import { ComponentDefaultProps } from "@chakra-ui/react";
import moment from "moment";

import { useCrmEntitiesExport } from "@/core/hooks/useCrmEntitiesExport";
import { TraitsPopover } from "@/modules/Settings/Crm/SyncSettings/TraitsPopover";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import { Drilldown } from "core/components/Drilldown";
import {
  GroupListSingleGroup,
  GroupType,
} from "core/components/Group/GroupListSingleGroup";
import { useCrmEntities } from "core/hooks/useCrmEntities";
import usePagination from "core/hooks/usePagination";
import { ICrmSync } from "core/models/crmSyncs";
import { Integration } from "core/models/dataMappings";
import { AppObjectType, IAppObject } from "core/types/AppObject";

interface IRecordsDrilldown extends ComponentDefaultProps {
  appObject: IAppObject;
  integration: Integration;
  isOpen: boolean;
  onClose: () => void;
  crmSync?: ICrmSync;
}

export const RecordsDrilldown: React.FC<IRecordsDrilldown> = ({
  appObject,
  integration,
  isOpen,
  onClose,
  crmSync,
}) => {
  const { currentPage, nextPage } = usePagination({
    withURL: false,
    persist: false,
  });
  const { crmEntities, pagy, isLoadingCrmEntities } = useCrmEntities({
    integration,
    objectType: appObject.objectType,
    page: currentPage,
    crmSync,
  });

  const { requestCsvExport, csvExportLoading } = useCrmEntitiesExport({
    appObject,
    integration,
  });

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={
                pagy?.count === 1
                  ? `${pagy?.count} ${appObject.singularName.toLowerCase()}`
                  : `${pagy?.count.toLocaleString("en-US")} ${appObject.pluralName.toLowerCase()}`
              }
              description={`Total ${appObject.pluralName.toLowerCase()} synced`}
              isLoading={isLoadingCrmEntities}
            />
            <Drilldown.DownloadButton
              isDisabled={Number(pagy?.count) <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={crmEntities}
              hasMore={crmEntities.length < Number(pagy?.count)}
              itemRenderer={({ item }) =>
                appObject.objectType === AppObjectType.User ? (
                  <UserListSingleContact
                    key={item.entityId}
                    id={item.entityId}
                    traits={item.traits}
                    description={
                      <p className="text-xs">
                        <p className="inline-block capitalize">{item.action}</p>{" "}
                        {moment.utc(item.updatedAt).fromNow()} with{" "}
                        <TraitsPopover traits={item.traits} />
                      </p>
                    }
                  />
                ) : (
                  <GroupListSingleGroup
                    key={item.entityId}
                    id={item.entityId}
                    traits={item.traits}
                    groupType={
                      appObject.objectType === AppObjectType.Group
                        ? GroupType.Group
                        : GroupType.Company
                    }
                    description={
                      <p className="text-xs">
                        <p className="inline-block capitalize">{item.action}</p>{" "}
                        {moment.utc(item.updatedAt).fromNow()} with{" "}
                        <TraitsPopover traits={item.traits} />
                      </p>
                    }
                  />
                )
              }
              loadNext={() => nextPage()}
              isLoading={isLoadingCrmEntities}
              emptyStateText={`No records synced`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
