import humps from "humps";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { useMountedState } from "core/hooks/useMountedState";
import axios from "core/initializers/axios";
import { Integration } from "core/models/dataMappings";
import { IAppObject } from "core/types/AppObject";

interface IUseCrmEntitiesExport {
  appObject: IAppObject;
  integration: Integration;
}

export const useCrmEntitiesExport = ({
  appObject,
  integration,
}: IUseCrmEntitiesExport) => {
  const { appId } = useParams<{
    appId: string;
  }>() as {
    appId: string;
  };

  const isMounted = useMountedState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const requestCsvExport = useCallback(() => {
    if (appId && appObject) {
      setError(undefined);
      setLoading(true);
      axios
        .post(
          `/crm_entities/csv_export`,
          humps.decamelizeKeys({
            appId,
            integration,
            objectType: appObject.objectType,
          }),
        )
        .then(() => {
          if (isMounted()) {
            const title = `Export ${appObject.singularName.toLowerCase()} list`;
            const description = `You will shortly receive an email with a CSV of your ${appObject.pluralName.toLowerCase()}`;
            toast.success(title, {
              description,
            });
          }
        })
        .catch((err) => {
          const title = "Export failed";
          const description = err?.response?.data?.error;
          const error = `${title}: ${description}`;
          setError(error);
          toast.error(title, {
            description,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isMounted, appId, appObject, integration]);

  return {
    requestCsvExport,
    csvExportLoading: loading,
    csvExportError: error,
  };
};
