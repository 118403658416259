import React from "react";

import { Button } from "@/Components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/Components/ui/dialog";

interface IConfirmationModal {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: string;
  onConfirmation?: () => void;
}

export const ConfirmationModal: React.FC<IConfirmationModal> = ({
  isOpen,
  onClose,
  onConfirmation,
  title,
  body,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{body}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirmation}>Yes, I'm sure</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
