import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/Components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/Components/ui/popover";
import { useGetCompaniesQuery } from "@/core/models/companies";
import { useGetGroupsQuery } from "@/core/models/groups";
import { IAppObject, AppObjectType } from "@/core/types/AppObject";
import { IGroup } from "@/core/types/Group";
import { JoinOperatorValue } from "core/types/Filters.d";

export const ObjectSelector = ({
  selectedAppObject,
  selectedObject,
  selectObject,
}: {
  selectedAppObject: IAppObject;
  selectedObject: IGroup | null;
  selectObject: (object: IGroup) => void;
}) => {
  const { appId } = useParams();
  const [open, setOpen] = useState(false);

  const groupsQuery = useGetGroupsQuery(
    selectedAppObject.objectType === AppObjectType.Group
      ? {
          appId: Number(appId),
          page: 1,
          audienceFilters: {
            filterGroups: [],
            joinOperator: JoinOperatorValue.AND,
          },
          searchQuery: "",
        }
      : skipToken,
  );

  const companiesQuery = useGetCompaniesQuery(
    selectedAppObject.objectType === AppObjectType.Company
      ? {
          appId: Number(appId),
          page: 1,
          audienceFilters: {
            filterGroups: [],
            joinOperator: JoinOperatorValue.AND,
          },
          searchQuery: "",
        }
      : skipToken,
  );

  const objects =
    selectedAppObject.objectType === AppObjectType.Group
      ? groupsQuery.data?.groups
      : companiesQuery.data?.companies;
  const isLoading =
    selectedAppObject.objectType === AppObjectType.Group
      ? groupsQuery.isFetching
      : companiesQuery.isFetching;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="flex w-full cursor-pointer items-center justify-between gap-1 rounded-md px-1.5 py-0.5 text-sm font-medium hover:bg-gray-200">
          {selectedObject ? (
            <div className="flex items-center gap-2">
              {selectedObject?.name}
            </div>
          ) : (
            <div className="flex items-center gap-2 text-gray-600">
              Select {selectedAppObject.singularName}
            </div>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command>
          <CommandList>
            <CommandGroup>
              {isLoading ? (
                <CommandItem>Loading...</CommandItem>
              ) : (
                objects?.map((object: IGroup) => (
                  <CommandItem
                    key={object.id}
                    onSelect={() => {
                      selectObject(object);
                      setOpen(false);
                    }}
                  >
                    {object.name || object.id}
                  </CommandItem>
                ))
              )}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
