import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useState } from "react";

import { Button } from "@/Components/ui/button";
import { IUpdateInsightPayload } from "@/core/hooks/useEditViewInsight";
import { useGetAudiencesQuery } from "@/core/models/audiences";
import { IViewInsight } from "@/core/models/viewInsights";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import { SolidChevronDown } from "core/design-system/components/Icon/Custom/SolidChevronDown";

export const AudienceMenu = ({
  viewInsight,
  onUpdateInsight,
}: {
  viewInsight: IViewInsight;
  onUpdateInsight: (payload: IUpdateInsightPayload) => void;
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { data } = useGetAudiencesQuery({
    appId: viewInsight.appId,
    page: 1,
    query: searchValue,
  });
  const audiences = data?.audiences || [];

  return (
    <Popover className="relative">
      <PopoverButton className="flex w-full justify-between">
        <Button
          className="mt-2 w-full border-none bg-gray-50"
          variant="outline"
        >
          <div className="flex w-full justify-between">
            {viewInsight.audience?.name}
            <SolidChevronDown className="h-4 w-4 text-gray-900" />
          </div>
        </Button>
      </PopoverButton>
      <PopoverPanel className="flex flex-col">
        {({ close }) => (
          <>
            <Lifecycle onUnmount={() => setSearchValue("")} />
            <Command>
              <CommandInput
                className="mb-1"
                placeholder="Search..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />

              <CommandList>
                {audiences.map((audience) => (
                  <CommandOption
                    key={audience.id}
                    value={() => {
                      close();
                      onUpdateInsight({
                        audienceId: audience.id,
                      });
                    }}
                  >
                    {audience.name}
                  </CommandOption>
                ))}
              </CommandList>
            </Command>
          </>
        )}
      </PopoverPanel>
    </Popover>
  );
};
