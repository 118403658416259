import {
  UserGroupIcon,
  BuildingOfficeIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import { useNavigate, useParams } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/Components/ui/breadcrumb";
import { Skeleton } from "@/Components/ui/skeleton";
import { useAppObjects } from "@/core/hooks/useAppObjects";
import { ViewLocation } from "@/core/hooks/useViews";
import { useGetObjectQuery } from "@/core/models/objects/object";
import { IViewInsight } from "@/core/models/viewInsights";
import { AppObjectType } from "@/core/types/AppObject";

const ICON_MAP = {
  [AppObjectType.User]: UsersIcon,
  [AppObjectType.Company]: BuildingOfficeIcon,
  [AppObjectType.Group]: UserGroupIcon,
};

export const Breadcrumbs: React.FC<{
  viewInsight: IViewInsight;
}> = ({ viewInsight }) => {
  const { appId, appObjectId, objectId } = useParams();
  const navigate = useNavigate();
  const { appObjects } = useAppObjects();
  const currentObject = appObjects.find((o) => o.id === Number(appObjectId));

  const { data: object, isLoading: isLoadingObject } = useGetObjectQuery({
    appId: Number(appId),
    appObjectId: Number(appObjectId),
    objectId: String(objectId),
  });

  const Icon = ICON_MAP[currentObject?.objectType || AppObjectType.User];

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <div className="flex flex-row items-center gap-1">
            {viewInsight.view.location === ViewLocation.Home && (
              <>
                <BreadcrumbLink
                  className="cursor-pointer text-sm font-medium text-gray-500 hover:text-gray-700"
                  onClick={() => navigate(`/a/${appId}/dashboards`)}
                >
                  Dashboards
                </BreadcrumbLink>
                <BreadcrumbSeparator className="text-gray-500" />
              </>
            )}
            {viewInsight.view.location === ViewLocation.Company && (
              <>
                <div className="flex flex-row items-center gap-1">
                  <Icon className="size-4 text-gray-500" />
                  <span className="cursor-pointer text-sm font-medium text-gray-500 hover:text-gray-700">
                    {currentObject?.pluralName}
                  </span>
                </div>
                <BreadcrumbSeparator className="text-gray-500" />
              </>
            )}
          </div>
          <BreadcrumbItem>
            {viewInsight.view.location === ViewLocation.Company ? (
              <BreadcrumbLink
                onClick={() =>
                  navigate(
                    `/a/${appId}/objects/${appObjectId}/object/${objectId}`,
                  )
                }
                className="cursor-pointer font-medium text-gray-500 hover:text-gray-700"
              >
                {isLoadingObject ? (
                  <Skeleton className="h-4 w-24 rounded-sm" />
                ) : (
                  object?.name
                )}
              </BreadcrumbLink>
            ) : (
              <BreadcrumbLink
                onClick={() =>
                  navigate(`/a/${appId}/home/${viewInsight.view.id}`)
                }
                className="text-gray-500 hover:cursor-pointer hover:text-gray-600"
              >
                <div className="flex flex-row items-center gap-1">
                  <span className="opacity-50">{viewInsight.view.emoji}</span>
                  <span className="text-sm font-medium">
                    {viewInsight.view.name}
                  </span>
                </div>
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
          <BreadcrumbSeparator className="text-gray-500" />
          <BreadcrumbItem>
            <BreadcrumbLink>
              <p className="text-sm font-medium text-gray-500">
                {viewInsight.title || "Untitled"}
              </p>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};
