import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { SmilePlusIcon } from "lucide-react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { EmojiPickerContent } from "@/core/components/EmojiPicker/Content";

interface IAddEmoji {
  handleEmojiSelect: (emoji: string) => void;
}

export const AddEmoji: React.FC<IAddEmoji> = ({ handleEmojiSelect }) => {
  return (
    <Popover>
      {({ close }) => (
        <>
          <PopoverButton as="div">
            <Tooltip>
              <TooltipTrigger>
                <div className="mt-1 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full p-2 hover:bg-gray-100">
                  <SmilePlusIcon className="h-8 w-8 text-gray-500" />
                </div>
              </TooltipTrigger>
              <TooltipContent className="shadow-none">Add emoji</TooltipContent>
            </Tooltip>
          </PopoverButton>
          <PopoverPanel
            className="absolute z-[10000] rounded-b-lg"
            portal={true}
            anchor={{ to: "right end" }}
            focus={true}
          >
            <EmojiPickerContent
              onSelect={(emoji) => handleEmojiSelect(emoji)}
              onClose={close}
            />
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
