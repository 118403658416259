import moment from "moment";

import { validateEventLog } from "core/helpers/event-validation/validate-event-log";
import { IEventLog } from "core/types/Debugger";
import { cx } from "helpers/cx";
import { LogIcon } from "modules/Developers/Logs/LogIcon";

export enum LogType {
  Page = 0,
  Screen = 1,
  Track = 2,
  Identify = 3,
  Group = 4,
}

const getLogType = (type: LogType) => {
  switch (type) {
    case LogType.Page:
      return "Page";
    case LogType.Screen:
      return "Screen";
    case LogType.Track:
      return "Track";
    case LogType.Identify:
      return "Identify";
    case LogType.Group:
      return "Group";
    default:
      return "Unknown";
  }
};
const getLogDetails = (type: LogType, data: any) => {
  switch (type) {
    case LogType.Page:
      return typeof data.properties.path === "string"
        ? data.properties.path
        : "Invalid path property";
    case LogType.Screen:
      return typeof data.name === "object" ? "Invalid name" : data.name;
    case LogType.Track:
      return typeof data.name === "object" ? "Invalid name" : data.name;
    case LogType.Identify:
      return typeof data.userId === "object" ? "Invalid userId" : data.userId;
    case LogType.Group:
      return typeof data.groupId === "object"
        ? "Invalid groupId"
        : data.groupId;
    default:
      return "Unknown";
  }
};
const getLogCss = (type: LogType) => {
  switch (type) {
    case LogType.Page:
      return "bg-green-100 text-green-800";
    case LogType.Track:
      return "bg-cyan-100 text-cyan-800";
    case LogType.Identify:
      return "bg-purple-100 text-purple-800";
    case LogType.Group:
      return "bg-orange-100 text-orange-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

export const Log = ({
  selected,
  data,
  onSelect,
}: {
  selected?: boolean;
  data: IEventLog;
  onSelect?: () => void;
}) => {
  const result = validateEventLog(data);
  return (
    <div
      onClick={onSelect}
      className={cx(
        "flex w-full items-center gap-4 border-b border-gray-200 p-4 text-xs",
        onSelect && "cursor-pointer hover:bg-gray-100",
        selected && "bg-gray-100",
      )}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <div className="flex w-28 items-center gap-4">
            <LogIcon issueLevel={result.maxLevel} />
            <span
              className={cx(
                "rounded px-1 py-0.5 text-xs font-medium uppercase",
                getLogCss(data.type),
              )}
            >
              {getLogType(data.type)}
            </span>
          </div>
          <div className="max-w-[200px] truncate text-ellipsis text-sm">
            {getLogDetails(data.type, data)}
          </div>
        </div>
        <div>{moment(data.timestamp).format("DD/MM/YYYY HH:mm:ss")}</div>
      </div>
    </div>
  );
};
