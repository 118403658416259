import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { getAudienceGroupId } from "@/core/helpers/audienceHelper";
import { JuneSpinner } from "core/components/JuneSpinner";
import { usePaywall } from "core/hooks/usePaywall";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useReorderViewInsights } from "core/hooks/useReorderViewInsights";
import { ViewLocation } from "core/hooks/useViews";
import { useGetViewInsightsQuery } from "core/models/viewInsights";
import { IView } from "core/models/views";
import { AddAudienceModalContainer } from "modules/ViewInsight/Modal/AddAudienceModalContainer";
import { DragOverlayCard } from "modules/ViewInsights/DragOverlayCard";
import { EmptyState } from "modules/ViewInsights/EmptyState";
import { SortableInsight } from "modules/ViewInsights/SortableInsight";

export const ViewInsightsContainer: React.FC<{
  location: ViewLocation;
  view: IView;
  viewId: number;
  columns?: number;
  width?: string;
  paywallInsights?: boolean;
  showAudienceModal: boolean;
  setShowAudienceModal: (show: boolean) => void;
}> = ({
  location,
  view,
  viewId,
  columns = 3,
  width = "315px",
  paywallInsights,
  showAudienceModal,
  setShowAudienceModal,
}) => {
  const { appId } = useParams();
  const groupId = useQueryParam("groupId");
  const { groupId: groupIdFromAudience } = getAudienceGroupId(view.audience);
  const {
    data: viewInsights,
    isLoading: isLoadingViewInsights,
    refetch,
  } = useGetViewInsightsQuery({
    appId: Number(appId),
    viewId,
    groupId: groupIdFromAudience || groupId,
  });
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const { reorderableInsights, handleDragEnd, sensors } =
    useReorderViewInsights({ viewInsights: viewInsights || [] });
  const { shouldBePaywalled } = usePaywall();

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const onDragEnd = (event: DragEndEvent) => {
    setActiveId(null);
    handleDragEnd(event);
  };

  const onRefetch = () => {
    return new Promise((resolve) => refetch().then(resolve));
  };

  if (isLoadingViewInsights) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-2">
        <JuneSpinner />
      </div>
    );
  }

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
        onDragStart={handleDragStart}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gridAutoColumns: "515px",
            gap: "20px",
            position: "relative",
          }}
        >
          <SortableContext
            items={reorderableInsights.map((i) => i.id)}
            strategy={rectSortingStrategy}
          >
            {reorderableInsights.map((viewInsight) => (
              <SortableInsight
                key={viewInsight.id}
                viewInsight={viewInsight}
                view={view}
                location={location}
                shouldBePaywalled={shouldBePaywalled}
                paywallInsights={paywallInsights}
                onRefetch={onRefetch}
              />
            ))}
          </SortableContext>
          <EmptyState viewInsights={viewInsights} width={width} />
        </div>

        <DragOverlay>
          {activeId ? (
            <DragOverlayCard
              viewInsight={
                reorderableInsights.find(
                  (item) => String(item.id) === String(activeId),
                )!
              }
              view={view}
              location={location}
              shouldBePaywalled={shouldBePaywalled}
              paywallInsights={paywallInsights}
            />
          ) : null}
        </DragOverlay>
      </DndContext>

      <React.Suspense fallback={<div className="h-10">Loading...</div>}>
        {showAudienceModal && (
          <AddAudienceModalContainer
            onClose={() => {
              setShowAudienceModal(false);
              onRefetch();
            }}
          />
        )}
      </React.Suspense>
    </>
  );
};
