import {
  AdjustmentsHorizontalIcon,
  PaintBrushIcon,
} from "@heroicons/react/16/solid";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { PillTabTrigger, PillTabs } from "@/Components/ui/pill-tabs";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/Components/ui/resizable";
import { ViewLocation } from "@/core/hooks/useViews";
import { DesignContainer } from "@/modules/Dashboard/InsightBuilder/SidePanel/DesignContainer";
import { PageContainer } from "core/components/PageContainer";
import { useEditViewInsight } from "core/hooks/useEditViewInsight";
import { IViewInsight } from "core/models/viewInsights";
import { IAppObject } from "core/types/AppObject";
import { IReport } from "core/types/Report";
import { GraphType, Measure } from "core/types/ViewInsight";
import { NavContainer } from "modules/Dashboard/InsightBuilder/Nav/NavContainer";
import { Sizes } from "modules/ViewInsight/Builder/LayoutContainer";
import { PreviewContainer } from "modules/ViewInsight/Builder/Preview/Container";
import { SetupContainer } from "modules/ViewInsight/Builder/Setup/Container";

export const InsightEditorContainer: React.FC<{
  viewInsight: IViewInsight;
  isAddingNewInsight?: boolean;
}> = ({ viewInsight, isAddingNewInsight }) => {
  const { appId, appObjectId, objectId } = useParams();
  const navigate = useNavigate();

  const {
    report,
    templateConfig,
    measure,
    graphType,
    size,
    title,
    interval,
    colorPalette,
    colors,
    appObject: reportAppObject,
    useViewAudience,
    isUpdatingViewInsight,
    onUpdateReport,
    onUpdateInsight,
    onCreate,
  } = useEditViewInsight({
    viewInsight: viewInsight as IViewInsight,
    loadReport: true,
  });
  const [selectedTab, setSelectedTab] = useState("setup");

  const onSave = async () => {
    if (viewInsight.view.location === ViewLocation.Home) {
      navigate(`/a/${appId}/home/${viewInsight.view.id}`);
    } else {
      navigate(`/a/${appId}/objects/${appObjectId}/object/${objectId}`);
    }
  };

  return (
    <PageContainer className="ml-1" maxW="full" py="0" containerless>
      <div className="flex h-full max-h-full w-full flex-col">
        <div className="flex flex-row items-center justify-between border-b border-gray-200 px-4 py-1">
          <NavContainer
            viewInsight={viewInsight}
            isUpdatingViewInsight={isUpdatingViewInsight}
            onCreate={onCreate}
            onSave={onSave}
            onUpdateReport={onUpdateReport}
          />
        </div>

        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel defaultSize={30} minSize={25} maxSize={40}>
            <div className="h-full">
              <div className="border-b border-gray-200 py-2">
                <div className="flex flex-row items-center justify-between pl-3 pr-1">
                  <PillTabs
                    value={selectedTab}
                    onValueChange={setSelectedTab}
                    className="flex gap-2"
                  >
                    <PillTabTrigger value="setup">
                      <div className="flex flex-row items-center gap-1">
                        <AdjustmentsHorizontalIcon className="h-4 w-4" /> Setup
                      </div>
                    </PillTabTrigger>
                    {viewInsight.measure !== Measure.Audience && (
                      <PillTabTrigger value="design">
                        <div className="flex flex-row items-center gap-1">
                          <PaintBrushIcon className="h-4 w-4" /> Design
                        </div>
                      </PillTabTrigger>
                    )}
                  </PillTabs>
                </div>
              </div>

              {reportAppObject && selectedTab === "setup" && (
                <SetupContainer
                  viewInsight={viewInsight}
                  report={report as IReport}
                  templateConfig={templateConfig}
                  measure={measure}
                  appObject={reportAppObject}
                  isAddingNewInsight={isAddingNewInsight}
                  useViewAudience={useViewAudience}
                  onUpdateReport={onUpdateReport}
                  onUpdateInsight={onUpdateInsight}
                />
              )}
              {reportAppObject && selectedTab === "design" && (
                <DesignContainer
                  viewInsight={viewInsight}
                  report={report as IReport}
                  graphType={graphType as GraphType}
                  measure={measure}
                  appObject={reportAppObject}
                  onUpdateInsight={onUpdateInsight}
                  colorPalette={colorPalette}
                  colors={colors}
                  size={size}
                  groupId={objectId}
                />
              )}
            </div>
          </ResizablePanel>
          <ResizableHandle showBorder />
          <ResizablePanel defaultSize={75}>
            <div className="col-span-4 h-full">
              {reportAppObject && (
                <PreviewContainer
                  viewInsight={viewInsight}
                  report={report as IReport}
                  measure={measure}
                  size={size}
                  width={Sizes[size].width}
                  height={Sizes[size].height}
                  graphType={graphType as GraphType}
                  colorPalette={colorPalette}
                  colors={colors}
                  title={title}
                  interval={interval}
                  appObject={reportAppObject as IAppObject}
                  isAddingNewInsight={isAddingNewInsight}
                  onUpdateInsight={onUpdateInsight}
                  isUpdatingViewInsight={isUpdatingViewInsight}
                  hideControls={true}
                />
              )}
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </PageContainer>
  );
};
