import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import useSWR from "swr";

import { Button } from "@/Components/ui/button";

const assetManifestFetcher = (assetManifestUrl: string) => {
  return axios
    .get(`${assetManifestUrl}?_${new Date().getTime()}`, {
      responseType: "text",
      transformResponse: [(data) => data],
    })
    .catch((e) => {
      console.error("Error fetching asset manifest:", e);
    });
};

export const UpdateNotification: React.FC = () => {
  const assetManifestUrl = "/asset-manifest.json";
  const [assetManifest, setAssetManifest] = useState("");
  const [entryAnimationFinished, setEntryAnimationFinished] = useState(false);

  const { data } = useSWR(assetManifestUrl, assetManifestFetcher, {
    refreshInterval: 120000,
    revalidateOnFocus: false,
  });
  useEffect(() => {
    if (!assetManifest && data) {
      setAssetManifest(data.data);
    }
  }, [data, assetManifest]);

  const onReload = () =>
    typeof window !== "undefined" && window.location.reload();

  const updateAvailable = assetManifest && data && data.data !== assetManifest;
  if (!updateAvailable) {
    return null;
  }

  return (
    <motion.div
      animate={{
        y: 0,
        opacity: 1,
      }}
      initial={{ opacity: 0, y: 100 }}
      transition={{ duration: 1.5, type: "spring", bounce: 0.6 }}
      onAnimationComplete={() => {
        setEntryAnimationFinished(true);
      }}
      style={{
        position: "fixed",
        bottom: 22,
        left: 13,
        zIndex: 10000,
      }}
    >
      <div
        className="container relative z-toast flex h-12 w-[299px] items-center justify-between rounded-lg bg-gray-50 p-3"
        style={{
          backdropFilter: "blur(12px)",
          filter: "auto",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.10)",
          overflow: "hidden",
        }}
      >
        {entryAnimationFinished && (
          <motion.div
            style={{
              position: "absolute",
              zIndex: 1,
              top: 0,
              left: "-50%",
              width: "40%",
              height: "100%",
              background:
                "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0))",
              transform: "skewX(20deg)",
            }}
            animate={{
              left: "100%",
            }}
            transition={{
              duration: 1.5,
              repeat: 2,
              ease: "linear",
            }}
          />
        )}

        <motion.div
          animate={
            entryAnimationFinished && {
              scale: [1, 0.4, 1, 1],
            }
          }
          transition={{ duration: 1.5, repeat: 1.5, delay: 0.25 }}
          style={{ position: "absolute", top: "7px", left: "103px" }}
        >
          <img src="/notification/star-small.svg" alt="star-sm" />
        </motion.div>
        <motion.div
          animate={
            entryAnimationFinished && {
              scale: [1, 0.4, 1, 1],
            }
          }
          transition={{ duration: 1.5, repeat: 1.5, delay: 0.1 }}
          style={{ position: "absolute", top: "32px", left: "52px" }}
        >
          <img src="/notification/star-small.svg" alt="star-sm" />
        </motion.div>
        <motion.div
          animate={
            entryAnimationFinished && {
              scale: [1, 0.3, 1, 1],
            }
          }
          transition={{ duration: 1.5, repeat: 1.5 }}
          style={{ position: "absolute", top: "28px", left: "8px" }}
        >
          <img src="/notification/star-medium.svg" alt="star-md" />
        </motion.div>
        <motion.div
          animate={
            entryAnimationFinished && {
              scale: [1, 0.2, 1, 1],
            }
          }
          transition={{ duration: 1.5, repeat: 1.5, delay: 0.5 }}
          style={{ position: "absolute", top: "15px", left: "172px" }}
        >
          <img src="/notification/star-large.svg" alt="star-lg" />
        </motion.div>
        <span className="chrome ml-3 text-sm font-bold leading-normal tracking-[-0.28px] text-gray-700">
          New version available
        </span>
        <Button
          size="sm"
          variant="gray"
          className="relative z-docked"
          onClick={onReload}
        >
          Refresh
        </Button>
      </div>
    </motion.div>
  );
};
