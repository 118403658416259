import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { IView, useDeleteViewMutation } from "core/models/views";

interface IDeleteViewModalProps {
  view: IView;
  isOpen: boolean;
  onClose: () => void;
  route?: string;
}

export const DeleteViewModal: React.FC<IDeleteViewModalProps> = ({
  view,
  isOpen,
  onClose,
  route = "/a/:appId/home",
}: IDeleteViewModalProps) => {
  const navigate = useNavigate();
  const [deleteView] = useDeleteViewMutation();

  return (
    <Modal isCentered isOpen={isOpen && Boolean(view)} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <h2 className="text-md font-semibold">Delete this dashboard?</h2>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p className="mb-4 text-sm">
            Once deleted, this dashboard cannot be recovered.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              if (!view.id) return;

              deleteView({ appId: view.appId, id: Number(view.id) })
                .unwrap()
                .then(() => {
                  toast.success(
                    `Dashboard "${view.name}" was deleted successfully`,
                  );

                  onClose();
                  navigate(route.replace(":appId", String(view.appId)));
                });
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
