import { GripHorizontal } from "lucide-react";

import { ViewLocation } from "core/hooks/useViews";
import { IViewInsight } from "core/models/viewInsights";
import { IView } from "core/models/views";
import { GRID_TO_PX } from "modules/ViewInsight/Builder/LayoutContainer";
import { ViewInsight } from "modules/ViewInsight/ViewInsight";

export const DragOverlayCard = ({
  viewInsight,
  view,
  location,
  shouldBePaywalled,
  paywallInsights,
}: {
  viewInsight: IViewInsight;
  view: IView;
  location: ViewLocation;
  shouldBePaywalled?: boolean;
  paywallInsights?: boolean;
}) => {
  const displayWidth = viewInsight.gridWidth || 1;

  return (
    <div
      style={{
        width: GRID_TO_PX[displayWidth],
        maxWidth: GRID_TO_PX[displayWidth],
        minWidth: GRID_TO_PX[displayWidth],
        height: "auto",
        transform: "scale(1.05)",
        boxShadow:
          "0 20px 25px -5px rgba(0, 0, 0, 0.15), 0 10px 10px -5px rgba(0, 0, 0, 0.1)",
        opacity: 0.95,
        zIndex: 9999,
        cursor: "grabbing",
        borderRadius: "8px",
        transition: "transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out",
        rotate: "1deg",
        backgroundColor: "white",
      }}
    >
      <div
        className="absolute -top-1 left-[48%] flex h-4 w-6 cursor-grabbing items-center justify-center rounded-md border border-purple-600 bg-purple-400 p-0.5 shadow-sm"
        style={{ zIndex: 10 }}
      >
        <div className="flex flex-col">
          <GripHorizontal className="h-4 w-4 text-white" />
        </div>
      </div>
      <ViewInsight
        view={view}
        location={location}
        viewInsight={viewInsight}
        isBlurred={
          shouldBePaywalled && paywallInsights && !viewInsight.isAutoGenerated
        }
        query={viewInsight.query}
        isAI={Boolean(viewInsight.queryId)}
        isDragging={true}
        gridWidth={displayWidth}
      />
    </div>
  );
};
