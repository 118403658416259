import { toast } from "sonner";

import axios from "core/initializers/axios";

function handleError({ error }) {
  if (!error.response) {
    return;
  }
  switch (error.response.status) {
    case 500:
      toast.error("Oops - something went wrong. Please reach out to support");
      break;

    case 404:
      toast.error("404 - The resource you're looking for doesn't exist");
      break;

    default:
      console.log(`Unhandled error: ${error}`);
  }
}

export default class Http {
  static async get({ path, config, dispatch }) {
    try {
      const request = await axios.get(path, config);
      return new Promise((resolve) => resolve(request));
    } catch (error) {
      console.log(`Http#get.error`, error);
      handleError({ dispatch, error });
    }
  }

  static async post({ path, body, config, dispatch }) {
    try {
      const request = await axios.post(path, body, config);
      return new Promise((resolve) => resolve(request));
    } catch (error) {
      console.log(`Http#post.error`, error);
      handleError({ dispatch, error });
    }
  }

  static async put({ path, body, config, dispatch }) {
    try {
      const request = await axios.put(path, body, config);
      return new Promise((resolve) => resolve(request));
    } catch (error) {
      console.log(`Http#put.error`, error);
      handleError({ dispatch, error });
    }
  }

  static async delete({ path, config, dispatch }) {
    try {
      const request = await axios.delete(path, config);
      return new Promise((resolve) => resolve(request));
    } catch (error) {
      console.log(`Http#delete.error`, error);
      handleError({ dispatch, error });
    }
  }
}
