import { Button } from "@june-so/ui";
import { BookOpenIcon, PlusIcon } from "lucide-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { Skeleton } from "@/Components/ui/skeleton";
import { PaginationContainer } from "@/modules/Dashboard/List/Pagination";
import { TableContainer } from "@/modules/Dashboard/List/Table";
import { PageContainer } from "core/components/PageContainer";
import { SearchBar } from "core/components/SearchBar";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import usePagination from "core/hooks/usePagination";
import { ViewLocation } from "core/hooks/useViews";
import { IView, useAddViewMutation, useGetViewsQuery } from "core/models/views";
import { DefaultHeader } from "modules/Pages/DefaultHeader";

export const Dashboards: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const [searchQuery, setSearchQuery] = useState("");
  const { currentPage, previousPage, nextPage, setPage } = usePagination({
    searchQuery,
  });
  const { data, isLoading, isFetching } = useGetViewsQuery({
    appId,
    page: currentPage,
    query: searchQuery,
    location: ViewLocation.Home,
    pinned: false,
  });
  const views = data?.views || [];
  const [addView] = useAddViewMutation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Dashboards";
  }, []);

  const onCreate = async function () {
    const { data } = (await addView({
      appId,
      name: "Untitled",
      pin: false,
    })) as { data: IView };
    navigate(`/a/${appId}/home/${data?.id}?new=true`);
    toast.success(`Dashboard created`, {
      description: `You can now start adding insights to this dashboard.`,
    });
  };

  return (
    <PageContainer>
      <div className="flex flex-col gap-2">
        <DefaultHeader
          section={{
            title: "Dashboards",
            description:
              "Dashboards are custom pages where you can add insights",
            sectionRight: () => (
              <div className="flex items-center gap-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    window.Intercom("showArticle", "10743301");
                  }}
                >
                  <BookOpenIcon className="h-[17px] w-[17px] text-gray-600" />
                  <span className="text-xs font-medium text-gray-800">
                    Learn
                  </span>
                </Button>
                <Button color="purple" onClick={onCreate}>
                  <PlusIcon className="h-[17px] w-[17px] text-white" />
                  New dashboard
                </Button>
              </div>
            ),
          }}
        />
      </div>
      <div className="mb-4 flex justify-start">
        <SearchBar
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Search a dashboard by name..."
          disabled={isLoading}
        />
      </div>

      <div className={isFetching ? "opacity-60 transition-opacity" : ""}>
        <TableContainer isLoading={isLoading} views={views} />
      </div>

      {data?.pagy && !isLoading && (
        <div className="mt-4 flex w-full justify-end pb-8">
          <PaginationContainer
            currentPage={currentPage}
            pagy={data.pagy}
            previousPage={previousPage}
            nextPage={nextPage}
            setPage={setPage}
          />
        </div>
      )}

      {isLoading && (
        <div className="mt-4 flex justify-center">
          <Skeleton className="h-4 w-[300px]" />
        </div>
      )}
    </PageContainer>
  );
};
