import React from "react";

import { cx } from "@/helpers/cx";
import { OnlineDot } from "core/components/Avatars/OnlineDot";
import { AvatarIcon } from "core/design-system/components/Icon/AvatarIcon";
import AnonymousIcon from "core/design-system/components/Icon/Interface/AnonymousIcon";
import {
  generateRandomAnimalName,
  getContactAvatar,
  hashStringToNumber,
  IGetContactAvatarProps,
} from "core/helpers/contactAvatar";
import { IContact } from "core/types/Contact";

export interface IContactAvatar {
  contact?: IContact;
  size?: string;
  isOnline?: boolean;
  previewMode?: boolean;
  className?: string;
  ml?: number; // Keeping for backward compatibility
}

export const ContactAvatar: React.FC<IContactAvatar> = ({
  contact,
  previewMode = false,
  size = "lg",
  ml = 0,
  isOnline = false,
  className,
}) => {
  const avatarURL = getContactAvatar(contact as IGetContactAvatarProps);
  const colors = ["yellow", "green", "red", "blue", "purple", "gray"];
  const isMedium = size === "md";
  const isSmall = size === "sm";
  const isExtraSmall = size === "xs";

  // Convert size to Tailwind size classes
  const sizeClass =
    (isMedium && "size-16") ||
    (isSmall && "size-6") ||
    (isExtraSmall && "size-5") ||
    "size-24";

  const avatarSize =
    (isMedium && 10) || (isSmall && 5) || (isExtraSmall && 4) || 16;

  // Create margin-left class based on ml prop
  const marginLeftClass = ml > 0 ? `ml-[${ml * 0.25}rem]` : "";

  if ((!contact?.isIdentified || contact?.isAnonymous) && !previewMode) {
    return (
      <div
        className={cx(
          "flex flex-shrink-0 items-center justify-center rounded-full",
          sizeClass,
          marginLeftClass,
          className,
        )}
      >
        <AnonymousIcon fontSize={isSmall ? 32 : 24} />
      </div>
    );
  }

  const contactId =
    typeof contact?.id === "number"
      ? contact?.id
      : hashStringToNumber(contact?.id);
  const hasAnonymousIcon = !contactId;
  const hasAnimalIcon = Boolean(!avatarURL && contactId);

  // Convert chakra color format to Tailwind format
  const bgColor = contactId
    ? `bg-${colors[contactId % colors.length]}-100`
    : "bg-gray-200";

  if (avatarURL)
    return (
      <div
        className={cx(
          "relative flex flex-shrink-0 items-center justify-center rounded-full bg-white bg-contain bg-center bg-no-repeat",
          sizeClass,
          marginLeftClass,
          className,
        )}
        style={{
          backgroundImage: `url(${avatarURL})`,
        }}
      >
        {isOnline && <OnlineDot />}
      </div>
    );

  return (
    <div
      className={cx(
        "relative flex flex-shrink-0 items-center justify-center rounded-full",
        sizeClass,
        bgColor,
        marginLeftClass,
        className,
      )}
    >
      {hasAnimalIcon && (
        <AvatarIcon
          h={avatarSize}
          w={avatarSize}
          name={generateRandomAnimalName(contactId)}
        />
      )}
      {hasAnonymousIcon && <AnonymousIcon fontSize={isSmall ? 32 : 24} />}
      {isOnline && <OnlineDot />}
    </div>
  );
};
