import { Flex, Spinner, Text } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";

import { ContactAvatar } from "core/components/Avatars/ContactAvatar";
import { getGroupName } from "core/helpers/contactName";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useGroupBanner } from "core/hooks/useGroupBanner";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useGetAudienceCountQuery } from "core/models/audiences";
import { Level } from "core/models/people";
import { getLevelParam } from "core/modules/reports/utils";
import { IAppObject } from "core/types";
import { IAudienceFilters } from "core/types/Audience";
import { pluralize } from "helpers/pluralize";

interface IAudienceCountPreviewProps {
  audienceFilters: IAudienceFilters;
  appObject?: IAppObject;
  showAvatars?: boolean;
}

export const AudienceCountPreview: React.FC<IAudienceCountPreviewProps> = ({
  audienceFilters,
  appObject,
  showAvatars = true,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const groupId = useQueryParam("groupId");
  const groupType = useQueryParam("groupType");
  const level = getLevelParam();
  const { activeAppObject } = useAppObjects();
  const { entity } = useGroupBanner({ groupId, groupType });

  const effectiveAppObject = appObject || activeAppObject;

  const { data, isLoading, error } = useGetAudienceCountQuery({
    appId: Number(appId),
    audienceFilters,
    level: effectiveAppObject?.slug || Level.User,
    groupId: groupId || undefined,
  });

  if (isLoading) return <Spinner size="xs" />;

  if (error || !data) return null;

  const total = data.totalCount;
  const count = data.count;
  const perc = (data.count / data.totalCount) * 100;

  return (
    <Flex alignItems="center" gridGap={2} data-testid="audience-count-preview">
      {showAvatars && (
        <Flex pl={2}>
          {["1", "2", "3"].map((i) => (
            <ContactAvatar
              ml={-2}
              size="xs"
              key={i}
              contact={{ id: i, traits: {}, isIdentified: true }}
            />
          ))}
        </Flex>
      )}
      <Flex>
        <Text fontWeight="semibold" fontSize="xs">
          {groupId && level === Level.User ? (
            <>
              {total.toLocaleString()} {pluralize(total, "user", "users")} for
              <Link
                className="mx-1 cursor-pointer underline decoration-dotted"
                to={`/a/${appId}/${groupType === "group" ? "group" : "company"}/${groupId}/`}
              >
                {entity && groupType === "group"
                  ? getGroupName(entity)
                  : entity?.id}
              </Link>
            </>
          ) : (
            <>
              {count >= 0 && `${count.toLocaleString()} out of `}{" "}
              {total.toLocaleString()}{" "}
              {effectiveAppObject?.pluralName.toLowerCase()}{" "}
              <Text
                as="span"
                fontWeight="normal"
                fontSize="xs"
                color="gray.600"
              >
                ({(perc || 0).toFixed(0)}%)
              </Text>
            </>
          )}
        </Text>
      </Flex>
    </Flex>
  );
};
