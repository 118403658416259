import { Tooltip } from "@chakra-ui/react";
import {
  BuildingOffice2Icon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import AnimatedToggle from "core/components/AnimatedToggle";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { TRAIT_LEVEL } from "core/constants/traits";
import { useAppObjects } from "core/hooks/useAppObjects";
import { Plan } from "core/types/App";
import { ITraitWithConfig } from "core/types/Trait";

const SLUG_TO_ICON: { [key: string]: React.ComponentType<any> } = {
  user: UsersIcon,
  group: UserGroupIcon,
  company: BuildingOffice2Icon,
};

export const TRAIT_LEVEL_TO_SLUG: { [key: string]: number } = {
  user: TRAIT_LEVEL.USER,
  group: TRAIT_LEVEL.GROUP,
  company: TRAIT_LEVEL.COMPANY,
};

export const Level: React.FC<{
  trait: ITraitWithConfig;
  onChangeLevel: (
    level: string,
    e: React.SyntheticEvent<Element, Event>,
  ) => void;
}> = ({ trait, onChangeLevel }) => {
  const { appObjects } = useAppObjects();
  const toggleItems =
    appObjects
      ?.filter((o) => o.isEnabled)
      ?.map((o) => {
        const Icon = SLUG_TO_ICON[o?.slug];
        return {
          value: String(TRAIT_LEVEL_TO_SLUG[o.slug]),
          label: o.singularName,
          startIcon: <Icon style={{ height: "18px" }} />,
          isDisabled: trait.isComputed,
        };
      }) || [];

  return (
    <div className="flex">
      <Tooltip
        label={
          trait.isComputed
            ? "You cannot change the trait type once the computed trait has been set live. Please create a new computed trait."
            : ""
        }
        shouldWrapChildren
        hasArrow
        placement="right"
      >
        <PaywallPopover
          feature={`computed traits`}
          redirect={`settings/computed-traits`}
          plan={Plan.Pro}
        >
          <AnimatedToggle
            key={String(trait.config.computationType)}
            containerProps={{ bg: "gray.100" }}
            items={toggleItems}
            value={String(trait.level)}
            onChange={onChangeLevel}
          />
        </PaywallPopover>
      </Tooltip>
    </div>
  );
};
