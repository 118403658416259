import { Button, Tooltip, Skeleton } from "@chakra-ui/react";
import { UserGroupIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { motion, AnimatePresence } from "framer-motion";

import { AddAudienceMenu } from "@/core/modules/audience/Filters/AddAudienceMenu";
import { AudienceFilters } from "@/core/modules/audience/Filters/AudienceFilters";
import { IAppObject } from "@/core/types/AppObject";
import { IAudience, IAudienceFilters } from "@/core/types/Audience";

export function getFilterCount(audienceFilters: IAudienceFilters) {
  return audienceFilters.filterGroups.reduce((count, group) => {
    return count + group.filters.length;
  }, 0);
}

export const DashboardFilters = ({
  showFilters,
  isFetchingViews,
  isFetchingAudience,
  isLoadingAudience,
  audience,
  onAddAudience,
  onRemoveAudience,
  onSaveAudience,
  isAudienceUpdated,
  audienceFilters,
  setAudienceFilters,
  userAppObject,
}: {
  showFilters: boolean;
  isFetchingViews: boolean;
  isFetchingAudience: boolean;
  isLoadingAudience: boolean;
  audience?: IAudience | null;
  onAddAudience: (audience: IAudience) => void;
  onRemoveAudience: () => void;
  onSaveAudience: () => void;
  isAudienceUpdated: boolean;
  audienceFilters: IAudienceFilters;
  setAudienceFilters: (filters: IAudienceFilters) => void;
  userAppObject: IAppObject;
}) => {
  return (
    <AnimatePresence>
      {showFilters && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.1, ease: "easeInOut" }}
          className="mb-10 mt-8 flex"
        >
          <div className="flex w-full justify-between">
            {isFetchingViews ||
              (isFetchingAudience && isLoadingAudience && (
                <Skeleton className="h-10 w-full rounded-md" />
              ))}
            {audience?.isSavedAudience ? (
              <div className="flex">
                <div className="flex w-full items-center gap-x-2 rounded-md bg-gray-50 pl-3 text-sm">
                  <div>
                    <UserGroupIcon className="h-4 w-4 text-gray-500" />
                  </div>
                  <Tooltip
                    fontSize="xs"
                    label="This is a saved audience"
                    textColor="gray.800"
                    bg="white"
                    className="rounded-md border border-gray-200"
                    placement="top"
                    bottom={2}
                    shadow="none"
                  >
                    <p className="w-full truncate text-sm font-medium">
                      {audience?.name}
                    </p>
                  </Tooltip>
                  <Tooltip
                    fontSize="xs"
                    label="Remove audience"
                    textColor="gray.800"
                    bg="white"
                    className="rounded-md border border-gray-200"
                    placement="top"
                    shadow="none"
                  >
                    <div
                      className="cursor-pointer rounded-md p-2 hover:bg-gray-100"
                      onClick={onRemoveAudience}
                    >
                      <XMarkIcon className="h-4 w-4 text-gray-500" />
                    </div>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <>
                <AudienceFilters
                  key={JSON.stringify(audienceFilters)}
                  audienceFilters={audienceFilters}
                  setAudienceFilters={setAudienceFilters}
                  view={userAppObject?.slug}
                  showClearFilters={false}
                >
                  <AddAudienceMenu
                    isDisabled={getFilterCount(audienceFilters) > 0}
                    onAddAudience={onAddAudience}
                  />
                </AudienceFilters>
                <Tooltip
                  fontSize="xs"
                  textColor="gray.800"
                  bg="white"
                  className="rounded-md border border-gray-200"
                  placement="top"
                  shadow="none"
                  label={
                    isAudienceUpdated
                      ? "Update the audience"
                      : "Audience has not changed"
                  }
                >
                  <Button
                    colorScheme="purple"
                    variant="ghost"
                    isDisabled={!isAudienceUpdated}
                    onClick={onSaveAudience}
                    isLoading={isLoadingAudience}
                  >
                    Save
                  </Button>
                </Tooltip>
              </>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
