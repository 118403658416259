import { Container, Spinner } from "@chakra-ui/react";
import {
  CommandLineIcon,
  PencilSquareIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/Components/ui/context-menu";
import { AddEmoji } from "@/core/components/AddEmoji";
import { JuneSpinner } from "@/core/components/JuneSpinner";
import { NOTES } from "@/core/constants/features";
import useFlag from "@/core/hooks/useFlag";
import { DashboardFilters } from "@/modules/Dashboard/DashboardFilters";
import { DashboardNav } from "@/modules/Dashboard/Nav";
import { ViewInsightsContainer } from "@/modules/ViewInsights/Container";
import { EmojiPicker } from "core/components/EmojiPicker";
import { PageContainer } from "core/components/PageContainer";
import { AND } from "core/constants/report-setup";
import { EditingViewProvider } from "core/contexts/EditingViewContext";
import { TwEditable } from "core/design-system/components/TwEditable";
import { useAppObjects } from "core/hooks/useAppObjects";
import { ViewLocation } from "core/hooks/useViews";
import {
  useGetAudienceQuery,
  useUpdateAudienceMutation,
} from "core/models/audiences";
import { useCreateContentMutation } from "core/models/viewInsights";
import {
  IView,
  useGetViewQuery,
  useUpdateViewMutation,
} from "core/models/views";
import { IAudience, IAudienceFilters } from "core/types/Audience";
import { JoinOperatorValue } from "core/types/Filters.d";

export const View: React.FC = () => {
  return (
    <EditingViewProvider>
      <Page />
    </EditingViewProvider>
  );
};

const Page = () => {
  const { appId, viewId, objectId, groupId } = useParams();
  const [searchParams] = useSearchParams();
  const [showAudienceModal, setShowAudienceModal] = useState(false);
  const [showFilters, setShowFilters] = useState(() => {
    const stored = localStorage.getItem("showAudienceFilters");
    return stored !== null ? JSON.parse(stored) : true;
  });
  const navigate = useNavigate();
  const [createNote] = useCreateContentMutation();
  const hasNotes = useFlag(NOTES) as boolean;
  const {
    data: view,
    isLoading: isLoadingViews,
    isFetching: isFetchingViews,
    refetch: onFetchView,
  } = useGetViewQuery({
    appId: Number(appId),
    id: Number(viewId),
  });

  const [audience, setAudience] = useState<IAudience | null>(null);
  const { userAppObject } = useAppObjects();
  const {
    data: audienceData,
    isFetching: isFetchingAudience,
    isLoading: isLoadingAudience,
  } = useGetAudienceQuery({
    appId: Number(appId),
    audienceId: view?.audience?.id as number,
  });

  const [updateAudience] = useUpdateAudienceMutation();
  const [audienceFilters, setAudienceFilters] = useState<IAudienceFilters>({
    filterGroups: [],
    joinOperator: AND as unknown as JoinOperatorValue,
  });
  const [shouldAutoFocusHeader] = useState(() => {
    return searchParams.get("new") === "true";
  });

  document.title = `${view?.emoji || ""} ${view?.name || "Home"}`;

  const isAudienceUpdated =
    JSON.stringify(audienceFilters) !==
    JSON.stringify({
      filterGroups: view?.audience?.filterGroups,
      joinOperator: view?.audience?.joinOperator,
    });
  const [updateView] = useUpdateViewMutation();

  const onAddAudience = (audience: IAudience) => {
    if (!view) return;

    updateView({
      id: Number(view.id),
      appId: Number(appId),
      audienceId: Number(audience.id),
    });
  };

  const onRemoveAudience = () => {
    if (!view) return;

    setAudience(null);
    setAudienceFilters({
      filterGroups: [],
      joinOperator: AND as unknown as JoinOperatorValue,
    });

    updateView({
      id: Number(view.id),
      appId: Number(appId),
      audienceId: undefined,
    });
  };

  useEffect(() => {
    if (audienceData) setAudience(audienceData);
  }, [audienceData]);

  useEffect(() => {
    if (!audience) return;

    setAudienceFilters({
      filterGroups: audience.filterGroups,
      joinOperator: audience.joinOperator,
    });
  }, [audience]);

  useEffect(() => {
    localStorage.setItem("showFilters", JSON.stringify(showFilters));
  }, [showFilters]);

  const onSaveAudience = async () => {
    if (!audience) return;

    await updateAudience({
      audienceId: Number(audience.id),
      appId: Number(appId),
      audienceFilters,
    });
    await onFetchView();
  };

  const handleEmojiClick = (emoji: string, viewId: number) => {
    updateView({
      id: Number(viewId),
      appId: Number(appId),
      emoji: emoji,
    });
  };

  const onUpdateViewName = (title: string) => {
    if (view?.id)
      updateView({ id: Number(view.id), appId: Number(appId), name: title });
  };

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <PageContainer
          maxW="full"
          minH="100vh"
          px={0}
          containerless
          py={0}
          bgColor="bg-white"
        >
          <div className="flex w-full items-center justify-between border-b border-gray-100 bg-white px-3 py-1">
            <DashboardNav
              view={view as IView}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              showAudienceModal={showAudienceModal}
              setShowAudienceModal={setShowAudienceModal}
            />
          </div>
          <Container
            id="views-container"
            minW="container.lg"
            minH="100vh"
            bg="white"
          >
            {isLoadingViews ? (
              <div className="flex h-full w-full items-center justify-center pt-10">
                <JuneSpinner />
              </div>
            ) : (
              <>
                <div className="flex w-full items-center justify-between">
                  <div className="group relative flex flex-col pt-8">
                    <div className="flex items-center gap-2">
                      <div className="flex">
                        {view?.emoji ? (
                          <EmojiPicker
                            emoji={view?.emoji}
                            handleEmojiClick={(e) =>
                              handleEmojiClick(e, Number(view?.id))
                            }
                            buttonProps={{ size: "normal", px: 2 }}
                            textProps={{ fontSize: "3xl" }}
                          />
                        ) : (
                          <AddEmoji
                            handleEmojiSelect={(e) =>
                              handleEmojiClick(e, Number(view?.id))
                            }
                          />
                        )}
                      </div>
                      <div className="flex">
                        {view && (
                          <TwEditable
                            key={view.name}
                            className={"text-2xl"}
                            inputClassName={"text-2xl"}
                            defaultValue={view.name}
                            placeholder={"Untitled view"}
                            fontWidth={14}
                            onSubmit={onUpdateViewName}
                            autoFocus={shouldAutoFocusHeader}
                            selectAllOnFocus={shouldAutoFocusHeader}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <DashboardFilters
                    showFilters={showFilters}
                    isFetchingViews={isFetchingViews}
                    isFetchingAudience={isFetchingAudience}
                    isLoadingAudience={isLoadingAudience}
                    audience={audience}
                    onAddAudience={onAddAudience}
                    onRemoveAudience={onRemoveAudience}
                    onSaveAudience={onSaveAudience}
                    isAudienceUpdated={isAudienceUpdated}
                    audienceFilters={audienceFilters}
                    setAudienceFilters={setAudienceFilters}
                    userAppObject={userAppObject}
                  />
                </div>
                <div className="my-3" />
                {isLoadingViews && !view && (
                  <div className="flex h-full w-full flex-col items-center justify-center gap-y-2">
                    <Spinner
                      emptyColor="gray.200"
                      color="gray.500"
                      thickness="3px"
                    />
                  </div>
                )}
                {view && (
                  <ViewInsightsContainer
                    key={audience?.updatedAt}
                    viewId={Number(viewId)}
                    view={view}
                    location={ViewLocation.Home}
                    showAudienceModal={showAudienceModal}
                    setShowAudienceModal={setShowAudienceModal}
                  />
                )}
                <div className="pb-10" />
              </>
            )}
          </Container>
        </PageContainer>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem
          onClick={() =>
            navigate(`/a/${appId}/dashboard/${view?.id}/graph/new`)
          }
        >
          <div className="flex items-center gap-2 text-xs">
            <PresentationChartLineIcon className="h-3 w-3 text-gray-600" />
            Add graph
          </div>
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => navigate(`/a/${appId}/dashboard/${view?.id}/sql/new`)}
        >
          <div className="flex items-center gap-2 text-xs">
            <CommandLineIcon className="h-3 w-3 text-gray-600" />
            Add SQL
          </div>
        </ContextMenuItem>
        {hasNotes && !(groupId || objectId) && (
          <ContextMenuItem
            onClick={() =>
              createNote({ appId: Number(appId), viewId: Number(viewId) })
            }
          >
            <div className="flex items-center gap-2 text-xs">
              <PencilSquareIcon className="h-3 w-3 text-gray-600" />
              Add note
              <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-[10px] font-medium text-blue-700">
                New
              </span>
            </div>
          </ContextMenuItem>
        )}
      </ContextMenuContent>
    </ContextMenu>
  );
};
