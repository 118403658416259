import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { PinOffIcon } from "lucide-react";

import { Button } from "@/Components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { EmojiPicker } from "core/components/EmojiPicker";
import { IView } from "core/models/views";
import { ItemContainer } from "modules/Navigation/Sidebar/ItemContainer";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";

export const SortableView = ({
  viewId,
  view,
  onUnpin,
  onEmojiClick,
}: {
  viewId: string;
  view: IView;
  onUnpin: (view: IView) => void;
  onEmojiClick: (emoji: string, viewId: number) => void;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: view?.id,
    transition: {
      duration: 300,
      easing: "cubic-bezier(0.51, 0.92, 0.24, 1.15)",
    },
    animateLayoutChanges: () => false,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDragging ? "grabbing" : "grab",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ItemContainer
        key={view.id}
        isCurrent={viewId === String(view.id)}
        to={`/a/${view.appId}/home/${view.id}`}
        className="group relative flex justify-between"
      >
        <div className="-ml-1 flex items-center">
          <EmojiPicker
            emoji={view.emoji}
            handleEmojiClick={(e) => onEmojiClick(e, Number(view.id))}
          />
          <div className="ml-0.5 line-clamp-1 max-w-[210px]">
            {view.name ? (
              <ItemText className="max-w-[210px] text-ellipsis">
                {view.name}
              </ItemText>
            ) : (
              <ItemText className="text-opacity-60">
                Untitled dashboard
              </ItemText>
            )}
          </div>
        </div>
        <div className="hidden items-center group-hover:flex">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="unstyled"
                size="unset"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onUnpin(view);
                }}
                className="p-0.5 text-gray-900 [&_svg]:size-3"
              >
                <PinOffIcon className="text-gray-600" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Unpin dashboard</TooltipContent>
          </Tooltip>
        </div>
      </ItemContainer>
    </div>
  );
};
