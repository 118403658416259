import { isEmpty } from "lodash";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/Components/ui/avatar";
import { Button } from "@/Components/ui/button";
import { Checkbox } from "@/Components/ui/checkbox";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandInput,
  CommandEmpty,
} from "@/Components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/Components/ui/popover";
import { FilterType } from "@/core/constants/filters";
import { useAppObjects } from "@/core/hooks/useAppObjects";
import { useLazyGetCompaniesQuery } from "@/core/models/companies";
import {
  useLazyGetGroupQuery,
  useLazyGetGroupsQuery,
} from "@/core/models/groups";
import {
  JoinOperatorValue,
  ComparisonType,
  IFilter,
} from "core/types/Filters.d";

interface ObjectType {
  id: string;
  name: string;
  traits: Record<string, any>;
}

export const ObjectValues = ({
  filter,
  onSelectValue,
  onDone,
}: {
  filter: IFilter;
  onSelectValue: (value: string) => void;
  onDone: () => void;
}) => {
  const { appId } = useParams();
  const { groupAppObject, companyAppObject } = useAppObjects();

  const [commandKey, setCommandKey] = useState(Date.now());

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [objects, setObjects] = useState<ObjectType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedObjects, setSelectedObjects] = useState<Array<ObjectType>>([]);

  const [fetchGroups] = useLazyGetGroupsQuery();
  const [fetchGroup] = useLazyGetGroupQuery();
  const [fetchCompanies] = useLazyGetCompaniesQuery();

  const isGroupFilter = filter.type === FilterType.GroupFilter;
  const isMultiSelect = filter.body.comparisonType === ComparisonType.CONTAINS;

  const objectSingularName = isGroupFilter
    ? groupAppObject?.singularName || "group"
    : companyAppObject?.singularName || "company";

  const objectPluralName = isGroupFilter
    ? groupAppObject?.pluralName || "groups"
    : companyAppObject?.pluralName || "companies";

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);
    return () => clearTimeout(timer);
  }, [searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      if (!open) return;

      setIsLoading(true);

      try {
        const queryParams = {
          appId: Number(appId),
          page: 1,
          audienceFilters: {
            filterGroups: [],
            joinOperator: JoinOperatorValue.AND,
          },
          searchQuery: debouncedSearchQuery,
        };

        let result;
        if (isGroupFilter) {
          result = await fetchGroups(queryParams).unwrap();
          setObjects(result?.groups || []);
        } else {
          result = await fetchCompanies(queryParams).unwrap();
          setObjects(result?.companies || []);
        }

        setCommandKey(Date.now());
      } catch (error) {
        console.error("Error fetching data:", error);
        setObjects([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    debouncedSearchQuery,
    open,
    isGroupFilter,
    appId,
    fetchGroups,
    fetchCompanies,
  ]);

  useEffect(() => {
    if (!open) {
      setSearchQuery("");
      setDebouncedSearchQuery("");
    }
  }, [open]);

  useEffect(() => {
    const initializeSelectedObject = async () => {
      setIsLoading(true);

      const ids = Array.isArray(filter.body.value)
        ? filter.body.value
        : filter.body.value.split(",");

      try {
        if (isGroupFilter) {
          const results = await Promise.all(
            ids.map((id) => fetchGroup({ appId: Number(appId), id }).unwrap()),
          );
          setSelectedObjects(results);
        } else {
          setSelectedObjects(
            ids
              .filter((id) => !isEmpty(id))
              .map((id) => ({
                id,
                name: id,
                traits: {},
              })),
          );
        }
      } catch (error) {
        console.error("Error fetching objects:", error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeSelectedObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMultiSelect = (id: string) => {
    const selectedObject = objects.find((obj) => obj.id === id);

    if (selectedObject) {
      setSelectedObjects((prev) => {
        const existingIndex = prev.findIndex((obj) => obj.id === id);

        if (existingIndex >= 0) {
          return prev.filter((obj) => obj.id !== id);
        } else {
          return [...prev, selectedObject];
        }
      });
    }
  };

  const handleSelect = (id: string) => {
    const object = objects.find((obj) => obj.id === id);

    if (object) {
      setSelectedObjects([object]);
    }

    onSelectValue(id);
    setOpen(false);
  };

  const handleDone = () => {
    onSelectValue(selectedObjects.map((obj) => obj.id).join(","));
    setOpen(false);
    onDone();
  };

  const renderFilterValue = () => {
    if (selectedObjects.length === 0) {
      return (
        <div
          className="flex items-center gap-2 text-gray-600"
          data-testid="audience-object-values-select"
        >
          Select {objectSingularName}
        </div>
      );
    }

    if (selectedObjects.length === 1) {
      return (
        <div className="flex items-center gap-2">
          <Avatar className="h-[16px] w-[16px] rounded-full">
            <AvatarImage src={selectedObjects[0].traits["avatar"]} />
            <AvatarFallback className="h-[16px] w-[16px] rounded-full bg-purple-500 capitalize text-white">
              {selectedObjects[0].name?.charAt(0) ||
                selectedObjects[0].id?.charAt(0)}
            </AvatarFallback>
          </Avatar>
          <span>{selectedObjects[0].name || selectedObjects[0].id}</span>
        </div>
      );
    }

    return (
      <div className="flex items-center gap-2">
        {selectedObjects.length} selected
      </div>
    );
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="flex w-full cursor-pointer items-center justify-between gap-1 rounded-md px-1.5 py-0.5 text-sm font-medium hover:bg-gray-200">
          {isLoading ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            renderFilterValue()
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command key={commandKey}>
          <CommandInput
            placeholder={`Search ${objectPluralName}`}
            value={searchQuery}
            onValueChange={setSearchQuery}
            autoFocus
          />
          <CommandList>
            <CommandGroup>
              {isLoading ? (
                <CommandItem>Loading...</CommandItem>
              ) : objects.length === 0 ? (
                <CommandEmpty>No results found.</CommandEmpty>
              ) : (
                objects.map((object) => (
                  <CommandItem
                    key={object.id}
                    onSelect={() =>
                      isMultiSelect
                        ? handleMultiSelect(object.id)
                        : handleSelect(object.id)
                    }
                    className="flex cursor-pointer items-center gap-2 text-xs"
                  >
                    {isMultiSelect && (
                      <Checkbox
                        id={`checkbox-${object.id}`}
                        checked={selectedObjects.some(
                          (obj) => obj.id === object.id,
                        )}
                      />
                    )}
                    <Avatar className="h-[16px] w-[16px] rounded-full">
                      <AvatarImage src={object.traits["avatar"]} />
                      <AvatarFallback className="h-[16px] w-[16px] rounded-full bg-purple-500 capitalize text-white">
                        {object.name?.charAt(0) || object.id?.charAt(0)}
                      </AvatarFallback>
                    </Avatar>
                    <span>{object.name || object.id}</span>
                  </CommandItem>
                ))
              )}
            </CommandGroup>
          </CommandList>
          {isMultiSelect && (
            <div className="border-t p-2">
              <Button
                size="sm"
                variant="outline"
                className="w-full"
                onClick={handleDone}
              >
                Done
                {selectedObjects.length > 0
                  ? ` (${selectedObjects.length})`
                  : ""}
              </Button>
            </div>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
};
