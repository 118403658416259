import { FileTextIcon, PinIcon, StarIcon } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import {
  FavouriteObjectType,
  useGetFavouriteQuery,
} from "@/core/models/favourites";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { IView } from "core/models/views";

export const NameCell: React.FC<{ props: IView }> = ({
  props: { id, name, emoji, pinned },
}) => {
  const { id: appId } = useCurrentApp();
  const { data: favourite } = useGetFavouriteQuery({
    appId: Number(appId),
    objectId: String(id),
    objectType: FavouriteObjectType.View,
  });

  return (
    <Link to={`/a/${appId}/home/${id}`}>
      <div className="flex items-center gap-2">
        <div className="flex items-center gap-2">
          {emoji ? (
            <p className="text-md">{emoji}</p>
          ) : (
            <FileTextIcon className="h-4 w-4 text-gray-500" />
          )}
          <p className="text-sm font-medium text-gray-800">{name}</p>
          {pinned && (
            <Tooltip>
              <TooltipTrigger>
                <PinIcon className="h-4 w-4 fill-gray-500 text-gray-500" />
              </TooltipTrigger>
              <TooltipContent>Pinned</TooltipContent>
            </Tooltip>
          )}
          {favourite && (
            <Tooltip>
              <TooltipTrigger>
                <StarIcon className="h-4 w-4 fill-yellow-400 text-yellow-400" />
              </TooltipTrigger>
              <TooltipContent>Favourite</TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>
    </Link>
  );
};
