import { Skeleton } from "@/Components/ui/skeleton";
import { TableCell, TableRow } from "@/Components/ui/table";

export const LoadingRow = () => (
  <TableRow>
    <TableCell>
      <Skeleton className="h-9 w-9" />
    </TableCell>
    <TableCell>
      <div className="flex items-center gap-2">
        <Skeleton className="h-4 w-4" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    </TableCell>
    <TableCell>
      <Skeleton className="h-4 w-[100px]" />
    </TableCell>
    <TableCell>
      <Skeleton className="float-right h-9 w-9" />
    </TableCell>
  </TableRow>
);
