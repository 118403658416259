import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { useState } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/Components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";
import { Button } from "Components/ui/button";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { Tabs } from "core/components/Tabs/Tabs";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useCrmSyncs } from "core/hooks/useCrmSyncs";
import { useCrmSyncSettings } from "core/hooks/useCrmSyncSettings";
import {
  CrmSyncStatus,
  CrmSyncTriggerType,
  CrmSyncType,
} from "core/models/crmSyncs";
import { Integration, integrationNames } from "core/models/dataMappings";
import { Plan } from "core/types/App";
import { AppObjectType } from "core/types/AppObject";
import { Issues } from "modules/Settings/Crm/SyncSettings/Issues";
import { LastSyncStatus } from "modules/Settings/Crm/SyncSettings/LastSyncStatus";
import { CrmSettings } from "modules/Settings/Crm/SyncSettings/Settings";

export const CrmSyncSettingRoute: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { appId, integration, objectType } = useParams();
  const { appObject } = useAppObjects(objectType as AppObjectType);
  const { crmSyncSetting } = useCrmSyncSettings({
    integration: integration as Integration,
    objectType: objectType as AppObjectType,
  });
  const {
    lastCrmSync,
    lastSyncedAt,
    lastSyncStatus,
    isLoadingCrmSyncs,
    onTriggerSync,
    onRefresh,
  } = useCrmSyncs({
    integration: integration as Integration,
    objectType: objectType as AppObjectType,
  });

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [syncType, setSyncType] = useState<CrmSyncType>(
    CrmSyncType.Incremental,
  );

  const integrationDisplayName =
    integrationNames[integration as Integration] || "";

  const pages = [
    {
      name: integrationDisplayName,
      path: `/a/${appId}/integrations/${integration}`,
      current: false,
    },
    {
      name: `${appObject?.singularName} settings`,
      path: `/a/${appId}/integrations/${integration}/${objectType}`,
      current: true,
    },
  ];

  if (!appObject || !integration) return <></>;
  if (window.location.pathname.includes("settings/integrations")) {
    return (
      <Navigate to={`/a/${appId}/integrations/${integration}/${objectType}`} />
    );
  }

  return (
    <div>
      <Dialog open={showSyncModal} onOpenChange={setShowSyncModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Sync {syncType === CrmSyncType.Full ? "all" : ""}{" "}
              {appObject.pluralName.toLocaleLowerCase()}
            </DialogTitle>
            <DialogDescription>
              This will temporarily pause automatic syncs. Would you like to
              proceed?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowSyncModal(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onTriggerSync({
                  triggerType: CrmSyncTriggerType.Manual,
                  syncType,
                });
                setShowSyncModal(false);
              }}
            >
              Sync
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <div className="mb-6 max-w-4xl">
        <Breadcrumbs pages={pages} />
        <div className="flex w-full items-center justify-between">
          <div className="my-4 flex flex-col gap-1">
            <p className="text-xl font-bold">
              {integrationDisplayName}{" "}
              {appObject.singularName.toLocaleLowerCase()} sync
            </p>
            <LastSyncStatus
              appObject={appObject}
              lastCrmSync={lastCrmSync}
              status={lastSyncStatus}
              lastSyncedAt={lastSyncedAt}
              onRefresh={onRefresh}
              isLoading={isLoadingCrmSyncs}
              integration={integration as Integration}
            />
          </div>
          <div className="flex items-center gap-0">
            <PaywallPopover
              feature={`automatic sync to ${integrationDisplayName}`}
              redirect={`settings/integrations/${integration}/${objectType}`}
              plan={Plan.Pro}
            >
              <>
                <Button
                  style={
                    integration === Integration.Hubspot
                      ? {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }
                      : {}
                  }
                  className="border-r border-r-purple-700/15"
                  disabled={
                    lastSyncStatus === CrmSyncStatus.Started ||
                    lastSyncStatus === CrmSyncStatus.Enqueued ||
                    !(
                      crmSyncSetting?.createEnabled ||
                      crmSyncSetting?.updateEnabled
                    ) ||
                    isLoadingCrmSyncs
                  }
                  onClick={() => {
                    setSyncType(CrmSyncType.Incremental);
                    setShowSyncModal(true);
                  }}
                >
                  <p className="text-sm">
                    Sync {appObject.pluralName.toLocaleLowerCase()}
                  </p>
                </Button>
                {integration === Integration.Hubspot && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        className="w-8"
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        disabled={
                          lastSyncStatus === CrmSyncStatus.Started ||
                          lastSyncStatus === CrmSyncStatus.Enqueued ||
                          !(
                            crmSyncSetting?.createEnabled ||
                            crmSyncSetting?.updateEnabled
                          ) ||
                          isLoadingCrmSyncs
                        }
                      >
                        <ChevronDownIcon className="size-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-50" align="end">
                      <DropdownMenuItem
                        onClick={() => {
                          setSyncType(CrmSyncType.Full);
                          setShowSyncModal(true);
                        }}
                      >
                        <p className="text-sm">
                          Sync all {appObject.pluralName.toLocaleLowerCase()}
                        </p>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </>
            </PaywallPopover>
          </div>
        </div>
        <div className="mt-2 max-w-4xl">
          <Tabs
            tabs={[
              {
                title: "Settings",
                Component: () =>
                  crmSyncSetting ? (
                    <CrmSettings
                      appObject={appObject}
                      integration={integration as Integration}
                      crmSyncSetting={crmSyncSetting}
                    />
                  ) : null,
              },
              {
                title: "Issues",
                Component: () => (
                  <Issues
                    appObject={appObject}
                    integration={integration as Integration}
                  />
                ),
              },
            ]}
            value={searchParams.get("tab") ?? "Settings"}
            onChange={(tab) => {
              setSearchParams({ tab });
            }}
          />
        </div>
      </div>
    </div>
  );
};
