import { BoltIcon } from "@heroicons/react/24/solid";

import { Tooltip, TooltipContent, TooltipTrigger } from "Components/ui/tooltip";
import { TraitIcon } from "core/components/Traits/TraitIcon";
import { ITraitKeyResponse } from "core/models/traits";
import { cx } from "helpers/cx";

interface TraitOptionProps {
  trait: ITraitKeyResponse;
  isDisabled?: boolean;
  tooltipContent?: string;
}

export const TraitOption: React.FC<TraitOptionProps> = ({
  trait,
  isDisabled,
  tooltipContent = undefined,
}) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div className="flex w-full">
          <div
            className={cx(
              `flex w-full items-center justify-between gap-1`,
              isDisabled ? "text-gray-500" : "text-gray-800",
            )}
          >
            <div className="flex min-w-0 items-center gap-1.5">
              <TraitIcon
                traitName={trait.trait}
                computationType={trait.computationType}
              />
              <p className="line-clamp-1 truncate text-sm font-medium normal-case tracking-normal">
                {trait.trait}
              </p>
            </div>
            <div className="flex flex-shrink-0">
              {trait.isComputed && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <BoltIcon className="h-3 w-3 text-purple-500" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Computed trait</p>
                  </TooltipContent>
                </Tooltip>
              )}
              {trait.trait.startsWith("june_") && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <BoltIcon className="h-3 w-3 text-gray-500" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Trait provided by June</p>
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <p>{tooltipContent || trait.trait}</p>
      </TooltipContent>
    </Tooltip>
  );
};
