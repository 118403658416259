import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import { SnakeCasedProperties } from "type-fest";

import { baseQuery } from "core/initializers/rtk";
import { IPagy } from "core/types/Pagy";
import {
  IReport,
  IReportBackendResponse,
  IReportToken,
} from "core/types/Report";

interface GetReportsArgs {
  appId: number;
  page: number;
  sort: string;
}

interface GetSearchReportsArgs {
  appId: number | string;
  query?: string;
  labelIds?: string[];
  reportTypes: number[];
}

interface GetReportTokenArgs {
  appId: number;
  reportId: number;
}

interface GetReportsResponse {
  reports: IReport[];
  pagy: IPagy;
}

interface GetSearchReportsResponse {
  reports: IReport[];
}

interface GetReportArgs {
  appId: string | number;
  reportId: string | number;
  sharingSecretToken?: string | null;
  insightType?: number;
  skipReportShowMetrics?: boolean;
}

interface UpdateReportArgs {
  appId: number;
  reportId: number;
  queryId?: number;
}

interface UpdateReportNameArgs extends UpdateReportArgs {
  name: string;
}

interface AddReportLabelArgs extends UpdateReportArgs {
  labelId: number | string;
}

interface RemoveReportLabelArgs extends UpdateReportArgs {
  labelId: number | string;
}

interface GetReportTypesResponse {
  reportTypes: number[];
}

export const reportsApi = createApi({
  baseQuery,
  reducerPath: "reportsApi",
  tagTypes: ["Report", "Token", "Insight", "Query"],
  endpoints: (builder) => ({
    getReports: builder.query<GetReportsResponse, GetReportsArgs>({
      query: ({ appId, page, sort }) => ({
        url: "/reports",
        params: { app_id: appId, page, sort },
      }),
      providesTags: ["Report", "Token"],
      transformResponse: (response: GetReportsResponse): GetReportsResponse =>
        // @ts-ignore
        humps.camelizeKeys(response),
    }),
    getSearchReports: builder.query<IReport[], GetSearchReportsArgs>({
      query: ({ appId, query, labelIds, reportTypes }) => {
        return {
          url: "/reports/search",
          params: {
            app_id: appId,
            report_types: reportTypes,
            query,
            label_ids: labelIds,
          },
        };
      },
      providesTags: ["Report", "Token"],
      transformResponse: (response: GetSearchReportsResponse): IReport[] =>
        humps.camelizeKeys(response) as IReport[],
    }),
    getReport: builder.query<IReport, GetReportArgs>({
      query: ({
        appId,
        reportId,
        sharingSecretToken = null,
        insightType = null,
        skipReportShowMetrics = false,
      }) => ({
        url: `/reports/${reportId}`,
        params: {
          app_id: appId,
          insight_type: insightType,
          sharing_secret_token: sharingSecretToken,
          skip_report_show_metrics: skipReportShowMetrics,
        },
      }),
      providesTags: ["Report", "Token"],
      transformResponse: (response: IReportBackendResponse): IReport =>
        // @ts-ignore
        humps.camelizeKeys(response),
    }),
    getReportTypes: builder.query<GetReportTypesResponse, { appId: number }>({
      query: ({ appId }) => ({
        url: `/reports/report_types`,
        params: { app_id: appId },
      }),
      transformResponse: (
        response: SnakeCasedProperties<GetReportTypesResponse>,
      ) => humps.camelizeKeys(response) as GetReportTypesResponse,
    }),
    getReportToken: builder.query<IReportToken, GetReportTokenArgs>({
      query: ({ appId, reportId }) => ({
        url: `/reports/${reportId}/token`,
        params: { app_id: appId },
      }),
      providesTags: ["Token"],
      transformResponse: (response: SnakeCasedProperties<IReportToken>) =>
        humps.camelizeKeys(response) as IReportToken,
    }),
    shareReport: builder.mutation({
      query: ({ appId, reportId }) => ({
        url: `/reports/${reportId}/share`,
        method: "PUT",
        body: { app_id: appId },
      }),
      invalidatesTags: ["Token"],
      transformResponse: (response: SnakeCasedProperties<IReportToken>) =>
        humps.camelizeKeys(response),
    }),
    refreshReport: builder.mutation({
      query: ({ appId, reportId }) => ({
        url: `/reports/${reportId}/refresh`,
        method: "PUT",
        body: { app_id: appId },
      }),
      invalidatesTags: ["Report"],
      transformResponse: (response: SnakeCasedProperties<IReport>) =>
        humps.camelizeKeys(response),
    }),
    removePublicAccess: builder.mutation({
      query: ({ appId, reportId }) => ({
        url: `/reports/${reportId}/delete_token`,
        method: "DELETE",
        body: { app_id: appId },
      }),
      invalidatesTags: ["Token"],
      transformResponse: (response: SnakeCasedProperties<IReportToken>) =>
        humps.camelizeKeys(response),
    }),
    updateReportName: builder.mutation<IReport, UpdateReportNameArgs>({
      query: ({ appId, reportId, name }) => ({
        url: `/reports/${reportId}`,
        method: "PUT",
        body: { app_id: appId, name },
      }),
      invalidatesTags: ["Report"],
      transformResponse: (response: SnakeCasedProperties<IReportToken>) =>
        humps.camelizeKeys(response) as IReport,
    }),
    addReportLabel: builder.mutation<IReport, AddReportLabelArgs>({
      query: ({ appId, reportId, labelId }) => ({
        url: `/reports/${reportId}/add_label`,
        method: "POST",
        body: { app_id: appId, label_id: labelId },
      }),
      invalidatesTags: ["Report"],
      transformResponse: (response: SnakeCasedProperties<IReport>) =>
        humps.camelizeKeys(response) as IReport,
    }),
    removeReportLabel: builder.mutation<IReport, RemoveReportLabelArgs>({
      query: ({ appId, reportId, labelId }) => ({
        url: `/reports/${reportId}/remove_label`,
        method: "DELETE",
        params: { app_id: appId, label_id: labelId },
      }),
      invalidatesTags: ["Report"],
      transformResponse: (response: SnakeCasedProperties<IReport>) =>
        humps.camelizeKeys(response) as IReport,
    }),
    duplicateReport: builder.mutation<IReport, UpdateReportArgs>({
      query: ({ appId, reportId }) => ({
        url: `/reports/${reportId}/duplicate`,
        method: "PUT",
        body: { app_id: appId },
      }),
      invalidatesTags: ["Report"],
      transformResponse: (response: SnakeCasedProperties<IReport>) =>
        humps.camelizeKeys(response) as IReport,
    }),
    deleteReport: builder.mutation<IReport, UpdateReportArgs>({
      query: ({ appId, reportId }) => ({
        url: `/reports/${reportId}`,
        method: "DELETE",
        params: { app_id: appId },
      }),
      invalidatesTags: ["Report"],
      transformResponse: (response: SnakeCasedProperties<IReport>) =>
        humps.camelizeKeys(response) as IReport,
    }),
    pinQuery: builder.mutation<IReport, UpdateReportArgs>({
      query: ({ appId, reportId, queryId }) => ({
        url: `/reports/${reportId}/pin_query`,
        method: "POST",
        params: humps.decamelizeKeys({ appId, queryId }),
      }),
      invalidatesTags: ["Report", "Insight", "Query"],
      transformResponse: (response: SnakeCasedProperties<IReport>) =>
        humps.camelizeKeys(response) as IReport,
    }),
    removeQuery: builder.mutation<IReport, UpdateReportArgs>({
      query: ({ appId, reportId, queryId }) => ({
        url: `/reports/${reportId}/remove_query`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId, queryId }),
      }),
      invalidatesTags: ["Report", "Insight"],
      transformResponse: (response: SnakeCasedProperties<IReport>) =>
        humps.camelizeKeys(response) as IReport,
    }),
    createFeature: builder.mutation<IReport, { appId: number; name: string }>({
      query: ({ appId, name }) => ({
        url: `/reports/create_feature`,
        method: "POST",
        params: humps.decamelizeKeys({ appId, name }),
      }),
      invalidatesTags: ["Report"],
      transformResponse: (response: SnakeCasedProperties<IReport>) =>
        humps.camelizeKeys(response) as IReport,
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGetSearchReportsQuery,
  useLazyGetSearchReportsQuery,
  useGetReportQuery,
  useLazyGetReportQuery,
  useGetReportTokenQuery,
  useShareReportMutation,
  useRefreshReportMutation,
  useRemovePublicAccessMutation,
  useDuplicateReportMutation,
  useDeleteReportMutation,
  useUpdateReportNameMutation,
  useAddReportLabelMutation,
  useRemoveReportLabelMutation,
  useGetReportTypesQuery,
  usePinQueryMutation,
  useRemoveQueryMutation,
  useCreateFeatureMutation,
} = reportsApi;
