import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getAudienceGroupId } from "core/helpers/audienceHelper";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  IShareViewInsightResponse,
  IViewInsight,
  useGetViewInsightTokenQuery,
  useShareViewInsightMutation,
  useUnshareViewInsightMutation,
} from "core/models/viewInsights";
import { AppObjectType, GROUP_TYPE_TO_SLUG } from "core/types/AppObject";

export const useShareViewInsight = ({
  viewInsight,
  groupId,
}: {
  viewInsight: IViewInsight;
  groupId?: string | undefined;
}) => {
  const { appObjectId, objectId } = useParams<{
    appObjectId: string;
    objectId: string;
  }>();
  const { appObjects } = useAppObjects();
  const currentObject = appObjects.find((o) => o.id === Number(appObjectId));
  const [token, setToken] = useState("");
  const [unshareViewInsight] = useUnshareViewInsightMutation();
  const [shareViewInsight] = useShareViewInsightMutation();

  const { data, isLoading } = useGetViewInsightTokenQuery({
    appId: viewInsight.appId,
    id: viewInsight.id as number,
    groupId,
  });

  const getGroupId = () => {
    if (groupId) return groupId;
    if (viewInsight.useViewAudience && viewInsight.view.audience) {
      const { groupId } = getAudienceGroupId(viewInsight.view.audience);
      return groupId;
    }

    return undefined;
  };

  useEffect(() => {
    if (data) setToken(data.token);
  }, [data]);

  const level = groupId
    ? GROUP_TYPE_TO_SLUG[currentObject?.objectType as AppObjectType]
    : viewInsight.level;
  const BASE_URL = import.meta.env.VITE_APP_HOST || "http://localhost:3000";
  const publicUrl = groupId
    ? `${BASE_URL}/a/${viewInsight.appId}/objects/${appObjectId}/object/${objectId}/insight/${viewInsight.id}/${token}?level=${level}&size=large`
    : `${BASE_URL}/a/${viewInsight.appId}/dashboard/${viewInsight.viewId}/insight/${viewInsight.id}/${token}?level=${level}&size=large`;

  function onShareViewInsight() {
    shareViewInsight({
      appId: viewInsight.appId,
      id: viewInsight.id as number,
      groupId: getGroupId(),
    })
      .unwrap()
      .then((response: IShareViewInsightResponse) => {
        setToken(response?.token);
      })
      .catch((error) => {
        console.error("Failed to share query:", error);
      });
  }

  function onUnshareViewInsight() {
    unshareViewInsight({
      appId: viewInsight.appId,
      id: viewInsight.id as number,
      groupId: getGroupId(),
    })
      .unwrap()
      .then(() => {
        setToken("");
      })
      .catch((error) => {
        console.error("Failed to unshare query:", error);
      });
  }

  return {
    token,
    isSharing: Boolean(token),
    isLoading,
    publicUrl,
    onShareViewInsight,
    onUnshareViewInsight,
  };
};
