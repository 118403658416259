import { BuildingOffice2Icon, UsersIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/Components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/Components/ui/popover";
import { useAppObjects } from "core/hooks/useAppObjects";
import { AppObjectType, IAppObject } from "core/types/AppObject";

const objectIcons = {
  [AppObjectType.User]: <UsersIcon className="h-4 w-4 text-gray-500" />,
  [AppObjectType.Company]: (
    <BuildingOffice2Icon className="h-4 w-4 text-gray-500" />
  ),
  [AppObjectType.Group]: <UsersIcon className="h-4 w-4 text-gray-500" />,
};

export const ObjectTypeSelector = ({
  selectedAppObject,
  setSelectedAppObject,
}: {
  selectedAppObject: IAppObject;
  setSelectedAppObject: (appObject: IAppObject) => void;
}) => {
  const { companyAppObject, groupAppObject } = useAppObjects();
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="flex w-full cursor-pointer items-center justify-between gap-1 rounded-md px-1.5 py-0.5 text-sm font-medium hover:bg-gray-200">
          <div className="flex items-center gap-2">
            {objectIcons[selectedAppObject.objectType]}
            {selectedAppObject.singularName}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="max-w-[150px] p-0">
        <Command>
          <CommandList>
            <CommandGroup>
              <CommandItem
                key={companyAppObject.id}
                value={companyAppObject.id.toString()}
                onSelect={() => {
                  setSelectedAppObject(companyAppObject);
                  setOpen(false);
                }}
                className="cursor-pointer rounded-md px-2 font-medium text-gray-900 hover:bg-gray-100"
              >
                <BuildingOffice2Icon className="h-4 w-4 text-gray-500" />
                {companyAppObject.singularName}
              </CommandItem>
              <CommandItem
                key={groupAppObject.id}
                value={groupAppObject.id.toString()}
                onSelect={() => {
                  setSelectedAppObject(groupAppObject);
                  setOpen(false);
                }}
                className="cursor-pointer rounded-md px-2 font-medium text-gray-900 hover:bg-gray-100"
              >
                <UsersIcon className="h-4 w-4 text-gray-500" />
                {groupAppObject.singularName}
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
