import { MoonIcon } from "@chakra-ui/icons";
import React from "react";

const ApiKeysEmptyState: React.FC = () => (
  <div className="flex max-w-lg flex-col items-center justify-center gap-3 py-4">
    <div>
      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-300">
        <MoonIcon color="gray.600" h={5} w={5} />
      </div>
    </div>
    <p className="text-sm text-gray-600">You don't have any API keys</p>
  </div>
);

export default ApiKeysEmptyState;
