import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import type { SnakeCasedPropertiesDeep } from "type-fest";

import { baseQuery } from "core/initializers/rtk";

export enum FavouriteObjectType {
  View = "view",
  Audience = "audience",
  Group = "group",
  Company = "company",
  User = "user",
  Report = "report",
}

export const FavouriteRoute = {
  [FavouriteObjectType.View]: "home",
  [FavouriteObjectType.Audience]: "audience",
  [FavouriteObjectType.Group]: "group",
  [FavouriteObjectType.Company]: "company",
  [FavouriteObjectType.User]: "contact",
  [FavouriteObjectType.Report]: "report",
};

export interface IFavourite {
  id: number;
  appId: number;
  userId: number;
  objectType: FavouriteObjectType;
  objectId: string;
  order: number;
}

export const favouritesApi = createApi({
  baseQuery,
  reducerPath: "favouritesApi",
  tagTypes: ["Favourites", "Favourite"],
  endpoints: (builder) => ({
    getFavourite: builder.query<
      IFavourite | null,
      { appId: number; objectId: string; objectType: FavouriteObjectType }
    >({
      query: ({ appId, objectId, objectType }) => ({
        url: `/favourites/${objectId}`,
        params: humps.decamelizeKeys({ appId, objectType, objectId }),
      }),
      providesTags: (result, error, arg) => [
        { type: "Favourite", id: `${arg.objectType}_${arg.objectId}` },
      ],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IFavourite> | null,
      ): IFavourite | null =>
        response ? (humps.camelizeKeys(response) as IFavourite) : null,
    }),
    getFavourites: builder.query<
      IFavourite[],
      { appId: number; objectTypes: FavouriteObjectType[] }
    >({
      query: ({ appId, objectTypes }) => ({
        url: "/favourites",
        params: humps.decamelizeKeys({ appId, objectTypes }),
      }),
      providesTags: ["Favourites"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IFavourite[]>,
      ): IFavourite[] => humps.camelizeKeys(response) as IFavourite[],
    }),
    deleteFavourite: builder.mutation<
      undefined,
      {
        appId: number;
        id: number;
        objectType: FavouriteObjectType;
        objectId: string;
      }
    >({
      query: ({ appId, id }) => ({
        url: `/favourites/${id}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Favourite", id: `${arg.objectType}_${arg.objectId}` },
        "Favourites",
      ],
    }),
    createFavourite: builder.mutation<
      undefined,
      { appId: number; objectType: FavouriteObjectType; objectId: string }
    >({
      query: ({ appId, objectType, objectId }) => ({
        url: "/favourites",
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          objectType,
          objectId,
        }),
      }),
      invalidatesTags: ["Favourites", "Favourite"],
    }),
  }),
});

export const {
  useGetFavouriteQuery,
  useLazyGetFavouriteQuery,
  useGetFavouritesQuery,
  useDeleteFavouriteMutation,
  useCreateFavouriteMutation,
} = favouritesApi;
