import { Button } from "@/Components/ui/button";
import { Input } from "@/Components/ui/input";
import { Spinner } from "@/Components/ui/spinner";
import { FrequencyBars } from "core/modules/reports/setup/Menu/FrequencyBars";
import { IPropertyValue } from "core/types/Property";

export const PropertyValueList = ({
  isLoading,
  hasMore,
  propertyValues,
  onChange,
  value: selectedValue,
  searchTerm,
  setSearchTerm,
  onLoadMore,
  ItemComponent,
}: {
  isLoading: boolean;
  hasMore: boolean;
  propertyValues: IPropertyValue[];
  onChange: (value: any) => void;
  value: string | null;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onLoadMore: () => void;
  ItemComponent: React.ComponentType<{
    value: string | null;
    onClick: () => void;
    isSelected: boolean;
    className?: string;
    children: React.ReactNode;
  }>;
}) => {
  return (
    <div className="flex w-full flex-col gap-2">
      <Input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        size="sm"
        placeholder="Search"
      />
      {isLoading && <Spinner size="small" />}
      {propertyValues.map(({ value, frequency }, index) => (
        <ItemComponent
          key={index}
          value={selectedValue}
          onClick={() => onChange(value)}
          isSelected={value === selectedValue}
          className="w-full"
        >
          <div className="flex w-full items-center justify-between gap-2">
            <span className="text-sm">{value}</span>
            <FrequencyBars frequency={frequency} />
          </div>
        </ItemComponent>
      ))}
      {hasMore && !isLoading ? (
        <Button
          onClick={onLoadMore}
          variant="default"
          size="sm"
          className="rounded-none rounded-b-md"
        >
          Load more
        </Button>
      ) : null}
      {propertyValues.length === 0 && !isLoading && (
        <p className="pb-2 text-center text-sm text-gray-500">
          No values found
        </p>
      )}
    </div>
  );
};
