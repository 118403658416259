import React from "react";

import { Skeleton } from "@/Components/ui/skeleton";
import { thousandsToK } from "core/helpers/thousands";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetAudienceCountQuery } from "core/models/audiences";
import { IAudience } from "core/types/Audience";

interface IAudienceCountCellProps {
  props: IAudience;
}

export const UsersCountCell: React.FC<IAudienceCountCellProps> = ({
  props,
}) => {
  const { id: appId } = useCurrentApp();
  const { data, isLoading, isFetching } = useGetAudienceCountQuery({
    appId: appId,
    audienceFilters: {
      filterGroups: props?.filterGroups,
      joinOperator: props?.joinOperator,
    },
    level: "user",
  });

  if (isLoading || isFetching)
    return (
      <div className="w-full">
        <Skeleton className="h-4 w-1/2 rounded-lg" />
      </div>
    );

  return (
    <div className="w-full font-medium text-gray-700">
      {thousandsToK(data?.count || 0)}
    </div>
  );
};
