import humps from "humps";
import { toast } from "sonner";

import axios from "core/initializers/axios";
import { slice } from "core/models/group";
import { IGroup } from "core/types/Group";
import { AppThunk } from "core/types/store";

interface IGroupParams {
  appId: string | number;
  id: string;
  showError?: boolean;
}

export const fetchOne =
  ({ appId, id, showError }: IGroupParams): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.fetchingOne({ id }));
    try {
      const { data } = await axios.get<IGroup>(`/groups/${id}/profile`, {
        params: humps.decamelizeKeys({ appId }),
      });
      dispatch(slice.actions.upsertOne({ id, data }));
    } catch (e: any) {
      if (!showError) return;
      const title = "Failed to fetch group";
      const description = e?.response?.data?.error;
      const error = `${title}: ${description}`;
      toast.error(title, {
        description,
      });
      dispatch(slice.actions.fetchOneFailed({ id, error }));
    }
  };
