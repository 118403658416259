import { motion } from "framer-motion";
import { Link, useParams } from "react-router-dom";

import { Button } from "@/Components/ui/button";
import NewLogo from "core/design-system/components/Icon/Logos/NewLogo.svg";

const Success = () => {
  const { appId } = useParams();

  return (
    <div
      className="flex h-screen w-screen items-center justify-center"
      style={{
        backgroundImage:
          "radial-gradient(circle at center, #7b7bff1c 0, #ffffff 60%)",
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="flex w-[480px] flex-col gap-6 rounded-lg bg-white p-6 shadow-[0_0_0_0_rgba(0,0,0,0.05),0_2px_4px_rgba(0,0,0,0.05)]"
      >
        <div className="flex w-full flex-col items-center">
          <div className="flex flex-1 space-x-3">
            <img className="h-[40px]" src={NewLogo} alt="" />
            <img className="h-[35px]" src="/logos/segment.svg" alt="" />
          </div>
          <h2 className="mb-2 mt-4 text-lg font-semibold">
            Successfully connected to Segment
          </h2>
          <p className="text-md text-center text-gray-600">
            You can now receive events from Segment into June
          </p>
        </div>

        <Button asChild>
          <Link to={`/a/${appId}/getting-started`} className="text-purple-500">
            Continue
          </Link>
        </Button>
      </motion.div>
    </div>
  );
};

export default Success;
