import { Button, Container } from "@chakra-ui/react";
import { Navigate, useParams } from "react-router-dom";

import { useGetObjectQuery } from "@/core/models/objects/object";
import { Avatar, AvatarFallback, AvatarImage } from "Components/ui/avatar";
import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { useUserAuth } from "core/hooks/useUserAuth";
import { IView } from "core/models/views";
import { cx } from "helpers/cx";
import { inIframe } from "helpers/iframe";
import { PublicViewInsightsContainer } from "modules/ViewInsight/PublicViewInsightsContainer";

interface IPublicViewProps {
  appId: number;
  view: IView;
  sharingSecretToken: string;
}

const SignUpBanner = () => {
  if (inIframe()) return null;

  return (
    <div
      className="flex w-[985px] items-start justify-center"
      data-id-public-banner
    >
      <div className="mb-4 flex w-full items-center justify-between rounded-lg border border-gray-50 bg-white px-4 py-3 shadow-sm">
        <div className="flex items-center">
          <div className="flex">
            <CompanyJuneLogoIcon h={6} w={6} color="primary" />
          </div>
          <p className="ml-4 text-sm font-medium">
            Welcome to June! Create an account to edit and collaborate on this
            dashboard.
          </p>
        </div>
        <div className="flex items-center">
          <Button as="a" href="https://analytics.june.so/login" fontSize="sm">
            Log in
          </Button>
          <Button
            ml={4}
            as="a"
            href="https://analytics.june.so/start"
            fontSize="sm"
            colorScheme="purple"
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};

export const PublicView: React.FC<IPublicViewProps> = ({
  appId,
  view,
  sharingSecretToken,
}) => {
  const { currentUser } = useUserAuth();
  const { appObjectId, objectId } = useParams();

  const { data: object } = useGetObjectQuery({
    appId,
    appObjectId: Number(appObjectId),
    objectId: objectId || "",
    viewId: Number(view.id),
    sharingSecretToken,
  });

  if (currentUser && !inIframe())
    if (currentUser && !inIframe())
      return <Navigate to={`/a/${appId}/home/${view.id}`} />;

  const traits = object?.traits || [];
  const avatar = traits.find((trait) => trait.name === "avatar");
  const name = traits.find((trait) => trait.name === "name");
  const domain = traits.find((trait) => trait.name === "domain");

  function visibleName() {
    return name?.value || domain?.value || "Unknown";
  }

  return (
    <div
      data-testid="page-container"
      className={cx(
        "h-full min-h-[100vh] w-full min-w-[100vw] overflow-hidden bg-white",
      )}
    >
      <div className="h-[99.9vh] p-2">
        <div className={cx("h-full overflow-auto rounded-lg py-8")}>
          <Container
            px="60px"
            maxW="container.lg"
            minW="container.lg"
            mx="auto"
          >
            <SignUpBanner />
            <div className="mb-3 flex w-full items-center justify-between">
              {objectId ? (
                <div className="mr-4 flex shrink-0 items-center gap-4">
                  <Avatar className="h-[48px] w-[48px] rounded-lg">
                    <AvatarImage src={avatar?.value} />
                    <AvatarFallback className="h-[48px] w-[48px] rounded-lg capitalize">
                      {visibleName().charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  <h1 className="max-w-[200px] truncate text-xl font-semibold">
                    {visibleName()}
                  </h1>
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="flex items-center gap-2">
                    <p className="text-2xl">
                      {view?.emoji ? view?.emoji : "🏠"}
                    </p>
                    <p className="text-2xl">{view.name}</p>
                  </div>
                </div>
              )}
            </div>
            {view && (
              <PublicViewInsightsContainer
                viewId={Number(view.id)}
                sharingSecretToken={sharingSecretToken}
              />
            )}
          </Container>
        </div>
      </div>
    </div>
  );
};
