import { TrashIcon } from "lucide-react";
import React, { useState } from "react";

import { Button } from "@/Components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { IView } from "core/models/views";
import { DeleteViewModal } from "modules/Navigation/Sidebar/DeleteViewModal";

export const DeleteAction: React.FC<{ view: IView }> = ({ view }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="ghost" size="icon" onClick={() => setIsOpen(true)}>
          <TrashIcon className="h-4 w-4 text-red-500" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Delete dashboard</TooltipContent>

      <DeleteViewModal
        isOpen={isOpen}
        view={view}
        onClose={() => setIsOpen(false)}
        route="/a/:appId/dashboards"
      />
    </Tooltip>
  );
};
