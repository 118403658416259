import { Trash } from "lucide-react";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { Button } from "@/Components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/Components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { useDeleteAudienceMutation } from "@/core/models/audiences";
import { IAudience } from "@/core/types/Audience";

export const DeleteAction: React.FC<{ audience: IAudience }> = ({
  audience,
}) => {
  const { appId } = useParams();
  const [deleteAudience] = useDeleteAudienceMutation();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleDelete = async () => {
    if (!audience.id) return;

    await deleteAudience({ appId: Number(appId), audienceId: audience.id })
      .unwrap()
      .then(() => {
        toast.success("Audience deleted successfully");
        setIsOpen(false);
      })
      .catch(() => {
        toast.error("Failed to delete audience");
      });
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsOpen(true)}
              className="px-1 text-red-500"
            >
              <Trash className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Delete audience</TooltipContent>
        </Tooltip>

        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Audience</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this audience? This action cannot
              be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-4">
            <Button variant="ghost" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
