import { TimeIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";

import {
  GroupCohortData,
  ICohortTableCell,
  UserCohortData,
} from "core/components/CohortTable/CohortTable.types";
import { getIntervalDate } from "core/components/CohortTable/helpers";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";

function getTextColor({
  rowData,
  colorKey,
}: {
  rowData: UserCohortData["data"][0] & GroupCohortData["data"][0];
  colorKey: keyof IColorPalette;
}) {
  const { purple, gray } = colors;

  if (rowData?.incomplete) return "black";
  if (rowData?.retention === 0) return gray[500];

  return Number(colorKey) > 400 ? "white" : purple[900];
}

function getBackgroundColor({
  rowData,
  colorKey,
}: {
  rowData: UserCohortData["data"][0] & GroupCohortData["data"][0];
  colorKey: keyof IColorPalette;
}) {
  const { templateLightPurple, gray } = colors as {
    templateLightPurple: IColorPalette;
    gray: IColorPalette;
  };
  if (rowData?.incomplete) return gray[300];
  if (rowData?.retention === 0) return gray[50];

  return templateLightPurple[colorKey];
}

function getRowColor({
  rowData,
}: {
  rowData: UserCohortData["data"][0] & GroupCohortData["data"][0];
}) {
  const { templateLightPurple } = colors;

  const retention = Math.floor(rowData?.retention / 10);
  let colorIndex = retention > 9 ? 9 : retention;

  const colorKey = Object.keys(templateLightPurple)[
    colorIndex
  ] as unknown as keyof IColorPalette;
  const textColor = getTextColor({ rowData, colorKey });
  const backgroundColor = getBackgroundColor({ rowData, colorKey });

  return {
    textColor,
    backgroundColor,
  };
}

export const Cell = ({
  unit,
  valueType,
  rowData,
  intervalType,
  hideWeekends,
  cohortIntervalDate,
  setCohortStartDate,
  setCohortIntervalStartDate,
  setRetainedUsersCount,
  setDroppedUsersCount,
  previewOrSharingMode,
  onAudienceOpen,
}: ICohortTableCell) => {
  const { textColor, backgroundColor } = getRowColor({ rowData });
  const rowText =
    valueType === "count"
      ? unit === "company"
        ? rowData?.retainedGroupsCount
        : rowData?.retainedUsersCount
      : `${Number(rowData?.retention).toFixed(0)}%`;
  const intervalNumber = rowData?.[intervalType];

  function handleCohortClick() {
    if (previewOrSharingMode) return;
    if (unit === "user") {
      setRetainedUsersCount(rowData?.retainedUsersCount);
      setDroppedUsersCount(rowData?.droppedUsersCount);
    } else {
      setRetainedUsersCount(rowData?.retainedGroupsCount);
    }

    setCohortStartDate(cohortIntervalDate);
    setCohortIntervalStartDate(rowData?.startDate);
    onAudienceOpen();
  }

  if (hideWeekends) {
    return (
      <div className="my-2 mr-2 flex h-[30px] w-[70px] shrink-0 items-center justify-center rounded-md border-white bg-gray-100"></div>
    );
  }

  return (
    <Tooltip
      hasArrow
      placement="top"
      label={
        <Box>
          <Text mb={2} fontWeight="semibold">
            {Math.round(rowData?.retention)}%{" "}
            {intervalNumber === 0 ? "the starting" : `after ${intervalNumber}`}{" "}
            {intervalType}
            {intervalNumber && intervalNumber > 1 ? "s" : ""}{" "}
            {(rowData?.incomplete && "(in progress)") || ""}
          </Text>
          <Text>
            {getIntervalDate({
              intervalType,
              startDate: rowData?.startDate,
              endDate: rowData?.endDate,
            })}
          </Text>
        </Box>
      }
    >
      <Flex
        h="30px"
        w="70px"
        my={2}
        mr={2}
        flexShrink={0}
        align="center"
        justify="center"
        bg={backgroundColor}
        borderRadius="md"
        borderColor="white"
        _hover={{ cursor: "pointer", filter: "brightness(0.8)" }}
        onClick={handleCohortClick}
      >
        <Text fontSize="sm" color={textColor}>
          {rowText}{" "}
          {rowData?.incomplete && <TimeIcon h={3} w={3} color="black" mb={1} />}
        </Text>
      </Flex>
    </Tooltip>
  );
};
