import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
} from "@chakra-ui/react";
import {
  CalendarDaysIcon,
  CalendarIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Body, Container, Header } from "@/core/components/ViewInsight";
import { AudienceTable } from "@/core/components/ViewInsight/Graphs/AudienceTable";
import { cn } from "@/lib/utils";
import AnimatedToggle from "core/components/AnimatedToggle";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { ABSOLUTE, DAY, MONTH, WEEK } from "core/constants/timerange";
import { TwEditable } from "core/design-system/components/TwEditable";
import { IColorPalette } from "core/design-system/constants/theme/colors";
import { useDebouncedCallback } from "core/hooks/useDebouncedCallback";
import {
  COLOR_NAME_TO_PALETTE,
  GraphTypeToIcon,
  InsightTypeConfigs,
  IUpdateInsightPayload,
} from "core/hooks/useEditViewInsight";
import { useInsightDataFetching } from "core/hooks/useInsightDataFetching";
import { useViewInsightTimerange } from "core/hooks/useViewInsightTimerange";
import { IViewInsight } from "core/models/viewInsights";
import { TimerangePicker } from "core/modules/reports/Timerange/TimerangePicker";
import { Plan } from "core/types/App";
import { IAppObject } from "core/types/AppObject";
import { IReport } from "core/types/Report";
import {
  Aggregation,
  AggregationIcons,
  AggregationOptions,
  AggregationToLabel,
  GraphType,
  Interval,
  Measure,
  Size,
} from "core/types/ViewInsight";
import { ColorPicker } from "modules/Labels/ColorPicker";
import { showActiveUsersBreakdown } from "modules/ViewInsight/ViewInsightComponent";
import { getInsightConfig } from "modules/ViewInsight/ViewInsightContainer";

export const INTERVAL_TYPES = {
  [Interval.Daily]: DAY,
  [Interval.Weekly]: WEEK,
  [Interval.Monthly]: MONTH,
};

export const PreviewContainer: React.FC<{
  viewInsight: IViewInsight;
  report: IReport;
  measure: Measure;
  size: Size;
  width: string;
  height: string;
  graphType: GraphType;
  colors: IColorPalette[];
  colorPalette: IColorPalette;
  title: string;
  interval: Interval;
  appObject: IAppObject;
  isAddingNewInsight?: boolean;
  onUpdateInsight: (payload: IUpdateInsightPayload) => void;
  isUpdatingViewInsight: boolean;
  hideControls?: boolean;
}> = ({
  viewInsight,
  report,
  measure,
  appObject,
  size,
  width,
  height,
  graphType,
  colorPalette,
  colors,
  title,
  interval,
  isAddingNewInsight,
  onUpdateInsight,
  isUpdatingViewInsight,
  hideControls = false,
}) => {
  const { groupId, objectId } = useParams();
  const insightConfig = getInsightConfig(
    viewInsight.insight.reportType,
    viewInsight.insightId as number,
  );
  const color = COLOR_NAME_TO_PALETTE[viewInsight?.color as string];

  const {
    label,
    relativeTimerange,
    customTimerange,
    readableCustomTimerange,
    setCustomTimerange,
    saveTimerange,
    timerangeValue,
    timerangeType,
    timerangeStartTimestamp,
    timerangeEndTimestamp,
  } = useViewInsightTimerange(viewInsight);

  const { response, isLoading, isFetching, refetch } = useInsightDataFetching({
    report,
    view: viewInsight.view,
    viewInsight,
    insightType: viewInsight.insightId,
    insightParams: INTERVAL_TYPES[interval]
      ? {
          ...viewInsight.insight?.defaultParams,
          interval_type: INTERVAL_TYPES[interval],
          size,
        }
      : { ...viewInsight.insight?.defaultParams, size },
    sharingSecretToken: null,
    previewMode: false,
    groupType: groupId || objectId ? undefined : appObject?.slug,
    timerangeType,
    timerangeValue,
    timerangeStartTimestamp,
    timerangeEndTimestamp,
    audience: viewInsight.useViewAudience
      ? viewInsight.view.audience
      : undefined,
  });

  const data = insightConfig?.view
    ? insightConfig?.view?.transform(
        response?.data,
        interval,
        showActiveUsersBreakdown(viewInsight),
      )
    : [];

  const [highlight, setHighlight] = useState(
    response?.data?.highlight ??
      insightConfig?.view?.highlight?.(response?.data) ??
      {},
  );

  useEffect(() => {
    const fetchInsightData = async () => {
      if (isUpdatingViewInsight) return;

      await refetch();
      setHighlight(
        response?.data?.highlight ??
          insightConfig?.view?.highlight?.(response?.data) ??
          {},
      );
    };
    fetchInsightData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInsight, isUpdatingViewInsight, response?.data]);

  const Component = viewInsight?.audience ? (
    AudienceTable
  ) : insightConfig?.view ? (
    insightConfig?.view?.Components ? (
      insightConfig?.view?.Components[
        graphType as keyof typeof insightConfig.view.Components
      ]
    ) : (
      insightConfig?.view?.Component
    )
  ) : (
    <></>
  );

  function isColorPickerDisabled() {
    return [
      GraphType.Scatter,
      GraphType.StackedLine,
      GraphType.StackedBar,
      GraphType.Table,
      GraphType.ContributionGraph,
    ].includes(graphType);
  }

  function isAggregationPickerEnabled() {
    return viewInsight.insight.hasAggregations || false;
  }

  const handleTitleChange = useDebouncedCallback((title: string) => {
    onUpdateInsight({ title });
  }, 500);

  return (
    <div className="h-full w-full bg-gradient-to-b from-gray-50 to-gray-100 p-5">
      {!hideControls && (
        <div className="flex justify-between">
          <div className="flex w-full items-center justify-end gap-x-2">
            <div className="w-[65px]">
              <ColorPicker
                color={colorPalette}
                setColor={(color) => onUpdateInsight({ colorPalette: color })}
                colors={colors}
                isDisabled={isColorPickerDisabled()}
                shade={300}
                disabledTooltip="This graph type does not support colors"
              />
            </div>
            <Menu matchWidth>
              <PaywallPopover
                feature="company profile insights"
                redirect="people"
                plan={Plan.Growth}
                overridePaywall={!Boolean(groupId) || isAddingNewInsight}
              >
                <MenuButton
                  textAlign="left"
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  textTransform="capitalize"
                >
                  <div className="flex items-center gap-1">
                    <>
                      {GraphTypeToIcon[graphType]}
                      {graphType.split(`_`).join(` `)}
                    </>
                  </div>
                </MenuButton>
              </PaywallPopover>
              <MenuList w="200px">
                {Object.values(GraphType)
                  .filter((g) =>
                    g === GraphType.ContributionGraph ? Boolean(groupId) : true,
                  )
                  .reverse()
                  .map(
                    (graphType) =>
                      measure &&
                      Object.keys(
                        InsightTypeConfigs[measure].graphTypes,
                      ).includes(graphType) &&
                      InsightTypeConfigs?.[measure]?.graphTypes?.[
                        graphType
                      ]?.appObjectTypes?.includes(appObject.objectType) && (
                        <MenuItem
                          textTransform="capitalize"
                          onClick={() => onUpdateInsight({ graphType })}
                        >
                          <div className="flex items-center gap-1">
                            {GraphTypeToIcon[graphType]}
                            {graphType.split(`_`).join(` `)}
                          </div>
                        </MenuItem>
                      ),
                  )}
              </MenuList>
            </Menu>
            {isAggregationPickerEnabled() && (
              <Menu matchWidth>
                <MenuButton
                  as={Button}
                  textTransform="capitalize"
                  rightIcon={<ChevronDownIcon />}
                >
                  <div className="flex items-center gap-1">
                    {React.createElement(
                      AggregationIcons[viewInsight.aggregation],
                      {
                        className: "h-4 w-4",
                      },
                    )}
                    {AggregationToLabel[viewInsight.aggregation]}
                  </div>
                </MenuButton>
                <MenuList>
                  {AggregationOptions.map((aggregationValue) => (
                    <MenuItem
                      onClick={() => {
                        onUpdateInsight({
                          aggregation: aggregationValue as Aggregation,
                        });
                      }}
                      textTransform="capitalize"
                    >
                      <div className="flex items-center gap-1">
                        {React.createElement(
                          AggregationIcons[aggregationValue as Aggregation],
                          {
                            className: "h-4 w-4",
                          },
                        )}
                        {AggregationToLabel[aggregationValue as Aggregation]}
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
          </div>
        </div>
      )}
      <Center h="full">
        <div style={{ width: width, height: height }}>
          <Container>
            <div className="flex h-full w-full flex-col">
              <Header>
                <div className="flex flex-col gap-y-3">
                  <div className="flex w-full items-center justify-between">
                    <div className="max-w-[50%]">
                      {title && (
                        <TwEditable
                          defaultValue={title}
                          onSubmit={(title) => onUpdateInsight({ title })}
                          onChange={handleTitleChange}
                          placeholder={"Untitled"}
                          maxW="100%"
                        />
                      )}
                    </div>
                    <div className="flex">
                      {measure &&
                        InsightTypeConfigs?.[measure]?.graphTypes?.[
                          graphType
                        ] &&
                        InsightTypeConfigs?.[measure]?.graphTypes?.[graphType]
                          ?.intervals?.length > 0 && (
                          <Menu matchWidth>
                            <MenuButton
                              textAlign="left"
                              size="xs"
                              as={Button}
                              variant="ghost"
                              textTransform="capitalize"
                              isDisabled={
                                typeof insightConfig?.view
                                  ?.hasIntervalPicker !== "undefined" &&
                                !insightConfig?.view?.hasIntervalPicker
                              }
                            >
                              <div className="flex items-center gap-x-1">
                                <CalendarDaysIcon className="h-3.5 w-3.5" />
                                <p className="text-xs">{interval}</p>
                              </div>
                            </MenuButton>
                            <MenuList>
                              {measure &&
                                InsightTypeConfigs?.[measure]?.graphTypes?.[
                                  graphType
                                ]?.intervals.map((interval) => (
                                  <MenuItem
                                    fontSize="xs"
                                    textTransform="capitalize"
                                    onClick={() =>
                                      onUpdateInsight({ interval })
                                    }
                                  >
                                    {interval}
                                  </MenuItem>
                                ))}
                            </MenuList>
                          </Menu>
                        )}
                      {insightConfig?.view?.hasTimerangePicker &&
                        viewInsight.measure !== Measure.Audience && (
                          <TimerangePicker
                            label={label}
                            options={
                              insightConfig?.view?.dateRangeOptions || []
                            }
                            timerangeType={timerangeType}
                            relativeTimerange={relativeTimerange}
                            customTimerange={customTimerange}
                            setCustomTimerange={setCustomTimerange}
                            saveTimerange={saveTimerange}
                            size="small"
                            tooltipText={
                              timerangeType === ABSOLUTE
                                ? readableCustomTimerange
                                : undefined
                            }
                            footerText="Insights are in UTC time"
                            customMenuButton={
                              <div className="flex items-center gap-x-1 text-black">
                                <CalendarIcon className="h-3.5 w-3.5" />
                                <p className="text-xs">{label}</p>
                              </div>
                            }
                          />
                        )}
                    </div>
                  </div>
                  {Boolean(highlight) &&
                    !isNaN(highlight?.value as number) &&
                    viewInsight.measure !== Measure.Audience && (
                      <div className="flex flex-row items-end gap-x-1">
                        <p className="line-clamp-1 text-3xl font-medium leading-tight">
                          {Math.round(highlight?.value as number)}
                          {highlight?.unit}
                        </p>
                        <p
                          className="mb-1 line-clamp-1 text-xs font-medium"
                          style={{ color: color[500] }}
                        >
                          {highlight?.description}
                        </p>
                      </div>
                    )}
                </div>
              </Header>
              <Body viewInsight={viewInsight}>
                {data?.length === 0 && !(isLoading || isFetching) ? (
                  <div className="flex h-full w-full flex-col items-center justify-center gap-y-2 text-gray-500">
                    <ExclamationCircleIcon className="h-5 w-5" />
                    <p className="text-xs">No data available</p>
                  </div>
                ) : (
                  <div
                    className={cn(
                      "flex h-full w-full",
                      viewInsight.measure !== Measure.Audience && "justify-end",
                      viewInsight.measure === Measure.Audience && "pt-2",
                    )}
                  >
                    {isLoading || isFetching ? (
                      <div className="h-full w-full">
                        <Skeleton height="full" w="full" borderRadius="lg" />
                      </div>
                    ) : (
                      Component && (
                        <Component
                          data={data}
                          {...data}
                          color={colorPalette}
                          size={size}
                          interval={interval}
                          measure={measure}
                          viewInsight={viewInsight}
                          isPreview
                        />
                      )
                    )}
                  </div>
                )}
              </Body>
            </div>
          </Container>
          <Center>
            {!hideControls && (
              <div className="absolute bottom-24">
                <AnimatedToggle
                  items={[
                    {
                      label: "Small",
                      value: "small",
                      isDisabled:
                        measure &&
                        !InsightTypeConfigs[measure].sizes.includes(Size.Small),
                    },
                    {
                      label: "Medium",
                      value: "medium",
                      isDisabled:
                        (measure &&
                          !InsightTypeConfigs[measure].sizes.includes(
                            Size.Medium,
                          )) ||
                        graphType === GraphType.Trend,
                    },
                    {
                      label: "Large",
                      value: "large",
                      isDisabled:
                        (measure &&
                          !InsightTypeConfigs[measure].sizes.includes(
                            Size.Large,
                          )) ||
                        graphType === GraphType.Trend,
                    },
                  ]}
                  value={size}
                  containerProps={{
                    bg: "gray.200",
                  }}
                  onChange={(val, e) => {
                    e.preventDefault();
                    onUpdateInsight({ size: val as Size });
                  }}
                />
              </div>
            )}
          </Center>
        </div>
      </Center>
    </div>
  );
};
