import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Button } from "Components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "Components/ui/dialog";
import { Input } from "Components/ui/input";
import { Label } from "Components/ui/label";
import {
  Select as UISelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "Components/ui/select";
import { Tooltip, TooltipContent, TooltipTrigger } from "Components/ui/tooltip";
import {
  DataTypeString,
  DataTypeToString,
  ITraitKeyResponse,
  useGetTraitQuery,
  useUpdateTraitMutation,
} from "core/models/traits";
import { AppObjectType, IAppObject } from "core/types/AppObject";
import { ITraitOption } from "core/types/Trait";
import { TraitOption } from "routes/Settings/Objects/TraitOption";

export enum TraitLevel {
  USER_LEVEL = 0,
  GROUP_LEVEL = 1,
  COMPANY_LEVEL = 2,
}

export const OBJECT_TYPE_TO_TRAIT_LEVEL = {
  [AppObjectType.User]: TraitLevel.USER_LEVEL,
  [AppObjectType.Group]: TraitLevel.GROUP_LEVEL,
  [AppObjectType.Company]: TraitLevel.COMPANY_LEVEL,
};

export const TraitEditModal: React.FC<{
  trait: ITraitKeyResponse;
  isOpen: boolean;
  onClose: () => void;
  appObject: IAppObject;
}> = ({ trait, isOpen, onClose, appObject }) => {
  const { appId } = useParams();
  const [type, setType] = useState<DataTypeString | undefined>(undefined);
  const [prefix, setPrefix] = useState<string | undefined>(undefined);
  const [suffix, setSuffix] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<ITraitOption[]>([]);

  const level = OBJECT_TYPE_TO_TRAIT_LEVEL[appObject.objectType];

  const { data: traitData, isLoading } = useGetTraitQuery({
    appId: Number(appId),
    trait: trait.trait,
    level,
  });

  useEffect(() => {
    if (!traitData) return;

    setType(traitData.dataType);
    setPrefix(traitData.prefix);
    setSuffix(traitData.suffix);
    setOptions(traitData.traitOptions);
  }, [traitData]);

  const [updateTrait] = useUpdateTraitMutation();

  function onAddOption() {
    setOptions([
      ...options,
      { uuid: Math.random(), label: "", value: "", color: "purple" },
    ]);
  }

  const hasEmptyOptions = options.some((option) => option.label.trim() === "");

  function onUpdate() {
    if (!traitData) return;

    const filteredOptions = options.filter(
      (option) => option.label.trim() !== "",
    );

    updateTrait({
      appId: Number(appId),
      id: traitData.id,
      dataType: type,
      prefix: prefix || "",
      suffix: suffix || "",
      traitOptions: filteredOptions,
    });
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit trait {trait.trait}</DialogTitle>
          <DialogDescription>Edit trait properties</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <Label>Prefix</Label>
            {isLoading ? (
              <div className="h-[35px] w-full animate-pulse rounded-lg bg-gray-200" />
            ) : (
              <Input
                size="sm"
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              />
            )}
            <Label>Suffix</Label>
            {isLoading ? (
              <div className="h-[35px] w-full animate-pulse rounded-lg bg-gray-200" />
            ) : (
              <Input
                size="sm"
                value={suffix}
                onChange={(e) => setSuffix(e.target.value)}
              />
            )}
            <Label>Data type</Label>
            {isLoading ? (
              <div className="h-[35px] w-full animate-pulse rounded-lg bg-gray-200" />
            ) : (
              <UISelect
                disabled={trait?.isComputed}
                value={type?.toLowerCase().replace(" ", "_")}
                onValueChange={(value) => setType(value as DataTypeString)}
              >
                <SelectTrigger className="w-full capitalize">
                  <SelectValue placeholder="Select data type" />
                </SelectTrigger>
                <SelectContent>
                  {Object.values(DataTypeToString).map((dataType) => (
                    <SelectItem
                      key={dataType.toLowerCase().replace(" ", "_")}
                      value={dataType.toLowerCase().replace(" ", "_")}
                      className="capitalize"
                    >
                      {dataType}
                    </SelectItem>
                  ))}
                </SelectContent>
              </UISelect>
            )}
            {type === DataTypeString.OPTIONS && (
              <div className="mt-2 flex flex-col gap-2">
                <Label>Options</Label>
                {options?.map((option) => {
                  return (
                    <TraitOption
                      key={option.id}
                      option={option}
                      onUpdateOptionColor={(option, color) =>
                        setOptions(
                          options.map((o) =>
                            o.uuid
                              ? o.uuid === option.uuid
                                ? { ...o, color }
                                : o
                              : o.id === option.id
                                ? { ...o, color }
                                : o,
                          ),
                        )
                      }
                      onUpdateOptionLabel={(option, label) =>
                        setOptions(
                          options.map((o) =>
                            o.uuid
                              ? o.uuid === option.uuid
                                ? { ...o, label }
                                : o
                              : o.id === option.id
                                ? { ...o, label }
                                : o,
                          ),
                        )
                      }
                    />
                  );
                })}
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="w-fit">
                      <Button
                        className="flex"
                        variant="outline"
                        onClick={onAddOption}
                        disabled={hasEmptyOptions}
                      >
                        Add option
                      </Button>
                    </div>
                  </TooltipTrigger>
                  {hasEmptyOptions && (
                    <TooltipContent>
                      <p>
                        Please fill in all existing options before adding a new
                        one
                      </p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <DialogFooter className="mt-4">
          {traitData && (
            <Link
              target="_blank"
              to={`/a/${appId}/settings/objects/${appObject.id}/traits/${traitData.id}/debugger`}
            >
              <Button size="sm" variant="outline">
                Debugger
              </Button>
            </Link>
          )}
          <Button
            className="ml-2"
            variant="default"
            size="sm"
            onClick={() => {
              onUpdate();
              onClose();
            }}
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
