import { PinIcon } from "lucide-react";
import { Link, useParams } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/Components/ui/table";
import { DeleteAction } from "@/modules/Audiences/List/DeleteAction";
import { DuplicateAction } from "@/modules/Audiences/List/DuplicationAction";
import { AudiencePinCell } from "@/modules/Audiences/List/PinAction";
import { LoadingRow } from "@/modules/Dashboard/List/LoadingRow";
import { IAudience } from "core/types/Audience";
import { UsersCountCell } from "modules/Audiences/List/UsersCountCell";

interface TableContainerProps {
  isLoading: boolean;
  audiences: IAudience[];
}

const HoverActions = ({ audience }: { audience: IAudience }) => {
  return (
    <div className="inline-flex flex-row items-center gap-1 rounded-md border border-gray-300 bg-white px-1 py-1 opacity-0 shadow-sm transition-opacity group-hover:opacity-100">
      <AudiencePinCell props={audience} />
      <DuplicateAction audience={audience} />
      <DeleteAction audience={audience} />
    </div>
  );
};

export const TableContainer = ({
  isLoading,
  audiences,
}: TableContainerProps) => {
  const { appId } = useParams();

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[25%]">Name</TableHead>
          <TableHead className="w-[65%]">Description</TableHead>
          <TableHead className="w-[5%]">Count</TableHead>
          <TableHead className="w-[5%]"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isLoading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <LoadingRow key={index} />
          ))
        ) : audiences.length > 0 ? (
          audiences.map((audience) => (
            <TableRow key={audience.id} className="group">
              <TableCell>
                <Link
                  to={`/a/${appId}/audience/${audience.id}`}
                  className="flex items-center gap-2 font-medium"
                >
                  {audience.name}
                  {audience.pinned && (
                    <PinIcon className="h-4 w-4 fill-gray-500 text-gray-500" />
                  )}
                </Link>
              </TableCell>
              <TableCell>
                <Link
                  to={`/a/${appId}/audience/${audience.id}`}
                  className="flex items-center gap-2 text-gray-600"
                >
                  {audience.description}
                </Link>
              </TableCell>
              <TableCell>
                <UsersCountCell props={audience} />
              </TableCell>
              <TableCell className="text-right">
                <HoverActions audience={audience} />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4} className="h-24 text-center">
              No audiences found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
