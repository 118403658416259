import { useState, useId } from "react";

import {
  GroupCohortData,
  IntervalType,
  UserCohortData,
} from "@/core/components/CohortTable/CohortTable.types";
import { FEATURE_RELEASE } from "@/core/constants/report-configurations";
import { AppObjectType } from "@/core/types";
import { GraphType, Interval, Measure } from "@/core/types/ViewInsight";
import {
  COLOR_NAME_TO_PALETTE,
  GRID_WIDTH_TO_SIZE,
} from "core/hooks/useEditViewInsight";
import { IViewInsight } from "core/models/viewInsights";
import { IInsightSection } from "core/types/TemplateConfig";
import { getInsightConfig } from "modules/ViewInsight/ViewInsightContainer";
import { ViewInsightDrilldown } from "modules/ViewInsight/ViewInsightDrilldown";

interface IViewInsightComponent {
  viewInsight: IViewInsight;
  data: any;
  filter?: string;
  opacity?: number;
  isSlackInsight?: boolean;
}

export function showActiveUsersBreakdown(viewInsight: IViewInsight) {
  return (
    viewInsight.measure === Measure.ActiveUsers &&
    [GraphType.StackedLine, GraphType.StackedBar].includes(
      viewInsight.graphType as GraphType,
    ) &&
    viewInsight?.appObject?.objectType === AppObjectType.User
  );
}

const GetGraphComponent = (
  insightConfig: IInsightSection | undefined | null,
  viewInsight: IViewInsight,
) => {
  if (!insightConfig?.view) {
    return <></>;
  }

  const { Components, Component } = insightConfig.view;

  if (
    Components &&
    viewInsight.graphType &&
    viewInsight.graphType !== "null" &&
    Components[viewInsight.graphType as keyof typeof Components]
  ) {
    return Components[viewInsight.graphType as keyof typeof Components];
  }

  return Component;
};

interface UsageData {
  chartDate: string;
  usageCount: number;
  uniqueUserCount: number;
}

export enum FEATURE_WEEKLY_USAGE_INSIGHT_IDS {
  USER_DAILY = 21,
  USER_WEEKLY = 22,
  USER_MONTHLY = 23,
  GROUP_DAILY = 24,
  GROUP_WEEKLY = 25,
  GROUP_MONTHLY = 26,
}

export interface DataPoint {
  id?: string;
  x?: string;
  y?: string;
  eventIndex?: number;
  label?: string;
  conversion?: {
    count: number;
  };
  dropped?: {
    count: number;
  };
  humanizedDate?: string;
  days?: number;
  count?: number;
  change?: number;
  interval?: Interval;
  date?: string;
  startDate?: string;
  intervalStartDate?: string;
  retainedUsersCount?: number;
  droppedUsersCount?: number;
  intervalType?: IntervalType;
  chartDate?: string;
  data?: UserCohortData[] | GroupCohortData[] | UsageData;
}

export function hasDrillDowns(viewInsight: IViewInsight) {
  if (viewInsight.query) return false;

  if (viewInsight.report.reportType === FEATURE_RELEASE) {
    return true;
  }

  if (
    viewInsight.measure === Measure.Funnel &&
    viewInsight.graphType === GraphType.CohortGraph
  ) {
    return true;
  }

  return [Measure.ActiveUsers, Measure.NewUsers, Measure.Power].includes(
    viewInsight.measure,
  );
}

export const ViewInsightComponent: React.FC<IViewInsightComponent> = ({
  viewInsight,
  data,
  filter = "none",
  opacity = undefined,
  isSlackInsight = false,
}) => {
  const insightConfig = getInsightConfig(
    viewInsight.insight.reportType,
    viewInsight.insightId,
  ) as IInsightSection;
  const [isOpen, setIsOpen] = useState(false);
  const [dataPoint, setDataPoint] = useState<DataPoint | null>(null);
  const Component = GetGraphComponent(insightConfig, viewInsight);
  const color = COLOR_NAME_TO_PALETTE[viewInsight?.color as string];
  const id = useId();

  return (
    <div
      id="view-insight-component"
      className="h-full max-h-full w-full max-w-full"
      style={{
        filter: filter,
        opacity: opacity,
      }}
    >
      <Component
        viewInsight={viewInsight}
        data={data}
        config={insightConfig}
        {...data}
        color={color}
        size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth]}
        interval={viewInsight.interval}
        measure={viewInsight.measure}
        insightConfig={insightConfig}
        headless={true}
        slug={insightConfig?.slug}
        isClickable={hasDrillDowns(viewInsight) && !isSlackInsight}
        onClickDataPoint={
          isSlackInsight
            ? undefined
            : (payload: DataPoint) => {
                setIsOpen(true);
                setDataPoint({ ...payload, id });
              }
        }
        hasDrillDowns={hasDrillDowns(viewInsight) && !isSlackInsight}
      />
      {hasDrillDowns(viewInsight) && !isSlackInsight && dataPoint && (
        <ViewInsightDrilldown
          id={id}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          dataPoint={dataPoint}
          viewInsight={viewInsight}
          insightConfig={insightConfig}
        />
      )}
    </div>
  );
};
