import moment from "moment";
import React from "react";

const SentAtDateCell = ({
  props: { timestamp },
}: {
  props: { timestamp: string };
}) => {
  return (
    <div
      title={moment.utc(timestamp).format("MMMM Do, YYYY [at] h:mm:ss a")}
      className="group relative"
    >
      <div className="flex min-w-[120px] max-w-[120px]">
        <span className="truncate text-sm text-gray-600">
          {`${moment.utc(timestamp).fromNow()}`}
        </span>
      </div>
    </div>
  );
};

export default SentAtDateCell;
