import { XMarkIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

import { useAppObjects } from "@/core/hooks/useAppObjects";
import { ComparisonSelector } from "@/core/modules/audience/Filters/ObjectFilter/ComparisonSelector";
import { ObjectSelector } from "@/core/modules/audience/Filters/ObjectFilter/ObjectSelector";
import { ObjectTypeSelector } from "@/core/modules/audience/Filters/ObjectFilter/ObjectTypeSelector";
import { AppObjectType, IAppObject } from "@/core/types/AppObject";
import { IGroup } from "@/core/types/Group";
import { ComparisonType, IFilter } from "core/types/Filters.d";

export const ObjectFilter = ({
  filter,
  filterIndex,
  onRemoveFilter,
  onSaveFilter,
}: {
  filter: IFilter;
  filterIndex: number;
  onRemoveFilter: (filter: IFilter) => void;
  onSaveFilter: (filter: IFilter) => void;
}) => {
  console.log("[ObjectFilter.tsx] filter", filter);

  const { groupAppObject, companyAppObject } = useAppObjects();
  const [selectedAppObject, setSelectedAppObject] = useState<IAppObject>(
    filter.body.objectType === AppObjectType.Group
      ? groupAppObject
      : companyAppObject,
  );
  const [selectedObject, setSelectedObject] = useState<IGroup | null>(null);

  return (
    <div className="flex rounded-lg">
      <div className="flex w-full flex-row items-center gap-0.5 rounded-md bg-gray-50 px-2 py-1">
        <div>
          {selectedAppObject && (
            <ObjectTypeSelector
              selectedAppObject={selectedAppObject}
              setSelectedAppObject={(appObject) => {
                setSelectedAppObject(appObject);
              }}
            />
          )}
        </div>
        <div className="border-l border-gray-100 px-1">
          <ComparisonSelector
            comparisonType={filter.body.comparisonType || ComparisonType.IS}
            setComparisonType={(comparisonType) => {
              onSaveFilter({
                ...filter,
                body: { ...filter.body, comparisonType },
              });
            }}
          />
        </div>
        <div className="border-l border-gray-100 px-1">
          <ObjectSelector
            selectedAppObject={selectedAppObject}
            selectedObject={selectedObject}
            selectObject={(object) => {
              setSelectedObject(object);
            }}
          />
        </div>
        <div
          onClick={() => onRemoveFilter(filter)}
          className="flex h-full cursor-pointer items-center rounded-r-md bg-gray-50 px-3 text-gray-500 hover:text-black"
        >
          <XMarkIcon className="h-4" />
        </div>
      </div>
    </div>
  );
};
