import { PinIcon, PinOff } from "lucide-react";
import React from "react";
import { useParams } from "react-router-dom";

import { Button } from "@/Components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { IView, usePinViewMutation } from "core/models/views";

export const PinAction: React.FC<{ view: IView }> = ({ view }) => {
  const { appId } = useParams();
  const [pinView] = usePinViewMutation();

  function handlePin() {
    if (view?.id === undefined) return null;

    pinView({
      appId: Number(appId),
      viewId: Number(view.id),
      pinned: !view.pinned,
    });
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          onClick={handlePin}
          className="text-gray-600"
        >
          {view.pinned ? (
            <PinOff className="h-4 w-4" />
          ) : (
            <PinIcon className="h-4 w-4" />
          )}
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {view.pinned ? "Unpin dashboard" : "Pin dashboard"}
      </TooltipContent>
    </Tooltip>
  );
};
