import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { ContactAvatar } from "core/components/Avatars/ContactAvatar";
import {
  GroupCohortData,
  ICohortOverview,
  UserCohortData,
} from "core/components/CohortTable/CohortTable.types";
import {
  getIntervalDate,
  unitTerminology,
} from "core/components/CohortTable/helpers";
import { ColumnHeader } from "core/components/CohortTable/private/ColumnHeader";
import { OverviewRow } from "core/components/CohortTable/private/OverviewRow";

export const Overview = ({
  unit,
  cohortData,
  intervalType,
  hideWeekends,
  setCohortStartDate,
  setCohortIntervalStartDate,
  setRetainedUsersCount,
  previewOrSharingMode,
  onAudienceOpen,
}: ICohortOverview) => {
  const { appId } = useParams<{ appId: string }>() as {
    appId: string;
  };
  const handleCohortClick = ({
    startDate,
    count,
  }: {
    startDate: string;
    count: number;
  }) => {
    if (previewOrSharingMode || count === 0) return;

    setCohortStartDate(startDate);
    setCohortIntervalStartDate(startDate);
    setRetainedUsersCount(count);
    onAudienceOpen();
  };

  return (
    <Flex direction="column" w="260px" pl={2}>
      <Flex>
        <ColumnHeader w="2xs">Date</ColumnHeader>
        <ColumnHeader w="2xs">{unitTerminology[unit].plural}</ColumnHeader>
      </Flex>

      <Flex h="40px" w="140px" align="center" justify="center" />
      {cohortData.map((data, index) => {
        const { startDate, endDate } = data;
        const count =
          (unit === "company"
            ? (data as GroupCohortData).cohortGroupsCount
            : (data as UserCohortData).people) || 0;
        const cohortPreviewIds =
          unit === "company"
            ? (data as GroupCohortData).cohortGroupIds?.slice(0, 4)
            : (data as UserCohortData).peoplePreview?.slice(0, 4);

        return (
          <Flex h="46px" key={index}>
            <Box cursor="default" pl={2}>
              <OverviewRow
                pb={2}
                fontSize="xs"
                fontWeight="semibold"
                text={getIntervalDate({ intervalType, startDate, endDate })}
              />
            </Box>
            {hideWeekends && (data as UserCohortData)?.isWeekend ? (
              <Tooltip
                placement="top"
                label={
                  <Box p={1}>
                    <Text mb={2} fontWeight="semibold">
                      Weekend
                    </Text>
                    <Text>
                      To see users in this cohort, enable "Weekends" at the top
                      of this report.
                    </Text>
                  </Box>
                }
              >
                <Flex cursor="default">
                  <OverviewRow text="🏖" />
                </Flex>
              </Tooltip>
            ) : (
              <Tooltip
                placement="top"
                label={
                  count > 0 &&
                  `Click to see the ${unitTerminology[unit].plural} in this cohort`
                }
              >
                <Flex
                  align="center"
                  justify="space-between"
                  _hover={{
                    cursor: `${previewOrSharingMode || count === 0 ? "default" : "pointer"}`,
                  }}
                  onClick={() => {
                    handleCohortClick({ startDate, count });
                  }}
                >
                  <Flex width="60px">
                    {cohortPreviewIds?.map(
                      (previewId: number | string, i: number) => (
                        <ContactAvatar
                          key={i}
                          className={i !== 0 ? "-ml-2" : ""}
                          size="xs"
                          contact={{
                            id: String(previewId),
                            appId,
                            traits: {},
                            isIdentified: true,
                          }}
                        />
                      ),
                    )}
                  </Flex>
                  <Flex>
                    <OverviewRow
                      text={String(count)}
                      fontWeight="bold"
                      pl={5}
                    />
                  </Flex>
                </Flex>
              </Tooltip>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
