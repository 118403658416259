import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Button, Tooltip } from "@chakra-ui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import React, { Suspense } from "react";

import colors from "core/design-system/constants/theme/colors";
import { AIAssist } from "modules/ViewInsight/SQLEditor/AIAssist";

const CodeEditor = React.lazy(() => import("@uiw/react-textarea-code-editor"));

const CodeEditorLoading = () => (
  <div
    className="animate-pulse rounded-md bg-gray-800"
    style={{ height: "200px" }}
  ></div>
);

export const SetupContainer: React.FC<{
  prompt: string;
  setPrompt: (prompt: string) => void;
  query: string;
  setQuery: (query: string) => void;
  suggestion: string | null;
  isPromptFetching: boolean;
  isExecuteFetching: boolean;
  isPromptLoading: boolean;
  isExecuteLoading: boolean;
  onPrompt: () => void;
  onExecute: () => void;
  onApply: () => void;
  onReject: () => void;
  hasError: boolean;
  showAIAssistant: boolean;
  setShowAIAssistant: (showAIAssistant: boolean) => void;
}> = ({
  prompt,
  setPrompt,
  query,
  setQuery,
  suggestion,
  isPromptFetching,
  isExecuteFetching,
  isPromptLoading,
  isExecuteLoading,
  onPrompt,
  onExecute,
  onApply,
  onReject,
  hasError,
  showAIAssistant,
  setShowAIAssistant,
}) => {
  return (
    <div className="relative flex h-full w-full flex-grow flex-col overflow-y-auto bg-[#2b2b2b]">
      <div className="flex h-full w-full flex-col justify-between bg-[#2b2b2b]">
        <div>
          <div className="sticky top-0 z-10 flex items-center justify-between border-b border-gray-800 bg-[#2b2b2b] py-2 pl-4 pr-2">
            <p className="text-sm text-white">SQL</p>
            <Button
              variant="ghost"
              colorScheme="white"
              color="white"
              _hover={{ bg: "gray.900" }}
              onClick={onExecute}
              isLoading={isExecuteLoading || isExecuteFetching}
            >
              <div className="flex items-center gap-x-1">
                {hasError && (
                  <Tooltip
                    bg="gray.300"
                    placement="left"
                    color="black"
                    label="Error occurred"
                  >
                    <ExclamationTriangleIcon className="h-4 w-4 text-red-400" />
                  </Tooltip>
                )}
                Run
              </div>
            </Button>
          </div>
          <Suspense fallback={<CodeEditorLoading />}>
            <CodeEditor
              value={query}
              language="sql"
              placeholder="SELECT * FROM events"
              onChange={(e) => setQuery(e.target.value)}
              data-color-mode="dark"
              padding={15}
              style={{
                borderRadius: "0px",
                width: "100%",
                fontSize: 12,
                backgroundColor: colors.black,
                fontFamily:
                  "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            />
          </Suspense>
          <div className="sticky bottom-0 left-0 right-0 flex flex-col gap-2 p-4">
            <a
              href="https://help.june.so/en/articles/7229521-how-to-use-sql-to-explore-your-data-in-june"
              target="_blank"
              className="flex w-[100px] items-center gap-x-1 rounded bg-white px-2 py-1 text-gray-800"
              rel="noreferrer"
            >
              <ExternalLinkIcon className="h-4 w-4" />
              <span className="text-sm font-medium">Schema</span>
            </a>
            <AIAssist
              suggestion={suggestion}
              isPromptFetching={isPromptFetching}
              isPromptLoading={isPromptLoading}
              onApply={onApply}
              onReject={onReject}
              onPrompt={onPrompt}
              setPrompt={setPrompt}
              prompt={prompt}
              showAIAssistant={showAIAssistant}
              setShowAIAssistant={setShowAIAssistant}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
