import { AudienceTable } from "core/components/ViewInsight/Graphs/AudienceTable";
import { useAudienceViewInsightDescription } from "core/hooks/useAudienceViewInsightDescription";
import { IViewInsight } from "core/models/viewInsights";
import { IAppObject } from "core/types/AppObject";
import { IAudience } from "core/types/Audience";
import { ViewInsightCard } from "modules/ViewInsight/CardContainer";

export const AudienceViewInsightContainer: React.FC<{
  viewInsight: IViewInsight;
  isLoading: boolean;
  isFetching: boolean;
  isSlackInsight?: boolean;
}> = ({ viewInsight, isLoading, isFetching, isSlackInsight }) => {
  const audienceCount = useAudienceViewInsightDescription({
    audience: viewInsight.audience as IAudience,
    appObject: viewInsight.appObject as IAppObject,
    viewInsightId: viewInsight.id,
  });

  return (
    <ViewInsightCard
      viewInsight={viewInsight}
      isAdding={false}
      isBlurred={false}
      isPaywalled={false}
      isEmpty={false}
      title={`${viewInsight?.audience?.name}`}
      showHighlightDescription={false}
      description={""}
      isLoading={isLoading || isFetching}
      timerange={""}
      hasTimerangePicker={false}
      reachedLimit={false}
      onMouseEnter={() => {}}
      onMouseLeave={() => {}}
      isSlackInsight={isSlackInsight}
      onDelete={() => {}}
      onAdd={() => {}}
      position="relative"
      mr={0}
      bodyProps={{ mb: 0, pb: 0, pr: 0, mr: 0, pl: 0 }}
      highlight={{
        value: audienceCount,
        description: ` ${viewInsight.appObject?.pluralName?.toLowerCase()}`,
      }}
    >
      <AudienceTable viewInsight={viewInsight} />
    </ViewInsightCard>
  );
};
