import {
  ArrowUturnLeftIcon,
  CalculatorIcon,
  DivideIcon,
} from "@heroicons/react/24/outline";

export enum Measure {
  ActiveUsers = "active_users",
  NewUsers = "new_users",
  Features = "features",
  Retention = "retention",
  Funnel = "funnel",
  Power = "power",
  Audience = "audience",
}

export const HumanizedMeasureLabels = {
  [Measure.ActiveUsers]: "Active Users",
  [Measure.NewUsers]: "New Users",
  [Measure.Features]: "Features",
  [Measure.Retention]: "Retention",
  [Measure.Funnel]: "Funnel",
  [Measure.Power]: "Power Users",
  [Measure.Audience]: "Audience",
};

export enum GraphType {
  Bar = "bar",
  Area = "area",
  Line = "line",
  Table = "table",
  Scatter = "scatter",
  StackedLine = "stacked_line",
  StackedBar = "stacked_bar",
  CohortGraph = "cohort_graph",
  ContributionGraph = "contribution_graph",
  Trend = "trend",
  Ratio = "ratio",
}

export const GridWidth = {
  Small: 1,
  Medium: 2,
  Large: 3,
};

export enum Summary {
  Total = "total",
  Average = "average",
}

export enum Size {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export enum Interval {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
}

export enum State {
  Unpersisted = 0,
  Persisted = 1,
}

export enum Aggregation {
  Avg = 0,
  Sum = 1,
  Last = 2,
}

export const AggregationToLabel = {
  [Aggregation.Avg]: "Average",
  [Aggregation.Sum]: "Total",
  [Aggregation.Last]: "Last known",
};

export const AggregationOptions = [
  Aggregation.Avg,
  Aggregation.Sum,
  Aggregation.Last,
];

export const AggregationIcons = {
  [Aggregation.Avg]: DivideIcon,
  [Aggregation.Sum]: CalculatorIcon,
  [Aggregation.Last]: ArrowUturnLeftIcon,
} as const;
