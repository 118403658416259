import { WarningTwoIcon } from "@chakra-ui/icons";
import { StyleProps, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { ContactAvatar } from "core/components/Avatars/ContactAvatar";
import { ClipboardButton } from "core/components/ClipboardButton";
import { getContactEmail } from "core/helpers/contactEmail";
import { getContactName } from "core/helpers/contactName";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useGroup } from "core/hooks/useGroup";
import { IContact } from "core/types/Contact";
import { ITrait } from "core/types/Trait";
import { cx } from "helpers/cx";

export interface IContactProps extends IContact {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string | null;
  traits: ITrait;
  isIdentified?: boolean;
  anonymousId?: string;
}

export interface IContactNameCellProps extends StyleProps {
  props: IContactProps;
  groupId?: string;
  showCompany?: boolean;
  showName?: boolean;
  width?: number;
  showCopyIcon?: boolean;
}

export const ContactNameCell: React.FC<IContactNameCellProps> = ({
  props,
  groupId,
  showCompany = false,
  showName = true,
  width = 300,
  showCopyIcon = true,
}) => {
  const { appId } = useParams();
  const { userAppObject } = useAppObjects();
  const { id, isIdentified, traits, anonymousId } = props;
  const [labelWidth, setLabelWidth] = useState(width);
  const [showCopy, setShowCopy] = useState(false);

  const profileLink = `/a/${appId}/objects/${userAppObject?.id}/object/${encodeURIComponent(id)}/`;

  const isAnonymous = id === "";
  const name = getContactName(props);
  const email = getContactEmail(props);

  const hasEmail = Boolean(email && email !== "null" && email !== "Unknown");
  const hasName = Boolean(name && name !== "Unknown");

  const { fetchGroup, group } = useGroup({
    id: groupId || "",
  });

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  if (isAnonymous) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="flex items-center">
              <div className="pr-4">
                <ContactAvatar size="xs" contact={{ id, traits: {} }} />
              </div>
              <div className="flex flex-col items-start hover:cursor-default">
                {anonymousId && showName ? (
                  <>
                    <Text fontSize="sm" noOfLines={1}>
                      Anonymous
                    </Text>
                    <Text fontSize="sm" color="gray.600" noOfLines={1}>
                      {anonymousId}
                    </Text>
                  </>
                ) : (
                  <Text fontSize="sm" noOfLines={1}>
                    Anonymous
                  </Text>
                )}
              </div>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            {anonymousId
              ? `This is an anonymous user with no userId and just an anonymousId. Their anonymous id is ${anonymousId}`
              : "This is an anonymous user with no userId"}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Link to={profileLink}>
            <div className="relative flex items-center">
              <div className="mr-3">
                <ContactAvatar
                  size="sm"
                  contact={{ id, traits, isIdentified }}
                />
              </div>
              {!Boolean(isIdentified) && (
                <div className="absolute left-20 top-[-5px] mr-1 flex flex-wrap items-center gap-2">
                  <div className="flex items-center">
                    <WarningTwoIcon color="yellow.400" mr={1} mb={0.5} />
                  </div>
                </div>
              )}
              <div
                className="flex w-full flex-col"
                onMouseEnter={() => {
                  if (!showCopyIcon) return;
                  setShowCopy(true);
                  setLabelWidth(width - 10);
                }}
                onMouseLeave={() => {
                  if (!showCopyIcon) return;
                  setShowCopy(false);
                  setLabelWidth(width);
                }}
              >
                <div className="flex w-full items-center justify-between">
                  <motion.div
                    initial={{ width: labelWidth }}
                    animate={{ width: labelWidth }}
                    className={cx(
                      "flex w-full items-center justify-between",
                      labelWidth ? `max-w-[${labelWidth}px]` : "max-w-full",
                    )}
                  >
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <p className="truncate text-sm">
                            {hasEmail ? email : id}
                          </p>
                        </TooltipTrigger>
                        <TooltipContent>{hasEmail ? email : id}</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </motion.div>
                  {showCopy && (
                    <motion.div animate={{ x: -5 }}>
                      <ClipboardButton
                        value={email ?? id}
                        showLabel={false}
                        copyText="Copy email"
                        variant="ghost"
                        bg="transparent"
                        color="black"
                        _hover={{ bg: "transparent" }}
                      />
                    </motion.div>
                  )}
                </div>
                {hasName && showName && (
                  <Text fontSize="xs" color="gray.600" noOfLines={1}>
                    {name}
                  </Text>
                )}
                {showCompany && (
                  <Text
                    fontSize="xs"
                    color={Boolean(group) ? "gray.600" : "warning.500"}
                    noOfLines={1}
                  >
                    {Boolean(group)
                      ? group?.name || group?.id
                      : "Missing company"}
                  </Text>
                )}
              </div>
            </div>
          </Link>
        </TooltipTrigger>
        <TooltipContent>
          {!Boolean(isIdentified) && (
            <div className="flex flex-col gap-1">
              <div>
                <p className="font-medium text-gray-600">Missing user</p>
                <Text mb={2}>
                  We have received a track event which was performed by this
                  user, but the user is missing an identify call. Make sure the
                  user with id{" "}
                  <Text
                    fontWeight="semibold"
                    fontStyle="italic"
                    borderRadius={"md"}
                    p={1}
                    as="span"
                    color="gray.400"
                  >
                    {id}
                  </Text>{" "}
                  is sending an identify call.
                </Text>
              </div>
            </div>
          )}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
