import moment from "moment";

import { Button } from "Components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "Components/ui/select";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
} from "Components/ui/sheet";
import { SlackViewInsightCTA } from "core/components/InsightCard/SlackViewInsightCTA";
import { SlackChannels } from "core/components/Slack/SlackChannels";
import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { useEditSlackInsightAlert } from "core/hooks/useEditSlackInsightAlert";
import { useSlack } from "core/hooks/useSlack";
import { ViewLocation } from "core/hooks/useViews";
import { IViewInsight } from "core/models/viewInsights";
import { IAlert } from "core/types/Alert";
import { cx } from "helpers/cx";
import { Frequency } from "modules/SlackInsightEditor/Frequency";
import { AIViewInsightContainer } from "modules/ViewInsight/AIViewInsightContainer";
import { AudienceViewInsightContainer } from "modules/ViewInsight/AudienceViewInsightContainer";
import { GRID_TO_PX } from "modules/ViewInsight/Builder/LayoutContainer";
import { ViewInsightContainer } from "modules/ViewInsight/ViewInsightContainer";

interface ISlackViewInsightEditorProps {
  viewInsight: IViewInsight;
  onClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
  alert?: IAlert;
}

export const SlackViewInsightEditor: React.FC<ISlackViewInsightEditorProps> = ({
  isOpen,
  onClose,
  alert,
  isLoading: isLoadingProp,
  viewInsight,
}) => {
  const { hasSlackAppInstalled } = useSlack();
  const {
    form,
    alertState,
    hasChanges,
    isLoading,
    onCancel,
    onConnectSlack,
    onSave,
    onUpdate,
    onPause,
  } = useEditSlackInsightAlert({
    reportId: String(viewInsight.reportId),
    insight: {
      id: viewInsight.id,
      typeId: viewInsight.insightId || 0,
      slug: viewInsight.insight.slug,
      title: viewInsight.insight.name,
      section: () => null, // Not needed for alerts
    },
    existingAlert: alert,
  });

  const hours = Array.from({ length: 24 }, (_, i) => i);

  return (
    <Sheet
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <SheetContent
        side="right"
        className="w-[800px] max-w-[800px] sm:max-w-[800px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <SheetHeader>
          <SheetTitle className="text-left text-lg">
            Setup Slack alert for {viewInsight.title}
          </SheetTitle>
        </SheetHeader>
        <div className="flex-1 overflow-y-auto">
          <div className="flex w-full flex-col gap-5 pt-8">
            <div className="flex w-full flex-col">
              <p className="mb-0 font-semibold">Preview</p>
              <p className="mb-2 text-sm text-gray-600">
                This is a preview of the graph you will receive in your chosen
                Slack channel
              </p>
              <div className="w-full rounded-lg bg-purple-100 p-3">
                <div className="w-full rounded-lg bg-white">
                  <div className="mb-2 flex flex-col px-6">
                    <div className="mt-1 flex flex-row items-center justify-start">
                      <div className="mr-2 mt-3 flex items-center justify-center rounded-lg border border-gray-200 p-1">
                        <CompanyJuneLogoIcon fontSize={26} />
                      </div>
                      <p className="font-semibold">June</p>
                      <p className="ml-2 rounded-md bg-gray-100 px-1 text-xs">
                        App
                      </p>
                      <p className="ml-2 text-xs text-gray-600">
                        {moment(Date.now()).format("HH:MM")}
                      </p>
                    </div>
                    <div className="ml-10 pl-1">
                      <p className="mt-[-10px] text-sm">
                        Here's your{" "}
                        <span className="text-transform-lowercase font-semibold">
                          {viewInsight.audience
                            ? viewInsight.audience.name
                            : viewInsight.title}
                        </span>{" "}
                        insight from your{" "}
                        <span className="text-transform-lowercase font-semibold">
                          {viewInsight.view.name}
                        </span>{" "}
                        dashboard
                      </p>
                    </div>
                  </div>
                  <div className="flex h-full w-full justify-center bg-gray-50 px-5 py-5">
                    <div
                      className={cx(
                        "max-w-full",
                        GRID_TO_PX[viewInsight.gridHeight]
                          ? `min-h-[${GRID_TO_PX[viewInsight.gridHeight]}] h-[${GRID_TO_PX[viewInsight.gridHeight]}]`
                          : "min-h-[315px]",
                        GRID_TO_PX[viewInsight.gridWidth]
                          ? `min-w-[${GRID_TO_PX[viewInsight.gridWidth]}] w-[${GRID_TO_PX[viewInsight.gridWidth]}]`
                          : "min-w-[315px]",
                      )}
                    >
                      {viewInsight.audience ? (
                        <AudienceViewInsightContainer
                          viewInsight={viewInsight}
                          isLoading={false}
                          isFetching={false}
                          isSlackInsight
                        />
                      ) : viewInsight.queryId ? (
                        <AIViewInsightContainer
                          location={ViewLocation.Home}
                          viewInsight={viewInsight}
                          isAdding={false}
                          isAdded={false}
                          viewId={viewInsight.viewId}
                          isBlurred={false}
                          isAI
                          isSlackInsight
                          execute={true}
                          skip={true}
                          view={viewInsight.view}
                        />
                      ) : (
                        <ViewInsightContainer
                          location={ViewLocation.Home}
                          viewInsight={viewInsight}
                          isAdding={false}
                          isAdded={false}
                          viewId={viewInsight.viewId}
                          isBlurred={false}
                          onSetViewInsight={() => {}}
                          view={viewInsight.view}
                          isSlackInsight
                          disableInteraction={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="mb-0 font-semibold">Slack channel</p>
              <p className="mb-2 text-sm text-gray-600">
                The channel you wish to receive the graph in
              </p>
              {!hasSlackAppInstalled ? (
                <div>
                  <Button
                    onClick={onConnectSlack}
                    variant="ghost"
                    className="text-purple-600 hover:bg-purple-50 hover:text-purple-700"
                  >
                    Connect Slack to select a channel
                  </Button>
                </div>
              ) : (
                form.Field({
                  name: "slackChannelId",
                  children: (field) => (
                    <SlackChannels
                      showLabel={false}
                      defaultValue={field.state.value ?? ""}
                      onChange={(ch) => field.handleChange(ch)}
                    />
                  ),
                })
              )}
            </div>
            {form.Field({
              name: "frequency",
              children: (field) => (
                <Frequency
                  alertChanges={{
                    ...form.state.values,
                    id: alert?.id ?? 0,
                    created: !!alert,
                    appId: Number(viewInsight.appId),
                    alertableId: Number(viewInsight.reportId),
                    setup: alert?.setup ?? {},
                    alertableType: "Report",
                    enrichmentEnabled: false,
                    threshold: 0,
                    thresholdType: 0,
                  }}
                  setAlertChanges={(alert) =>
                    field.handleChange(alert.frequency)
                  }
                />
              ),
            })}
            <div className="flex flex-col">
              <p className="mb-0 font-semibold">Preferred time (UTC)</p>
              <p className="mb-2 text-sm text-gray-600">
                The time of day when you want to receive the notification
              </p>
              {form.Field({
                name: "preferredUtcHour",
                children: (field) => (
                  <Select
                    value={String(field.state.value)}
                    onValueChange={(value) => field.handleChange(Number(value))}
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Select hour" />
                    </SelectTrigger>
                    <SelectContent>
                      {hours.map((hour) => (
                        <SelectItem key={hour} value={String(hour)}>
                          {`${String(hour).padStart(2, "0")}:00 UTC`}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                ),
              })}
            </div>
          </div>
        </div>
        <SheetFooter>
          <Button
            variant="ghost"
            className="text-purple-600 hover:bg-purple-50 hover:text-purple-700"
            disabled={!hasChanges}
            onClick={() => {
              onCancel(onClose);
              onClose();
            }}
          >
            Cancel
          </Button>
          <SlackViewInsightCTA
            isLoading={isLoading}
            isLoadingProp={isLoadingProp}
            form={form}
            alertState={alertState}
            hasSlackAppInstalled={hasSlackAppInstalled}
            onConnectSlack={onConnectSlack}
            onSave={onSave}
            onUpdate={onUpdate}
            onPause={onPause}
          />
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};
