import { MoveDiagonal } from "lucide-react";
import React from "react";

import { cn } from "@/lib/utils";

interface ResizeHandleProps {
  onResizeStart: (e: React.MouseEvent) => void;
  isDragging?: boolean;
  direction?: "horizontal" | "vertical";
}

export const ResizeHandle: React.FC<ResizeHandleProps> = ({
  onResizeStart,
  isDragging = false,
  direction = "horizontal",
}) => {
  const handleMouseDown = (e: React.MouseEvent) => {
    onResizeStart(e);
  };

  return (
    <div
      className={cn(
        "absolute z-50 flex h-4 w-6 items-center justify-center rounded-sm border border-gray-200 bg-white p-0 opacity-0 shadow-sm transition-opacity duration-200 hover:bg-gray-50 group-hover:opacity-100",
        direction === "horizontal"
          ? "-bottom-3 -right-1.5 cursor-ew-resize"
          : "-bottom-1.5 right-[48%] cursor-ns-resize",
        isDragging &&
          (direction === "horizontal"
            ? "bottom-1 right-1 cursor-grabbing opacity-100"
            : "bottom-1 right-[48%] cursor-grabbing opacity-100"),
      )}
      onMouseDown={handleMouseDown}
    >
      <MoveDiagonal
        className={cn(
          "h-3 w-3 text-gray-600",
          direction === "horizontal" ? "rotate-45" : "rotate-[135deg]",
        )}
      />
    </div>
  );
};
