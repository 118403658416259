import { IAppObject } from "core/types/AppObject";

export const ObjectTraitText: React.FC<{ object: IAppObject }> = ({
  object,
}) => {
  return (
    <div className="flex-1 pl-1.5 text-sm text-gray-900">
      {object.pluralName + " where"}
    </div>
  );
};
