import { isNumber } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { getAudienceGroupId } from "@/core/helpers/audienceHelper";
import { FILTER_TYPE_TO_SLUG } from "core/constants/filters";
import {
  BREAKDOWN_TYPE,
  ENUM_TYPE,
  MULTI_EVENT_TYPE,
  OCCURRENCES_TYPE,
  SETTINGS_TYPE,
  SINGLE_EVENT_TYPE,
} from "core/constants/report-setup";
import { getReportConfig } from "core/helpers/getReportConfig";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useReportSetup } from "core/hooks/useReportSetup";
import { IGetInsightArgs, useGetInsightQuery } from "core/models/insights";
import { IViewInsight } from "core/models/viewInsights";
import { IView } from "core/models/views";
import { GROUP_TYPE_TO_SLUG } from "core/types/AppObject";
import { IAudience } from "core/types/Audience";
import { IReport, IReportConfig } from "core/types/Report";
import { INTERVAL_TYPES } from "modules/ViewInsight/Builder/Preview/Container";

interface IDataFetchingProps {
  report: IReport;
  view?: IView;
  viewInsight?: IViewInsight;
  insight?: any;
  insightParams?: any;
  insightType?: number;
  previewMode?: boolean;
  sharingSecretToken?: string | null;
  skip?: boolean;
  groupId?: string;
  groupType?: string;
  timerangeType?: number;
  timerangeValue?: number;
  timerangeStartTimestamp?: string;
  timerangeEndTimestamp?: string;
  audience?: IAudience;
}

function getGroupTypeRoute() {
  if (window.location.href.includes("/company/")) return "company";
  if (window.location.href.includes("/group/")) return "group";
}

// FIX: we need this function because we maintain two different states
// for the report config on the frontend and the backend
export function serializeConfig({
  report,
  config,
}: {
  report: IReport;
  config: IReportConfig;
}) {
  let newConfig: IReportConfig = {};

  const templateConfig = getReportConfig(report.reportType);

  templateConfig?.setup?.setupSections?.forEach(({ type, configKey }) => {
    if (type === SINGLE_EVENT_TYPE) {
      if (Array.isArray(config[configKey])) {
        newConfig[configKey] = config[configKey][0];
      } else {
        newConfig[configKey] = config[configKey];
      }
    } else if (type === MULTI_EVENT_TYPE) {
      newConfig[configKey] = config[configKey];
      if (config.name) {
        newConfig = {
          ...config,
          name: {
            [configKey]: config.name[configKey],
            ...config.name,
          },
        };
      }
    } else if (type === ENUM_TYPE) {
      if (Array.isArray(config[configKey])) {
        newConfig[configKey] = config[configKey][0]?.value;
      } else {
        newConfig[configKey] = config[configKey];
      }
    } else if (
      type === SETTINGS_TYPE ||
      type === OCCURRENCES_TYPE ||
      type === BREAKDOWN_TYPE
    ) {
      newConfig[configKey] = config[configKey];
    }
  });

  return newConfig;
}

export const useInsightDataFetching = ({
  report,
  view,
  viewInsight,
  insight = {},
  insightType = undefined,
  insightParams = {},
  previewMode = false,
  sharingSecretToken = null,
  skip = false,
  groupId,
  groupType,
  timerangeType,
  timerangeValue,
  timerangeStartTimestamp,
  timerangeEndTimestamp,
  audience,
}: IDataFetchingProps): {
  response: any;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  error: any;
  refetch: () => void;
  onRefreshInsight: () => void;
} => {
  const {
    appId,
    viewId,
    viewInsightId,
    groupId: encodedGroupId,
    appObjectId,
    objectId,
  } = useParams<{
    appId: string;
    reportId: string;
    viewId: string;
    viewInsightId: string;
    groupId: string;
    appObjectId: string;
    objectId: string;
  }>() as {
    appId: string;
    reportId: string;
    viewId: string;
    groupId: string;
    viewInsightId: string;
    appObjectId: string;
    objectId: string;
  };

  const { fullModeInsight } = useEditDashboard();

  const levelQueryParam = useQueryParam("level");

  const { groupId: groupIdFromAudience, filterType } =
    getAudienceGroupId(audience);
  const groupIdQueryParam = useQueryParam("groupId");

  const getGroupId = useCallback(() => {
    if (groupId) return groupId;
    if (groupIdFromAudience) return groupIdFromAudience;
    if (groupIdQueryParam) return groupIdQueryParam;

    return decodeURIComponent(encodedGroupId || objectId);
  }, [
    groupId,
    groupIdFromAudience,
    groupIdQueryParam,
    encodedGroupId,
    objectId,
  ]);

  const groupTypeRoute = getGroupTypeRoute();
  const groupTypeQueryParam = useQueryParam("groupType");
  const { appObjects } = useAppObjects();
  const currentObject = appObjects.find((o) => o.id === Number(appObjectId));

  const timerangeTypeQueryParam = useQueryParam("timerangeType");
  const timerangeValueQueryParam = useQueryParam("timerangeValue");
  const timerangeStartTimestampQueryParam = useQueryParam(
    "timerangeStartTimestamp",
  );
  const timerangeEndTimestampQueryParam = useQueryParam(
    "timerangeEndTimestamp",
  );

  const getGroupType = useCallback(() => {
    if (groupType) return groupType;
    if (filterType) return FILTER_TYPE_TO_SLUG[filterType];
    if (currentObject) return GROUP_TYPE_TO_SLUG[currentObject.objectType];
    if (groupTypeQueryParam) return groupTypeQueryParam;
    if (groupTypeRoute) return groupTypeRoute;
    if (fullModeInsight && fullModeInsight?.viewInsight?.appObject)
      return GROUP_TYPE_TO_SLUG[
        fullModeInsight?.viewInsight?.appObject?.objectType
      ];
    if (viewInsight && viewInsight?.appObject)
      return GROUP_TYPE_TO_SLUG[viewInsight?.appObject?.objectType];

    return levelQueryParam;
  }, [
    groupType,
    filterType,
    currentObject,
    groupTypeQueryParam,
    groupTypeRoute,
    fullModeInsight,
    viewInsight,
    levelQueryParam,
  ]);

  const groupTypeParam = getGroupType();

  const { serializeKey } = useReportSetup();
  const [refreshCache, setRefreshCache] = useState<boolean>(false);
  const skipQuery = previewMode || !appId || appId === "0" || skip;

  const params = useMemo(() => {
    if (Boolean(fullModeInsight?.viewInsight?.interval)) {
      return {
        ...insightParams,
        serializeKey,
        interval_type:
          INTERVAL_TYPES[
            fullModeInsight?.viewInsight
              ?.interval as keyof typeof INTERVAL_TYPES
          ],
      };
    }
    return { ...insightParams, serializeKey };
  }, [insightParams, serializeKey, fullModeInsight]);

  const insightQueryParams = useMemo(
    () => ({
      appId: Number(appId),
      reportId: Number(report?.id),
      viewId: view?.id || viewId,
      viewInsightId: viewInsight?.id || viewInsightId,
      groupId: getGroupId(),
      sharingSecretToken,
      insightType: isNumber(insight.typeId) ? insight.typeId : insightType,
      insightParams: params,
      groupType: groupTypeParam,
      timerangeType: Boolean(fullModeInsight?.viewInsight)
        ? fullModeInsight?.viewInsight?.timerangeType
        : timerangeType !== undefined
          ? timerangeType
          : timerangeTypeQueryParam,
      timerangeValue:
        timerangeValue !== undefined
          ? timerangeValue
          : Boolean(fullModeInsight?.viewInsight)
            ? fullModeInsight?.viewInsight?.timerangeValue
            : timerangeValue !== undefined
              ? timerangeValue
              : timerangeValueQueryParam,
      timerangeStartTimestamp: Boolean(fullModeInsight?.viewInsight)
        ? fullModeInsight?.viewInsight?.timerangeStartTimestamp
        : timerangeStartTimestamp
          ? timerangeStartTimestamp
          : timerangeStartTimestampQueryParam,
      timerangeEndTimestamp: Boolean(fullModeInsight?.viewInsight)
        ? fullModeInsight?.viewInsight?.timerangeEndTimestamp
        : timerangeEndTimestamp
          ? timerangeEndTimestamp
          : timerangeEndTimestampQueryParam,
      config: serializeConfig({ report, config: report?.config }) || {},
      audience: audience || report?.audience || {},
      refreshCache,
    }),
    [
      appId,
      report,
      viewId,
      view?.id,
      viewInsightId,
      viewInsight?.id,
      getGroupId,
      sharingSecretToken,
      insight.typeId,
      insightType,
      params,
      refreshCache,
      timerangeType,
      timerangeValue,
      timerangeStartTimestamp,
      timerangeEndTimestamp,
      timerangeTypeQueryParam,
      timerangeValueQueryParam,
      timerangeStartTimestampQueryParam,
      timerangeEndTimestampQueryParam,
      audience,
      fullModeInsight,
      groupTypeParam,
    ],
  );

  const {
    data: response,
    isFetching,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetInsightQuery(insightQueryParams as IGetInsightArgs, {
    skip: skipQuery,
  });

  const onRefreshInsight = () => {
    setRefreshCache(true);
  };

  return {
    response,
    isFetching,
    isLoading,
    isError,
    error,
    refetch,
    onRefreshInsight,
  };
};
