import { GRID_TO_PX } from "modules/ViewInsight/Builder/LayoutContainer";

export interface GridOverlayProps {
  targetWidth?: number;
  targetHeight?: number;
  direction?: "horizontal" | "vertical";
}

export const GridOverlay = ({
  targetWidth,
  targetHeight,
  direction = "horizontal",
}: GridOverlayProps) => {
  return (
    <div className="pointer-events-none absolute inset-0 z-40">
      <div className="relative h-full w-full">
        <div
          className="absolute inset-0 rounded-lg border border-purple-500 bg-purple-100 bg-opacity-20 shadow-lg transition-all duration-75"
          style={
            direction === "horizontal"
              ? { width: targetWidth ? GRID_TO_PX[targetWidth] : "100%" }
              : { height: targetHeight ? GRID_TO_PX[targetHeight] : "100%" }
          }
        />
      </div>
    </div>
  );
};
