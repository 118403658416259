import { useState } from "react";

import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/Components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/Components/ui/popover";
import { COMPARISON_TO_NAME } from "@/core/constants/traitFilterComponents";
import { ComparisonType } from "core/types/Filters.d";

const ObjectComparisonTypes = [
  {
    value: ComparisonType.IS,
    label: "is",
  },
  {
    value: ComparisonType.CONTAINS,
    label: "contains",
  },
];

export const ComparisonSelector = ({
  comparisonType,
  setComparisonType,
}: {
  comparisonType: ComparisonType;
  setComparisonType: (comparisonType: ComparisonType) => void;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="flex w-full cursor-pointer items-center justify-between gap-1 rounded-md px-1.5 py-0.5 text-sm font-medium hover:bg-gray-200">
          {COMPARISON_TO_NAME[comparisonType]}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[150px] p-0">
        <Command>
          <CommandList>
            <CommandGroup>
              {ObjectComparisonTypes.map((type) => (
                <CommandItem
                  key={type.value}
                  value={type.value.toString()}
                  onSelect={() => {
                    setComparisonType(type.value);
                    setOpen(false);
                  }}
                  className="cursor-pointer rounded-md px-2 font-medium text-gray-900 hover:bg-gray-100"
                >
                  {type.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
