import { IAudience, IAudienceFilters } from "core/types/Audience.d";
import { IFilter } from "core/types/Filters";

export const getAudienceGroupId = (
  audience: IAudience | IAudienceFilters | undefined,
): { groupId: string | undefined; filterType: number | undefined } => {
  if (!audience) {
    return { groupId: undefined, filterType: undefined };
  }

  if (!audience.filterGroups || audience.filterGroups.length === 0) {
    return { groupId: undefined, filterType: undefined };
  }

  for (const filterGroup of audience.filterGroups) {
    for (const filter of filterGroup.filters) {
      if (
        filter.body &&
        filter.body.trait === "group_id" &&
        typeof filter.body.value === "string"
      ) {
        return { groupId: filter.body.value, filterType: filter.type };
      }
    }
  }

  return { groupId: undefined, filterType: undefined };
};

export const audienceHasGroupId = (audience: IAudience | IAudienceFilters) => {
  const { groupId } = getAudienceGroupId(audience);

  return groupId !== undefined;
};

export const isGroupIdFilter = (filter: IFilter) => {
  return filter.body && filter.body.trait === "group_id";
};
