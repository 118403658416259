import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { usePreviewQuery } from "@/core/models/sql";
import { JuneLoadingPage } from "@/modules/Dashboard/LoadingPage";
import { InsightEditorContainer } from "@/modules/Dashboard/SQLBuilder/EditorContainer";
import { useNavigation } from "core/hooks/useNavigation";

export const AddSQLRoute: React.FC = () => {
  const { navigation, toggleSidebar } = useNavigation();
  const { appId, viewId } = useParams();

  const { data: viewInsight, isLoading } = usePreviewQuery({
    appId: Number(appId),
    viewId: Number(viewId),
  });

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !viewInsight) {
    return <JuneLoadingPage />;
  }

  return (
    <div className="h-screen w-full overflow-hidden">
      <InsightEditorContainer viewInsight={viewInsight} />
    </div>
  );
};
