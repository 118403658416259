import { ContactBody } from "core/components/Contact/ContactBody";

interface IContactPreviewProps {
  id: string;
  description?: string;
  p: number;
  truncateName: boolean;
  hideHoverBorder: boolean;
}

export const ContactStaticPreview: React.FC<IContactPreviewProps> = ({
  id,
  description,
  p,
  truncateName,
  hideHoverBorder,
}) => {
  const padding = `p-${p}`;

  return (
    <div
      className={`${padding} flex items-center justify-between rounded-lg border border-white hover:cursor-pointer ${!hideHoverBorder ? "hover:border-gray-200" : ""}`}
      data-testid="selector-clickable-contact"
    >
      <ContactBody
        userId={id}
        description={description}
        truncateName={truncateName}
        previewMode
      />
    </div>
  );
};
