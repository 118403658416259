import { BuildingOfficeIcon, UsersIcon } from "@heroicons/react/24/outline";

import { AppObjectType } from "@/core/types/AppObject";
import { FilterType } from "core/constants/report-setup";
import { CommandOption } from "core/design-system/components/Command";
import { IFilterOption } from "helpers/searchFilters";

export const ObjectFilterOption = ({
  filterOption,
  onAddObjectFilter,
  isObjectFilterDisabled,
}: {
  filterOption: IFilterOption;
  onAddObjectFilter: (
    filterType: FilterType,
    objectType: AppObjectType,
  ) => void;
  isObjectFilterDisabled?: boolean;
}) => {
  return (
    <CommandOption
      testId="audience-object-option"
      value={() => {
        onAddObjectFilter(
          FilterType.ObjectFilter,
          filterOption.objectType as AppObjectType,
        );
      }}
      disabled={isObjectFilterDisabled}
    >
      <div className="flex items-center gap-2">
        {filterOption.objectType === AppObjectType.Group && (
          <UsersIcon className="h-4 w-4 text-gray-700" />
        )}
        {filterOption.objectType === AppObjectType.Company && (
          <BuildingOfficeIcon className="h-4 w-4 text-gray-700" />
        )}
        <p className="text-sm font-medium">{filterOption.trait}</p>
      </div>
    </CommandOption>
  );
};

export default ObjectFilterOption;
