import { format } from "date-fns";

import { SimpleStackedBarChart } from "@/core/components/ViewInsight/Graphs/SimpleStackedBarChart";
import { SimpleStackedLineChart } from "@/core/components/ViewInsight/Graphs/SimpleStackedLineChart";
import { withInView } from "core/components/InsightCard/withInView";
import { ContributionGraph } from "core/components/ViewInsight/Graphs/ContributionGraph";
import { List } from "core/components/ViewInsight/Graphs/List";
import { SimpleAreaChart } from "core/components/ViewInsight/Graphs/SimpleAreaChart";
import { SimpleBarChart } from "core/components/ViewInsight/Graphs/SimpleBarChart";
import { ITrendData, Trend } from "core/components/ViewInsight/Graphs/Trend";
import defaultTemplateConfig from "core/constants/report-configurations/report-types/defaultTemplateConfig";
import {
  ANY,
  AUDIENCE_TYPE,
  MULTI_EVENT_TYPE,
  OR,
  PAGE,
  SETTINGS_TYPE,
  TRACK,
} from "core/constants/report-setup";
import { Template } from "core/constants/templates";
import {
  DAU_GROUPED_OPTIONS,
  DAU_OPTIONS,
  MAU_GROUPED_OPTIONS,
  MOST_ACTIVE_USERS_GROUPED_OPTIONS,
  MOST_ACTIVE_USERS_OPTIONS,
  WAU_GROUPED_OPTIONS,
} from "core/constants/timerange";
import { DagContainer } from "core/modules/reports/report-types/ActiveGroups/DagContainer";
import { HeaderInsight as GroupsHeaderInsight } from "core/modules/reports/report-types/ActiveGroups/HeaderInsight";
import { MagContainer } from "core/modules/reports/report-types/ActiveGroups/MagContainer";
import {
  IMostActiveGroup,
  MostActiveGroupsContainer,
} from "core/modules/reports/report-types/ActiveGroups/MostActiveGroupsContainer";
import { WagContainer } from "core/modules/reports/report-types/ActiveGroups/WagContainer";
import { ContributionGraphInsight } from "core/modules/reports/report-types/ActiveUsers/ContributionGraphInsight";
import { DauContainer } from "core/modules/reports/report-types/ActiveUsers/DauContainer";
import { DauMauContainer } from "core/modules/reports/report-types/ActiveUsers/DauMauContainer";
import { DauWauContainer } from "core/modules/reports/report-types/ActiveUsers/DauWauContainer";
import { HeaderInsight } from "core/modules/reports/report-types/ActiveUsers/HeaderInsight";
import { MauContainer } from "core/modules/reports/report-types/ActiveUsers/MauContainer";
import {
  IMostActiveUser,
  MostActiveUsersContainer,
} from "core/modules/reports/report-types/ActiveUsers/MostActiveUsersContainer";
import { WauContainer } from "core/modules/reports/report-types/ActiveUsers/WauContainer";
import WauMauContainer from "core/modules/reports/report-types/ActiveUsers/WauMauContainer";
import { ITemplateConfig } from "core/types/TemplateConfig";
import { ITrait } from "core/types/Trait";

type Interval = "daily" | "weekly" | "monthly";
function transformData(
  data: any,
  dataKey: string,
  yKey: string,
  interval?: Interval,
  showBreakdown?: boolean,
) {
  if (showBreakdown) {
    return [
      {
        name: "Returning users",
        unit: "users",
        data: data?.[dataKey]?.map((d: any) => ({
          date: format(new Date(d.date), "d MMM"),
          count: d.identifiedUsersCount,
        })),
      },
      {
        name: "New users",
        unit: "users",
        data: data?.[dataKey]?.map((d: any) => ({
          date: format(new Date(d.date), "d MMM"),
          count: d.identifiedNewUsersCount,
        })),
      },
    ];
  }

  return (
    data?.[dataKey]?.map((d: any) => ({
      x:
        interval === "monthly"
          ? format(new Date(d.date), "MMM")
          : format(new Date(d.date), "d MMM"),
      y: d[yKey],
      date: d.date,
    })) || []
  );
}

const LazyWauContainer = withInView(WauContainer);
const LazyMauContainer = withInView(MauContainer);
const LazyDauWauContainer = withInView(DauWauContainer);
const LazyDauMauContainer = withInView(DauMauContainer);
const LazyWauMauContainer = withInView(WauMauContainer);

const LazyWagContainer = withInView(WagContainer);
const LazyMagContainer = withInView(MagContainer);

export const ACTIVE_USERS_CONFIG: ITemplateConfig = {
  ...defaultTemplateConfig,
  route: "active-users",
  reportType: 4,
  subtitle: "An overview of your active users and their stickiness",
  aboutTemplate:
    "Get an accurate picture of your daily, weekly and monthly active users (DAU, WAU, MAU). Find out how they stick with some stickiness ratios.",
  howItWorksText: "Learn how it works",
  howItWorksLink:
    "https://help.june.so/en/articles/4752500-how-active-users-are-calculated",
  howToSetupText: "Learn how to set up active users",
  howToSetupLink:
    "https://help.june.so/en/articles/4752874-how-to-set-up-your-active-users-template",
  title: "Active users",
  template: Template.ActiveUsers,
  sections: {
    user: [
      HeaderInsight,
      DauContainer,
      MostActiveUsersContainer,
      // Below the fold
      LazyWauContainer,
      LazyMauContainer,
      LazyDauWauContainer,
      LazyDauMauContainer,
      LazyWauMauContainer,
    ],
    company: [
      GroupsHeaderInsight,
      DagContainer,
      MostActiveGroupsContainer,
      // Below the fold
      LazyWagContainer,
      LazyMagContainer,
    ],
  },
  insights: [
    {
      typeId: 7,
      slug: "latest-active-users",
      title: "Latest active {APP_OBJECT_PLURAL_NAME}",
      section: HeaderInsight,
      shareable: true,
      slackAlertAvailable: true,
      view: {
        level: "user",
        Component: Trend,
        transform: (data, interval) => {
          if (!data || !Array.isArray(data) || data?.length === 0 || !interval)
            return {};

          const trendData = data?.find((d: any) => d?.interval === interval);
          if (!trendData) return {};

          return {
            count: trendData.count,
            change: trendData.insightChange,
            interval,
            ...trendData,
          } as ITrendData;
        },
        hasTimerangePicker: false,
      },
    },
    {
      typeId: 0,
      slug: "dau",
      title: "Daily active {APP_OBJECT_PLURAL_NAME}",
      description: {
        title: "Learn more about Daily Active Users",
        content:
          "Daily Active Users are the unique users that triggered the track and / or page events on a given day / hour.",
      },
      section: DauContainer,
      slackAlertAvailable: true,
      hasInProgress: true,
      timerangeOptions: DAU_OPTIONS,
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        Components: {
          stacked_line: SimpleStackedLineChart,
          stacked_bar: SimpleStackedBarChart,
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data, interval, showBreakdown) => {
          return transformData(
            data,
            "data",
            "activeUsers",
            interval,
            showBreakdown,
          );
        },
        hasTimerangePicker: true,
        dateRangeOptions: DAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 8,
      slug: "most-active-users",
      title: "Most active users",
      section: MostActiveUsersContainer,
      timerangeOptions: MOST_ACTIVE_USERS_OPTIONS,
      shareable: true,
      copyable: false,
      downloadable: false,
      slackAlertAvailable: true,
      view: {
        level: "user",
        Component: List,
        transform: (data) => {
          return data?.contacts?.slice(0, 5)?.map((entity: IMostActiveUser) => {
            const traits = entity?.traits as ITrait;
            return {
              id: entity?.userId,
              displayName: traits?.["email"],
              avatar: traits?.["avatar"],
              description: `Used over ${entity?.numberOfActiveDays} days`,
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: MOST_ACTIVE_USERS_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 15,
      slug: "contribution-graph",
      title: "Contribution graph",
      section: ContributionGraphInsight,
      shareable: false,
      copyable: false,
      downloadable: false,
      slackAlertAvailable: false,
      view: {
        level: "user",
        Component: ContributionGraph,
        transform: (data) => {
          return data;
        },
        hasIntervalPicker: false,
      },
    },
    {
      typeId: 1,
      slug: "wau",
      title: "Weekly active {APP_OBJECT_PLURAL_NAME}",
      description: {
        title: "Learn more about Weekly Active Users",
        content:
          "Weekly Active Users are the unique users that triggered the track and / or page events on a week.",
      },
      section: WauContainer,
      slackAlertAvailable: true,
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        Components: {
          stacked_line: SimpleStackedLineChart,
          stacked_bar: SimpleStackedBarChart,
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data, interval, showBreakdown) =>
          transformData(data, "data", "activeUsers", interval, showBreakdown),
        hasTimerangePicker: true,
        dateRangeOptions: WAU_GROUPED_OPTIONS,
      },
    },
    {
      section: MauContainer,
      typeId: 2,
      slug: "mau",
      title: "Monthly active {APP_OBJECT_PLURAL_NAME}",
      description: {
        title: "Learn more about Monthly Active Users",
        content:
          "Monthly Active Users are the unique users that triggered the track and / or page events on a month.",
      },
      slackAlertAvailable: true,
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        Components: {
          stacked_line: SimpleStackedLineChart,
          stacked_bar: SimpleStackedBarChart,
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data, interval, showBreakdown) =>
          transformData(data, "data", "activeUsers", interval, showBreakdown),
        hasTimerangePicker: true,
        dateRangeOptions: MAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 3,
      slug: "dau-wau",
      title:
        "Percentage of weekly active {APP_OBJECT_PLURAL_NAME} that are active daily (DAU / WAU)",
      section: DauWauContainer,
      slackAlertAvailable: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "data", "stickynessRatio"),
        hasTimerangePicker: true,
        dateRangeOptions: DAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 4,
      slug: "dau-mau",
      title:
        "Percentage of monthly active {APP_OBJECT_PLURAL_NAME} that are active daily (DAU / MAU)",
      section: DauMauContainer,
      slackAlertAvailable: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "data", "stickynessRatio"),
        hasTimerangePicker: true,
        dateRangeOptions: DAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 5,
      slug: "wau-mau",
      title:
        "Percentage of monthly active {APP_OBJECT_PLURAL_NAME} that are active weekly (WAU / MAU)",
      section: WauMauContainer,
      slackAlertAvailable: true,
      view: {
        level: "user",
        Component: SimpleAreaChart,
        transform: (data) => transformData(data, "data", "stickynessRatio"),
        hasTimerangePicker: true,
        dateRangeOptions: WAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 13,
      slug: "latest-active-companies",
      title: "Latest active {APP_OBJECT_PLURAL_NAME}",
      section: GroupsHeaderInsight,
      shareable: true,
      slackAlertAvailable: true,
      view: {
        level: "group",
        Component: Trend,
        transform: (data, interval) => {
          if (!data || !Array.isArray(data) || data?.length === 0 || !interval)
            return {};

          const trendData = data?.find((d: any) => d?.interval === interval);
          if (!trendData) return {};

          return {
            count: trendData.count,
            change: trendData.insightChange,
            interval,
            ...trendData,
          } as ITrendData;
        },
        hasTimerangePicker: false,
      },
    },
    {
      typeId: 9,
      slug: "dac",
      title: "Daily active {APP_OBJECT_PLURAL_NAME}",
      timerangeOptions: MOST_ACTIVE_USERS_OPTIONS,
      section: DagContainer,
      slackAlertAvailable: true,
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        Components: {
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data) => transformData(data, "data", "activeGroups"),
        hasTimerangePicker: true,
        dateRangeOptions: DAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 14,
      slug: "most-active-companies",
      title: "Most active {APP_OBJECT_PLURAL_NAME}",
      section: MostActiveGroupsContainer,
      timerangeOptions: MOST_ACTIVE_USERS_OPTIONS,
      shareable: true,
      copyable: false,
      downloadable: false,
      slackAlertAvailable: true,
      view: {
        level: "group",
        Component: List,
        transform: (data) => {
          return data?.groups?.slice(0, 5)?.map((entity: IMostActiveGroup) => {
            const traits = entity?.traits as ITrait;
            return {
              id: entity?.groupId,
              displayName: traits?.["name"],
              description: `Used over ${entity?.dayCount} days`,
            };
          });
        },
        hasTimerangePicker: true,
        dateRangeOptions: MOST_ACTIVE_USERS_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 10,
      slug: "wac",
      title: "Weekly active {APP_OBJECT_PLURAL_NAME}",
      section: WagContainer,
      slackAlertAvailable: true,
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        Components: {
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data) => transformData(data, "data", "activeGroups"),
        hasTimerangePicker: true,
        dateRangeOptions: WAU_GROUPED_OPTIONS,
      },
    },
    {
      typeId: 11,
      slug: "mac",
      title: "Monthly active {APP_OBJECT_PLURAL_NAME}",
      section: MagContainer,
      slackAlertAvailable: true,
      hasInProgress: true,
      hasYAxis: true,
      view: {
        level: "group",
        Component: SimpleAreaChart,
        Components: {
          area: SimpleAreaChart,
          bar: SimpleBarChart,
        },
        transform: (data) => transformData(data, "data", "activeGroups"),
        hasTimerangePicker: true,
        dateRangeOptions: MAU_GROUPED_OPTIONS,
      },
    },
  ],
  hasSmartEvents: true,
  setup: {
    requiredSections: [0],
    eventOperator: OR,
    setupSections: [
      {
        id: "retention_event",
        configKey: "events",
        title: "Events",
        description:
          "This event should be a core action in your product. Pick multiple events if you have more than one",
        type: MULTI_EVENT_TYPE,
        supportedEventTypes: [ANY, TRACK, PAGE],
        required: true,
        eventOccurrences: true,
        validate: (events) =>
          events &&
          events.length > 0 &&
          events.length ===
            events.map((event) => event.id).filter((x) => x).length,
      },
      {
        id: "audience",
        configKey: "audience",
        title: "Audience",
        description: "Select an audience for your report",
        type: AUDIENCE_TYPE,
        supportedUserTypes: ["user"],
      },
      {
        id: "settings",
        configKey: "settings",
        title: "Settings",
        description: "Select the settings for your report",
        type: SETTINGS_TYPE,
        options: [
          {
            type: "boolean",
            identifier: "removeWeekends",
            displayName: "Weekends",
          },
          {
            type: "boolean",
            isUserOption: true,
            identifier: "removeNewUsers",
            displayName: "New users",
            tooltip:
              "Show users that performed their first event in a period won't be counted as active. For instance if a user signed up in a given week they won't be counted as part of the Weekly Active Users for that week.",
          },
          {
            isCompanyOption: true,
            type: "boolean",
            identifier: "removeNewUsers",
            displayName: "New companies",
            tooltip:
              "Show companies that performed their first event in a period won't be counted as active. For instance if a company signed up in a given week they won't be counted as part of the Weekly Active Companies for that week.",
          },
        ],
      },
    ],
  },
  validateSetup: (config) =>
    config &&
    config?.events &&
    config?.events?.length > 0 &&
    config?.events?.some((e) => e.name),
};
