import { Copy } from "lucide-react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { Button } from "@/Components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { useDuplicateAudienceMutation } from "@/core/models/audiences";
import { IAudience } from "@/core/types/Audience";

export const DuplicateAction: React.FC<{ audience: IAudience }> = ({
  audience,
}) => {
  const { appId } = useParams();
  const [duplicateAudience] = useDuplicateAudienceMutation();

  const handleDuplicate = async () => {
    if (!audience.id) return;

    await duplicateAudience({ appId: Number(appId), audienceId: audience.id })
      .unwrap()
      .then(() => {
        toast.success("Audience duplicated successfully");
      })
      .catch(() => {
        toast.error("Failed to duplicate audience");
      });
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          onClick={handleDuplicate}
          className="px-1 text-gray-600"
        >
          <Copy className="h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Duplicate audience</TooltipContent>
    </Tooltip>
  );
};
