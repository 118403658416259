import { Color } from "@tiptap/extension-color";
import { FontFamily } from "@tiptap/extension-font-family";
import FontSize from "@tiptap/extension-font-size";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import {
  useEditor,
  EditorContent,
  BubbleMenu,
  NodeViewWrapper,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useRef, useState, useCallback, useEffect } from "react";
import * as ReactDOM from "react-dom/client";
import { toast } from "sonner";

import { Button } from "@/Components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/Components/ui/dialog";
import { Input } from "@/Components/ui/input";
import { useDebouncedCallback } from "@/core/hooks/useDebouncedCallback";
import {
  IViewInsight,
  useUpdateContentMutation,
  useUploadContentImageMutation,
} from "@/core/models/viewInsights";
import { cn } from "@/lib/utils";
import { OverflowMenu } from "modules/ViewInsight/OverflowMenu";

const FONT_SIZE_OPTIONS = {
  "14px": { label: "Paragraph", icon: "P", value: "14px" },
  "18px": { label: "Heading 2", icon: "H2", value: "18px" },
  "24px": { label: "Heading 1", icon: "H1", value: "24px" },
};

// CSS for image styling
const imageCSS = `
.ProseMirror img {
  max-width: 100%;
  height: auto;
  transition: all 0.1s ease-in-out;
  cursor: default;
  border: 1px solid transparent;
  border-radius: 5px;
  will-change: width, height;
}

.ProseMirror img:hover {
  border: 1px solid #6868F7;
}

.ProseMirror a {
  cursor: pointer;
  pointer-events: auto;
}

.ProseMirror a:hover {
  text-decoration: underline;
}

.ProseMirror img.ProseMirror-selectednode {
  outline: 1px solid #6868F7;
  border-radius: 5px;
}

.resizer {
  display: inline-flex;
  position: relative;
  max-width: 100%;
  will-change: transform;
}

.resizer .resize-handle {
  position: absolute;
  bottom: -4px;
  right: -4px;
  background-color: #6868F7;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  cursor: nwse-resize;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.2s, background-color 0.2s, width 0.2s, height 0.2s;
  border: 2px solid white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  touch-action: none;
}

.resizer:hover .resize-handle {
  opacity: 1;
}

.resizer.resizing {
  cursor: nwse-resize;
}

.resizer.resizing .resize-handle {
  opacity: 1;
  background-color: #4a4af7;
  width: 16px;
  height: 16px;
}

.resizer.resizing img {
  pointer-events: none;
  user-select: none;
  border: 1px solid #6868F7;
  opacity: 0.95;
  box-shadow: 0 0 8px rgba(104, 104, 247, 0.3);
}

/* Ensure images in public mode don't show resize cursor */
.ProseMirror[contenteditable="false"] .resizer {
  cursor: default !important;
  pointer-events: none !important;
}

.ProseMirror[contenteditable="false"] .resizer img {
  cursor: default !important;
  pointer-events: none !important;
  user-select: none !important;
  border-color: transparent !important;
}

.ProseMirror[contenteditable="false"] .resizer:hover .resize-handle {
  display: none !important;
  opacity: 0 !important;
}
`;

// Add a helper function to throttle resize events for better performance
const throttle = (func: Function, limit: number) => {
  let inThrottle: boolean;
  let lastResult: unknown;

  return function (this, ...args: unknown[]) {
    if (!inThrottle) {
      inThrottle = true;
      lastResult = func.apply(this, args);

      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }

    return lastResult;
  };
};

// Custom node view for resizable images
const ResizableImageNodeView = (props) => {
  const { node, updateAttributes, isPublic } = props;
  const [width, setWidth] = useState(node.attrs.width || null);
  const [height, setHeight] = useState(node.attrs.height || null);
  const [resizingClass, setResizingClass] = useState("");
  const imageRef = useRef<HTMLImageElement>(null);
  const resizerRef = useRef<HTMLDivElement>(null);
  const isResizingRef = useRef(false);
  const startXRef = useRef(0);
  const startYRef = useRef(0);
  const startWidthRef = useRef(0);
  const startHeightRef = useRef(0);
  const aspectRatioRef = useRef(1);
  const resizeHandleRef = useRef<HTMLDivElement>(null);
  const scaleXRef = useRef(1);
  const scaleYRef = useRef(1);

  console.log("isPublic", isPublic);

  const onImageLoad = useCallback(() => {
    if (imageRef.current) {
      // Set initial width and height if not already set
      if (!width || !height) {
        const newWidth = imageRef.current.naturalWidth;
        const newHeight = imageRef.current.naturalHeight;
        setWidth(newWidth);
        setHeight(newHeight);
        updateAttributes({ width: newWidth, height: newHeight });
      }

      // Store aspect ratio for resizing
      aspectRatioRef.current =
        imageRef.current.naturalWidth / imageRef.current.naturalHeight;
    }
  }, [width, height, updateAttributes]);

  // Throttled resize move handler for better performance
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onResizeMove = useCallback(
    throttle((moveEvent: MouseEvent) => {
      // Don't allow resizing in public mode
      if (isPublic) {
        return;
      }

      if (isResizingRef.current && imageRef.current && resizerRef.current) {
        moveEvent.preventDefault();
        moveEvent.stopPropagation();

        const deltaX = moveEvent.clientX - startXRef.current;
        const newWidth = Math.max(50, startWidthRef.current + deltaX);
        const newHeight = Math.round(newWidth / aspectRatioRef.current);

        // Calculate scale factors
        scaleXRef.current = newWidth / startWidthRef.current;
        scaleYRef.current = newHeight / startHeightRef.current;

        // Apply transform for smoother resizing during the operation
        resizerRef.current.style.transform = `scale(${scaleXRef.current}, ${scaleYRef.current})`;
        resizerRef.current.style.transformOrigin = "top left";
      }
    }, 10),
    [isPublic],
  );

  const onResizeEnd = useCallback(() => {
    // Don't allow resizing in public mode
    if (isPublic) {
      return;
    }

    if (isResizingRef.current && imageRef.current && resizerRef.current) {
      isResizingRef.current = false;
      setResizingClass("");

      // Reset transform
      resizerRef.current.style.transform = "";

      // Calculate final dimensions
      const newWidth = Math.round(startWidthRef.current * scaleXRef.current);
      const newHeight = Math.round(startHeightRef.current * scaleYRef.current);

      // Update state and attributes with final values
      setWidth(newWidth);
      setHeight(newHeight);

      // Update the node attributes to persist the dimensions
      updateAttributes({
        width: newWidth,
        height: newHeight,
      });

      // Reset scale references
      scaleXRef.current = 1;
      scaleYRef.current = 1;

      // Remove event listeners
      window.removeEventListener("mousemove", onResizeMove);
      window.removeEventListener("mouseup", onResizeEnd);
      window.removeEventListener("mouseleave", onResizeEnd);
    }
  }, [updateAttributes, onResizeMove, isPublic]);

  const onResizeStart = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      // Don't allow resizing in public mode
      if (isPublic) {
        return;
      }

      if (imageRef.current) {
        startXRef.current = e.clientX;
        startYRef.current = e.clientY;
        startWidthRef.current = imageRef.current.width;
        startHeightRef.current = imageRef.current.height;
        isResizingRef.current = true;
        setResizingClass("resizing");

        // Add event listeners to window for better tracking
        window.addEventListener("mousemove", onResizeMove);
        window.addEventListener("mouseup", onResizeEnd);
        window.addEventListener("mouseleave", onResizeEnd);
      }
    },
    [onResizeMove, onResizeEnd, isPublic],
  );

  // Clean up event listeners when component unmounts
  useEffect(() => {
    return () => {
      window.removeEventListener("mousemove", onResizeMove);
      window.removeEventListener("mouseup", onResizeEnd);
      window.removeEventListener("mouseleave", onResizeEnd);
    };
  }, [onResizeMove, onResizeEnd]);

  return (
    <NodeViewWrapper>
      <div ref={resizerRef} className={`resizer ${resizingClass}`}>
        <img
          ref={imageRef}
          src={node.attrs.src}
          alt={node.attrs.alt || ""}
          width={width}
          height={height}
          onLoad={onImageLoad}
        />
        {!isPublic && (
          <div
            ref={resizeHandleRef}
            className="resize-handle"
            onMouseDown={onResizeStart}
            title="Drag to resize"
          />
        )}
      </div>
    </NodeViewWrapper>
  );
};

// Create a custom extension that extends the Image extension
const ResizableImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.width) {
            return {};
          }
          return {
            width: attributes.width,
            style: `width: ${attributes.width}px; height: ${attributes.height || "auto"}px;`,
          };
        },
        parseHTML: (element) => element.getAttribute("width"),
      },
      height: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.height) {
            return {};
          }
          return {
            height: attributes.height,
          };
        },
        parseHTML: (element) => element.getAttribute("height"),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "img[src]",
        getAttrs: (node) => {
          if (typeof node === "string") return {};
          const element = node as HTMLElement;
          const width = element.getAttribute("width");
          const height = element.getAttribute("height");
          return {
            src: element.getAttribute("src"),
            alt: element.getAttribute("alt"),
            title: element.getAttribute("title"),
            width: width ? parseInt(width, 10) : null,
            height: height ? parseInt(height, 10) : null,
          };
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    const { width, height } = HTMLAttributes;

    // Add inline style for width and height if they exist
    if (width && height) {
      HTMLAttributes.style = `width: ${width}px; height: ${height}px;`;
    }

    return ["img", HTMLAttributes];
  },
  addNodeView() {
    return ({ node, editor, getPos, HTMLAttributes }) => {
      const dom = document.createElement("div");

      const updateAttributes = (attrs: Record<string, unknown>) => {
        if (typeof getPos === "function") {
          editor.view.dispatch(
            editor.view.state.tr.setNodeMarkup(getPos(), undefined, {
              ...node.attrs,
              ...attrs,
            }),
          );
        }
      };

      // Create a React root and render the component
      const root = ReactDOM.createRoot(dom);

      // Initial render with current isPublic value
      root.render(
        <ResizableImageNodeView
          node={node}
          updateAttributes={updateAttributes}
          HTMLAttributes={HTMLAttributes}
          isPublic={editor.storage.isPublic || false}
        />,
      );

      return {
        dom,
        update: (updatedNode) => {
          if (updatedNode.type !== node.type) {
            return false;
          }

          // Update the component with new props, including the latest isPublic value
          root.render(
            <ResizableImageNodeView
              node={updatedNode}
              updateAttributes={updateAttributes}
              HTMLAttributes={HTMLAttributes}
              isPublic={editor.storage.isPublic || false}
            />,
          );

          return true;
        },
        destroy: () => {
          // Clean up React component
          root.unmount();
        },
      };
    };
  },
});

const MenuBar = ({
  editor,
  onImageUploadClick,
}: {
  editor: unknown;
  onImageUploadClick: () => void;
}) => {
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");

  if (!editor) return null;

  const toggleStyle = (style: string) => {
    switch (style) {
      case "bold":
        editor.chain().focus().toggleBold().run();
        break;
      case "italic":
        editor.chain().focus().toggleItalic().run();
        break;
      case "underline":
        editor.chain().focus().toggleUnderline().run();
        break;
      case "strike":
        editor.chain().focus().toggleStrike().run();
        break;
    }
  };

  const isActive = (style: string) => editor.isActive(style);

  const openLinkDialog = () => {
    // Pre-fill with the current link URL if a link is selected
    if (editor.isActive("link")) {
      setLinkUrl(editor.getAttributes("link").href || "");
    } else {
      setLinkUrl("");
    }
    setLinkDialogOpen(true);
  };

  const addLink = () => {
    // If there's no URL, unset the link
    if (!linkUrl) {
      editor.chain().focus().unsetLink().run();
      setLinkDialogOpen(false);
      return;
    }

    // Normalize the URL (add https:// if missing)
    let url = linkUrl;
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    }

    // Set the link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();

    setLinkDialogOpen(false);
    setLinkUrl("");
  };

  return (
    <>
      <div className="relative flex items-center gap-1 rounded-lg bg-white p-0.5 shadow-lg">
        <div className="flex items-center gap-0.5">
          <Button
            type="button"
            onClick={() => toggleStyle("bold")}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={`rounded p-2 hover:bg-gray-100 disabled:opacity-50 ${
              isActive("bold") ? "bg-gray-100" : ""
            }`}
            title="Bold"
            variant="ghost"
            size="icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              fill="currentColor"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z" />
            </svg>
          </Button>
          <Button
            type="button"
            onClick={() => toggleStyle("italic")}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={`rounded p-2 hover:bg-gray-100 disabled:opacity-50 ${
              isActive("italic") ? "bg-gray-100" : ""
            }`}
            title="Italic"
            variant="ghost"
            size="icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              fill="currentColor"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z" />
            </svg>
          </Button>
          <Button
            type="button"
            onClick={() => toggleStyle("underline")}
            disabled={!editor.can().chain().focus().toggleUnderline().run()}
            className={`rounded p-2 hover:bg-gray-100 disabled:opacity-50 ${
              isActive("underline") ? "bg-gray-100" : ""
            }`}
            title="Underline"
            variant="ghost"
            size="icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              fill="currentColor"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M8 3v9a4 4 0 1 0 8 0V3h2v9a6 6 0 1 1-12 0V3h2zM4 20h16v2H4v-2z" />
            </svg>
          </Button>
          <Button
            type="button"
            onClick={() => toggleStyle("strike")}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            className={`rounded p-2 hover:bg-gray-100 disabled:opacity-50 ${
              isActive("strike") ? "bg-gray-100" : ""
            }`}
            title="Strikethrough"
            variant="ghost"
            size="icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              fill="currentColor"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z" />
            </svg>
          </Button>
        </div>
        <div className="h-4 w-px bg-gray-200" />
        <select
          onChange={(e) => {
            editor.chain().focus().setFontSize(e.target.value).run();
          }}
          value={editor.getAttributes("textStyle").fontSize}
          className="w-[100px] cursor-pointer appearance-none rounded border-none px-1 py-0.5 text-sm hover:bg-gray-100"
        >
          {Object.entries(FONT_SIZE_OPTIONS).map(([value, { label }]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <div className="h-4 w-px bg-gray-200" />
        <Button
          type="button"
          onClick={onImageUploadClick}
          className="rounded p-2 hover:bg-gray-100"
          title="Upload Image"
          variant="ghost"
          size="icon"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
          </svg>
        </Button>
        <Button
          type="button"
          onClick={openLinkDialog}
          className={`rounded p-2 hover:bg-gray-100 ${
            isActive("link") ? "bg-gray-100" : ""
          }`}
          title="Insert Link"
          variant="ghost"
          size="icon"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.414-1.414 7.071-7.07z" />
          </svg>
        </Button>
      </div>

      <Dialog open={linkDialogOpen} onOpenChange={setLinkDialogOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Insert Link</DialogTitle>
          </DialogHeader>
          <div className="flex items-center space-y-2 py-4">
            <Input
              type="text"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              placeholder="https://example.com"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  addLink();
                }
              }}
            />
          </div>
          <DialogFooter className="sm:justify-end">
            <Button
              type="button"
              variant="secondary"
              onClick={() => setLinkDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button type="button" onClick={addLink}>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const Editor = ({
  content,
  viewInsight,
  isPublic,
  isDragging = false,
}: {
  content: string;
  viewInsight: IViewInsight;
  isPublic?: boolean;
  isDragging?: boolean;
}) => {
  const [updateContent] = useUpdateContentMutation();
  const [uploadContentImage] = useUploadContentImageMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const debouncedUpdateContent = useDebouncedCallback((content) => {
    if (!viewInsight.content?.id) return;
    updateContent({
      appId: viewInsight.appId,
      id: viewInsight.content?.id,
      content: content as string,
    });
  }, 500) as (content: string) => void;

  const handleImageUpload = async (file: File, editor: unknown) => {
    try {
      if (!viewInsight.content?.id) return;

      const response = await uploadContentImage({
        appId: viewInsight.appId,
        contentId: viewInsight.content.id,
        image: file,
      }).unwrap();

      // Load the image to get dimensions before inserting
      const tempImg = document.createElement("img");
      tempImg.onload = () => {
        // Set the image with dimensions
        editor
          .chain()
          .focus()
          .setImage({
            src: response.url,
            width: tempImg.naturalWidth,
            height: tempImg.naturalHeight,
          })
          .run();
      };
      tempImg.src = response.url;

      toast.success("Image uploaded successfully");
    } catch (error) {
      toast.error("Error uploading image");
      console.error("Error uploading image:", error);
    }
  };

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3],
        },
        listItem: {
          HTMLAttributes: {
            class: "list-item",
          },
        },
      }),
      TextStyle.configure({
        HTMLAttributes: {
          class: "text-style",
        },
      }),
      FontSize,
      Color,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      FontFamily,
      Underline,
      // Add Link extension
      Link.configure({
        openOnClick: true,
        HTMLAttributes: {
          class: "text-purple-500 underline cursor-pointer",
          rel: "noopener noreferrer nofollow",
          target: "_blank",
        },
      }),
      // Use the custom ResizableImage extension instead of the standard Image
      ResizableImage.configure({
        allowBase64: true,
        inline: false,
        HTMLAttributes: {
          class: "resizable-image",
        },
      }),
    ],
    content,
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none [&_*::selection]:bg-purple-100 [&_p]:text-[14px]",
      },
      handleClick: (view, pos, event) => {
        const link = event.target as HTMLElement;

        // Check if the clicked element is a link
        if (link.tagName === "A" && !isPublic) {
          event.preventDefault();
          const href = link.getAttribute("href");
          if (href) {
            window.open(href, "_blank", "noopener,noreferrer");
            return true;
          }
        }

        return false;
      },
      transformPastedHTML(html) {
        // This helps ensure pasted content maintains image dimensions
        return html;
      },
      handlePaste: (view, event) => {
        // Handle image paste
        const items = Array.from(event.clipboardData?.items || []);
        const imageItem = items.find((item) => item.type.startsWith("image/"));

        if (imageItem) {
          event.preventDefault();
          const file = imageItem.getAsFile();
          if (file && editor) {
            handleImageUpload(file, editor);
          }
          return true;
        }

        // Handle text paste
        event.preventDefault();
        const text = event.clipboardData?.getData("text/plain") ?? "";
        view.dispatch(view.state.tr.insertText(text));
        return true;
      },
      handleDrop: (view, event, slice, moved) => {
        if (
          !moved &&
          event.dataTransfer &&
          event.dataTransfer.files &&
          event.dataTransfer.files[0]
        ) {
          const file = event.dataTransfer.files[0];
          const isImage = file.type.startsWith("image/");

          if (isImage && editor) {
            event.preventDefault();
            handleImageUpload(file, editor);
            return true;
          }
        }
        return false;
      },
    },
    onUpdate: ({ editor }) => {
      if (!viewInsight.content?.id) return;

      // Get the HTML content with all attributes preserved
      const htmlContent = editor.getHTML();
      debouncedUpdateContent(htmlContent);
    },
    editable: !isPublic,
  });

  // Store isPublic in editor storage for access in node views
  useEffect(() => {
    if (editor) {
      console.log("Setting editor.storage.isPublic to:", isPublic);
      editor.storage.isPublic = isPublic;

      // Force a refresh of the editor view to update all node views
      // This ensures the isPublic value is properly propagated
      const currentContent = editor.getHTML();
      editor.commands.setContent(currentContent);
    }
  }, [editor, isPublic]);

  if (!editor) {
    return null;
  }

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      handleImageUpload(file, editor);
      // Reset the input value so the same file can be selected again
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  return (
    <div className={cn("relative cursor-text rounded-lg")}>
      {/* Inject custom CSS for image styling */}
      <style dangerouslySetInnerHTML={{ __html: imageCSS }} />

      {!isPublic && (
        <div className="absolute -top-2 right-0 z-10 ">
          <OverflowMenu viewInsight={viewInsight} />
        </div>
      )}
      {editor && (
        <BubbleMenu
          className="bubble-menu"
          tippyOptions={{
            duration: 100,
            placement: "top",
            // Always show the menu when editor is focused
            showOnCreate: true,
            trigger: "manual",
            hideOnClick: false,
          }}
          editor={editor}
        >
          <MenuBar
            editor={editor}
            onImageUploadClick={() => fileInputRef.current?.click()}
          />
        </BubbleMenu>
      )}
      <input
        type="file"
        ref={fileInputRef}
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
      <EditorContent
        editor={editor}
        onClick={(e) => {
          // Handle link clicks
          const target = e.target as HTMLElement;
          if (target.tagName === "A" && !isPublic) {
            e.preventDefault();
            const href = target.getAttribute("href");
            if (href) {
              window.open(href, "_blank", "noopener,noreferrer");
            }
          }
        }}
      />
    </div>
  );
};
