import { Button, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

import { IReport } from "core/types/Report";
import { DeleteItem } from "modules/Report/Overflow/DeleteItem";
import { DuplicateItem } from "modules/Report/Overflow/DuplicateItem";
import { FavouriteItem } from "modules/Report/Overflow/FavouriteItem";
import { RefreshItem } from "modules/Report/Overflow/RefreshItem";
import { ShareItem } from "modules/Report/Overflow/ShareItem";

export const OverflowMenu: React.FC<{
  report: IReport;
}> = ({ report }) => {
  return (
    <Menu>
      <MenuButton as={Button} variant="ghost">
        <EllipsisVerticalIcon className="h-4" />
      </MenuButton>
      <MenuList>
        <ShareItem report={report} />
        <RefreshItem report={report} />
        <DuplicateItem report={report} />
        <FavouriteItem report={report} />
        <DeleteItem report={report} />
      </MenuList>
    </Menu>
  );
};
