import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import { SnakeCasedProperties } from "type-fest";

import { baseQuery } from "core/initializers/rtk";
import { ITraitWithConfig } from "core/types/Trait";

// TODO: Configure interface later
export interface ITrait {
  name: string;
  value: string;
  trait?: ITraitWithConfig;
}

export const objectTraitsApi = createApi({
  baseQuery,
  reducerPath: "objectTraitsApi",
  tagTypes: ["Trait"],
  endpoints: (builder) => ({
    getTraitList: builder.query({
      query: ({
        appId,
        appObjectId,
        objectId,
      }: {
        appId: number;
        appObjectId: number;
        objectId: string;
      }) => ({
        url: `/objects/traits`,
        params: humps.decamelizeKeys({ appId, appObjectId, objectId }),
      }),
      transformResponse: (response: SnakeCasedProperties<ITrait>): ITrait[] =>
        humps.camelizeKeys(response) as ITrait[],
      providesTags: (result, error, { objectId }) => {
        return result ? [{ type: "Trait", id: objectId }] : [];
      },
    }),
    updateTrait: builder.mutation({
      query: ({
        appId,
        appObjectId,
        objectId,
        trait,
        value,
      }: {
        appId: number;
        appObjectId: number;
        objectId: string;
        trait: string;
        value: string;
      }) => ({
        url: `/objects/traits/${objectId}`,
        method: "PUT",
        body: humps.decamelizeKeys({ trait, value }),
        params: humps.decamelizeKeys({ appId, appObjectId, objectId }),
      }),
      invalidatesTags: (result, error, { objectId }) => [
        { type: "Trait", id: objectId },
        { type: "Trait", id: "LIST" },
      ],
    }),
    pinTrait: builder.mutation({
      query: ({
        appId,
        appObjectId,
        objectId,
        traitId,
        traitName,
        isPinned,
      }: {
        appId: number;
        appObjectId: number;
        objectId: string;
        traitId: number | undefined;
        traitName: string;
        isPinned: boolean;
      }) => ({
        url: `/objects/traits/pin`,
        method: "PUT",
        body: humps.decamelizeKeys({
          appId,
          appObjectId,
          objectId,
          traitId,
          traitName,
          isPinned,
        }),
      }),
      invalidatesTags: (result, error, { objectId }) => [
        { type: "Trait", id: objectId },
      ],
    }),
  }),
});

export const {
  useGetTraitListQuery,
  useUpdateTraitMutation,
  usePinTraitMutation,
} = objectTraitsApi;
