import { Button, MenuItem, Menu, MenuButton, MenuList } from "@chakra-ui/react";

import { PaywallPopover } from "core/components/Paywall/Popover";
import { SolidChevronDown } from "core/design-system/components/Icon/Custom/SolidChevronDown";
import { useAppObjects } from "core/hooks/useAppObjects";
import { IUpdateInsightPayload } from "core/hooks/useEditViewInsight";
import { IViewInsight } from "core/models/viewInsights";
import { Plan } from "core/types/App";
import { AudienceMenu } from "modules/ViewInsight/Audience/AudienceMenu";

export const SetupContainer: React.FC<{
  viewInsight: IViewInsight;
  onUpdateInsight: (payload: IUpdateInsightPayload) => Promise<void>;
}> = ({ viewInsight, onUpdateInsight }) => {
  const { appObjects } = useAppObjects();

  return (
    <div className="relative flex h-full w-full flex-grow flex-col">
      <p className="text-sm font-medium ">Audience</p>
      <AudienceMenu
        viewInsight={viewInsight}
        onUpdateInsight={onUpdateInsight}
      />
      <div className="mt-4">
        <p className="mb-2 text-sm font-medium">Object</p>
        <Menu matchWidth>
          <PaywallPopover
            feature="company level metrics"
            redirect="people"
            plan={Plan.Growth}
          >
            <MenuButton
              textAlign="left"
              w="full"
              as={Button}
              rightIcon={<SolidChevronDown className="h-4 w-4 text-gray-900" />}
              bg="gray.50"
              border="none"
              textTransform="capitalize"
            >
              <div className="flex items-center gap-1">
                {viewInsight?.appObject?.pluralName}
              </div>
            </MenuButton>
          </PaywallPopover>
          <MenuList>
            {appObjects.map((appObject) => (
              <MenuItem
                textTransform="capitalize"
                onClick={async () =>
                  await onUpdateInsight({
                    ...viewInsight,
                    objectType: appObject.objectType,
                  })
                }
              >
                <div className="flex items-center gap-1">
                  {appObject.pluralName}
                </div>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};
