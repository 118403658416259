import React from "react";

import { useInsightTitle } from "core/hooks/useInsightTitle";
import { IReport } from "core/types/Report";
import { IInsightSection } from "core/types/TemplateConfig";

interface ITitleProps {
  showReportTitle: boolean;
  insight: IInsightSection;
  report?: IReport;
}

export const Title: React.FC<ITitleProps> = ({
  showReportTitle,
  insight,
  report,
}) => {
  const insightTitle = useInsightTitle({ title: insight.title });

  return (
    <>
      {showReportTitle && report ? (
        <div className="flex flex-col">
          <div className="flex">
            <p className="text-gray-900">{insightTitle}</p>
          </div>
          <div className="flex">
            <p className="text-sm text-gray-600">{report?.name}</p>
          </div>
        </div>
      ) : (
        insightTitle
      )}
    </>
  );
};
