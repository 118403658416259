import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import { ShareIcon } from "@heroicons/react/24/outline";
import { AnimatePresence, motion } from "framer-motion";
import { GlobeIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Input } from "@/Components/ui/input";
import { Spinner } from "@/Components/ui/spinner";
import { Button } from "Components/ui/button";
import { ClipboardButton } from "core/components/ClipboardButton";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { getAudienceGroupId } from "core/helpers/audienceHelper";
import { usePaywall } from "core/hooks/usePaywall";
import {
  IView,
  useEnablePasswordMutation,
  useGetViewTokenQuery,
  useShareViewMutation,
  useUpdatePasswordMutation,
  useUnshareViewMutation,
  useDisablePasswordMutation,
} from "core/models/views";
import { Plan } from "core/types/App";

interface IShareable {
  shareable: IView;
  size?: "default" | "sm";
  showLabel?: boolean;
  shareableType: "View" | "ViewInsight";
}

export const Shareable: React.FC<IShareable> = ({
  shareable,
  size = "default",
  showLabel = true,
  shareableType,
}) => {
  const { appObjectId, objectId } = useParams<{
    appObjectId: string;
    objectId: string;
  }>();

  const getGroupId = () => {
    if (objectId) return objectId;
    if (shareableType === "View") {
      return getAudienceGroupId(shareable.audience).groupId;
    }
    return undefined;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: viewToken, isLoading: isLoadingViewToken } =
    useGetViewTokenQuery({
      appId: shareable.appId,
      viewId: shareable.id,
      groupId: objectId,
    });
  const [enablePassword] = useEnablePasswordMutation();
  const [updatePassword, { isLoading: isLoadingUpdatePassword }] =
    useUpdatePasswordMutation();
  const [disablePassword] = useDisablePasswordMutation();

  const { plan } = usePaywall();
  const [password, setPassword] = useState(viewToken?.password || "");
  const [share] = useShareViewMutation();
  const [unshare] = useUnshareViewMutation();

  useEffect(() => {
    setPassword(viewToken?.password || "");
  }, [viewToken?.password]);

  const onShare = () => {
    share({
      appId: shareable.appId,
      viewId: shareable.id,
      groupId: getGroupId(),
    });
  };

  function onEnablePassword() {
    enablePassword({ appId: shareable.appId, viewId: shareable.id });
  }

  function onDisablePassword() {
    disablePassword({ appId: shareable.appId, viewId: shareable.id });
  }

  function onUpdatePassword() {
    updatePassword({
      appId: shareable.appId,
      viewId: shareable.id,
      password,
    });
  }

  const onUnshare = () => {
    unshare({
      appId: shareable.appId,
      viewId: shareable.id,
      groupId: getGroupId(),
    });
  };

  const isShared =
    viewToken &&
    typeof viewToken === "object" &&
    Object.keys(viewToken).length > 0;

  const BASE_URL = import.meta.env.VITE_APP_HOST || "http://localhost:3000";
  const publicUrl =
    isShared &&
    (objectId
      ? `${BASE_URL}/a/${shareable.appId}/objects/${appObjectId}/object/${objectId}/dashboard/${shareable.id}/${viewToken.token}`
      : `${BASE_URL}/a/${shareable.appId}/dashboard/${shareable.id}/${viewToken.token}`);

  return (
    <>
      <Button variant="ghost" onClick={onOpen} size={size}>
        <div className="flex items-center gap-1">
          {isLoadingViewToken ? (
            <Spinner size="small" />
          ) : (
            <>
              {isShared ? (
                <GlobeIcon className="size-4 text-gray-700" />
              ) : (
                <ShareIcon className="size-4 text-gray-700" />
              )}

              {showLabel && (isShared ? "Public" : "Share")}
            </>
          )}
        </div>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share {shareable.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="flex items-center justify-between pb-4">
              <div>
                <p className="text-sm font-medium">Enable public sharing</p>
                <p className="text-sm text-gray-600">
                  Publish and share link with anyone
                </p>
              </div>
              <Switch
                isChecked={isShared}
                isDisabled={plan !== Plan.Pro}
                colorScheme="purple"
                onChange={isShared ? onUnshare : onShare}
              />
            </div>
            <AnimatePresence>
              {isShared && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <p className="text-sm font-medium">Copy public link</p>
                  <p className="text-sm text-gray-600">
                    Anyone with the link can view this dashboard
                  </p>
                  <div className="mt-4 flex w-full items-center gap-1">
                    <p className="truncate text-sm text-gray-700 underline">
                      {publicUrl}
                    </p>
                    <ClipboardButton
                      value={publicUrl || ""}
                      showLabel={false}
                    />
                  </div>
                  {viewToken && (
                    <div className="mt-6">
                      <div className="flex w-full items-center justify-between">
                        <div>
                          <p className="text-sm font-medium">
                            Password required
                          </p>
                          <p className="text-sm text-gray-600">
                            Protect your dashboard with a password
                          </p>
                        </div>
                        <Switch
                          isChecked={Boolean(viewToken?.password)}
                          colorScheme="purple"
                          onChange={
                            viewToken.password
                              ? onDisablePassword
                              : onEnablePassword
                          }
                        />
                      </div>
                      {viewToken.password && (
                        <div className="relative mt-5 flex w-full items-center">
                          <Input
                            size="sm"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="pr-[100px]"
                          />
                          <Button
                            size="sm"
                            variant="ghost"
                            className="absolute right-1 h-6 bg-white"
                            disabled={password === viewToken.password}
                            onClick={onUpdatePassword}
                          >
                            {isLoadingUpdatePassword ? (
                              <Spinner size="small" />
                            ) : (
                              "Update"
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </ModalBody>
          <ModalFooter>
            <div className="flex w-full items-center justify-end gap-2">
              <PaywallPopover
                feature="public dashboards"
                redirect="home"
                plan={Plan.Pro}
              >
                <Button onClick={onClose}>
                  {plan !== Plan.Pro ? "Share" : "Done"}
                </Button>
              </PaywallPopover>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
