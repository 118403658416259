import { StarIcon } from "lucide-react";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { Button } from "@/Components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { useFavourites } from "@/core/hooks/useFavourites";
import {
  FavouriteObjectType,
  useGetFavouriteQuery,
} from "@/core/models/favourites";
import { IView } from "core/models/views";

export const FavoriteAction: React.FC<{ view: IView }> = ({ view }) => {
  const { appId } = useParams();
  const { data: favourite } = useGetFavouriteQuery({
    appId: Number(appId),
    objectId: String(view.id),
    objectType: FavouriteObjectType.View,
  });

  const { onDeleteFavourite, onCreateFavourite } = useFavourites(
    Number(appId),
    [FavouriteObjectType.View],
  );

  function handleFavourite() {
    if (view?.id === undefined) return null;

    if (favourite) {
      onDeleteFavourite(favourite);
      toast.success("Dashboard removed from favourites");
    } else {
      onCreateFavourite({
        objectId: String(view.id),
        objectType: FavouriteObjectType.View,
      });
      toast.success("Dashboard added to favourites");
    }
  }
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          onClick={handleFavourite}
          className="text-gray-600"
        >
          {favourite ? (
            <StarIcon className="h-4 w-4 fill-yellow-400 text-yellow-400" />
          ) : (
            <StarIcon className="h-4 w-4 text-gray-600" />
          )}
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {favourite ? "Remove from favourites" : "Add to favourites"}
      </TooltipContent>
    </Tooltip>
  );
};
