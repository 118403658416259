import humps from "humps";

import { ReportState } from "@/core/hooks/useReportSetup";
import {
  BREAKDOWN_TYPE,
  CONVERSION_WINDOW_TYPE,
  ENUM_TYPE,
  MULTI_EVENT_TYPE,
  OCCURRENCES_TYPE,
  SETTINGS_TYPE,
  SINGLE_EVENT_TYPE,
} from "core/constants/report-setup";
import * as api from "core/helpers/api";
import { getReportConfig } from "core/helpers/getReportConfig";
import { utf8ToBase64 } from "core/helpers/insights/utf8ToBase64";
import { AppThunk } from "core/initializers/store";
import { insightsApi } from "core/models/insights";
import {
  resetIsAddingOnAudienceFilters,
  slice,
} from "core/models/report/slice";
import { IAudience, IAudienceFilters } from "core/types/Audience";
import { IEvent } from "core/types/Event";
import { JoinOperatorValue } from "core/types/Filters.d";
import { IFilterGroup, IReport } from "core/types/Report";
import { ITemplateConfigSetup } from "core/types/TemplateConfig";

export const {
  _fetch,
  _fetchSuccess,
  _fetchError,

  // config
  _addTrackEvent,
  _selectTrackEvent,
  _updateTrackEvent,
  _removeTrackEvent,
  _updatePageEvent,
  _updateEnum,
  _updateSetting,
  _loadReportSetup,
  _updateReportSetup,
  _updateReportSetupSuccess,
  _updateReportSetupError,
  _updateEventOrdering,
  _updateSectionName,
  _updateReportTimerange,
  _updateReportTimerangeSuccess,
  _updateReportTimerangeError,
  _updateReportType,
  _updateReportTypeSuccess,
  _updateReportTypeError,
  _updateReportLevelSuccess,
  _updateReportLevelError,

  // audience
  _addAudienceFilterGroup,
  _removeAudienceFilterGroup,
  _updateJoinOperator,
  _addAudienceFilter,
  _updateAudienceFilter,
  _removeAudienceFilter,
  _updateFilterGroupJoinOperator,
  _addSavedAudience,
  _removeSavedAudience,
  _updateAudience,

  // conversion window
  _enableConversionWindow,
  _disableConversionWindow,
  _updateConversionWindowIntervalCount,
  _updateConversionWindowIntervalType,

  // breakdown
  _addBreakdown,
  _removeBreakdown,

  // occurrences
  _editOccurrences,

  // setting
  _updateMailSetting,

  // funnel
  _addFunnelStep,
  _removeFunnelStep,
  _updateFunnelOrder,
} = slice.actions;

export const fetchReport =
  ({
    appId,
    reportId,
    level,
  }: {
    appId: string;
    reportId: string;
    level: string;
  }) =>
  async (dispatch: any) => {
    dispatch(_fetch());
    try {
      const { data } = await api.fetchReport({
        appId,
        reportId,
        level,
        dispatch,
      });
      const setup = getReportConfig(data.reportType)!.setup;
      dispatch(_loadReportSetup({ report: data, setup }));
    } catch (error: any) {
      console.error(`Error fetching report`, error);
      dispatch(_fetchError(error.message));
    }
  };

export const addTrackEvent =
  ({ event, configKey }: { event: IEvent; configKey: string }): AppThunk =>
  async (dispatch) => {
    dispatch(_addTrackEvent({ event, configKey }));
  };

export const updateReportTimerange =
  ({
    timerangeType,
    timerangeValue,
    timerangeStartTimestamp,
    timerangeEndTimestamp,
  }: {
    timerangeType: string | number;
    timerangeValue: string | number;
    timerangeStartTimestamp: string;
    timerangeEndTimestamp: string;
  }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().report;
    const currentReport = state.data;
    dispatch(
      _updateReportTimerange({
        timerangeType,
        timerangeValue,
        timerangeStartTimestamp,
        timerangeEndTimestamp,
      }),
    );

    try {
      const updatedReport = {
        ...currentReport,
        timerangeType,
        timerangeValue,
        timerangeStartTimestamp,
        timerangeEndTimestamp,
      };

      await api.updateReportTimerange({
        appId: String(updatedReport.appId),
        reportId: String(updatedReport.id),
        audience: currentReport.audience,
        timerangeType: updatedReport.timerangeType,
        timerangeValue: updatedReport.timerangeValue,
        timerangeStartTimestamp: updatedReport.timerangeStartTimestamp,
        timerangeEndTimestamp: updatedReport.timerangeEndTimestamp,
        dispatch,
      });
      dispatch(_updateReportTimerangeSuccess({ report: updatedReport }));
      dispatch(insightsApi.util.invalidateTags(["Insight"]));
    } catch (error) {
      dispatch(_updateReportTimerangeError());
    }
  };

export const updateReportType =
  ({ reportType }: { reportType: number }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().report;
    const currentReport = state.data;
    dispatch(_updateReportType({ reportType }));

    try {
      const response = await api.updateReportType({
        appId: String(currentReport.appId),
        reportId: String(currentReport.id),
        reportConfig: utf8ToBase64(
          JSON.stringify(humps.decamelizeKeys(currentReport.config) || {}),
        ),
        reportType,
        dispatch,
      });
      const updatedReport = { ...response.data, reportType };
      dispatch(_updateReportTypeSuccess({ report: updatedReport }));
      dispatch(insightsApi.util.invalidateTags(["Insight"]));
    } catch (error) {
      dispatch(_updateReportTypeError());
    }
  };

export const selectTrackEvent =
  ({ event, configKey }: { event: IEvent; configKey: string }): AppThunk =>
  async (dispatch) => {
    dispatch(_selectTrackEvent({ event, configKey }));
  };

export const updateTrackEvent =
  ({ event, configKey }: { event: IEvent; configKey: string }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateTrackEvent({ event, configKey }));
  };

export const updateEventOrdering =
  ({ events, configKey }: { configKey: string; events: IEvent[] }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateEventOrdering({ configKey, events }));
  };

export const updatePageEvent =
  ({ event, configKey }: { event: IEvent; configKey: string }): AppThunk =>
  async (dispatch) => {
    dispatch(_updatePageEvent({ event, configKey }));
  };

export const removeTrackEvent =
  ({ event, configKey }: { event: IEvent; configKey: string }): AppThunk =>
  async (dispatch) => {
    dispatch(_removeTrackEvent({ event, configKey }));
  };

export const updateEnum =
  ({ option, configKey }: { option: any; configKey: string }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateEnum({ option, configKey }));
  };

export const updateSetting =
  ({ setting, configKey }: { setting: any; configKey: string }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateSetting({ setting, configKey }));
  };

export const updateSectionName =
  ({ name, configKey }: { name: string; configKey: string }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateSectionName({ name, configKey }));
  };

export const loadReportSetup =
  ({
    report,
    setup,
  }: {
    report: IReport;
    setup: ITemplateConfigSetup;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(_loadReportSetup({ report, setup }));
  };

export const updateReportSetup =
  ({
    setup,
    state = ReportState.PERSISTED,
  }: {
    setup: ITemplateConfigSetup;
    state?: ReportState;
  }): AppThunk =>
  async (dispatch, getState) => {
    const currentSetup = getState().report.data;

    dispatch(_updateReportSetup());

    let config: { [key: string]: any } = {};
    setup.setupSections.forEach(({ type, configKey }) => {
      if (type === ENUM_TYPE) {
        config[configKey] = currentSetup.config[configKey][0].value;
      } else if (type === SINGLE_EVENT_TYPE) {
        config[configKey] = currentSetup.config[configKey][0];
      } else if (type === MULTI_EVENT_TYPE) {
        config[configKey] = currentSetup.config[configKey];
        if (currentSetup.config.name) {
          config = {
            ...currentSetup.config,
            name: {
              [configKey]: currentSetup.config.name[configKey],
              ...currentSetup.config.name,
            },
          };
        }
      } else if (
        type === SETTINGS_TYPE ||
        type === OCCURRENCES_TYPE ||
        type === CONVERSION_WINDOW_TYPE ||
        type === BREAKDOWN_TYPE
      ) {
        config[configKey] = currentSetup.config[configKey];
      }
    });

    try {
      const { data } = await api.updateReportSetup({
        appId: String(currentSetup.appId),
        reportId: String(currentSetup.id),
        config,
        audience: resetIsAddingOnAudienceFilters(currentSetup.audience),
        /*@ts-ignore*/
        reportMailSetting: state.reportMailSetting,
        dispatch,
        state,
      });
      const setup = getReportConfig(data.reportType)!.setup;
      dispatch(_loadReportSetup({ report: data, setup }));
      dispatch(_updateReportSetupSuccess({ report: currentSetup }));
      dispatch(insightsApi.util.invalidateTags(["Insight"]));
    } catch (error) {
      dispatch(_updateReportSetupError());
    }
  };

export const addSavedAudience =
  ({ audience }: { audience: IAudience }): AppThunk =>
  async (dispatch) => {
    dispatch(_addSavedAudience({ audience }));
  };

export const removeSavedAudience = (): AppThunk => async (dispatch) => {
  dispatch(_removeSavedAudience());
};

export const addAudienceFilterGroup =
  ({ filterGroup }: { filterGroup: IFilterGroup }): AppThunk =>
  async (dispatch) => {
    dispatch(_addAudienceFilterGroup({ filterGroup }));
  };

export const updateAudience =
  ({ audience }: { audience: IAudienceFilters }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateAudience({ audience }));
  };

export const removeAudienceFilterGroup =
  ({ filterGroupIndex }: { filterGroupIndex: number }): AppThunk =>
  async (dispatch) => {
    dispatch(_removeAudienceFilterGroup({ filterGroupIndex }));
  };

export const updateJoinOperator =
  ({ operator }: { operator: JoinOperatorValue }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateJoinOperator({ operator }));
  };

export const addAudienceFilter =
  ({
    filterGroupIndex,
    filter,
  }: {
    filterGroupIndex: number;
    filter: any;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(_addAudienceFilter({ filterGroupIndex, filter }));
  };

export const updateAudienceFilter =
  ({
    filterGroupIndex,
    filter,
  }: {
    filterGroupIndex: number;
    filter: any;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateAudienceFilter({ filterGroupIndex, filter }));
  };

export const removeAudienceFilter =
  ({
    filterGroupIndex,
    filter,
  }: {
    filterGroupIndex: number;
    filter: any;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(_removeAudienceFilter({ filterGroupIndex, filter }));
  };

export const updateFilterGroupJoinOperator =
  ({
    filterGroupIndex,
    operator,
  }: {
    filterGroupIndex: number;
    operator: JoinOperatorValue;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateFilterGroupJoinOperator({ filterGroupIndex, operator }));
  };

// Conversion window
export const enableConversionWindow = (): AppThunk => async (dispatch) => {
  dispatch(_enableConversionWindow());
};

export const disableConversionWindow = (): AppThunk => async (dispatch) => {
  dispatch(_disableConversionWindow());
};

export const updateConversionWindowIntervalCount =
  ({ intervalCount }: { intervalCount: number }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateConversionWindowIntervalCount({ intervalCount }));
  };

export const updateConversionWindowIntervalType =
  ({ intervalType }: { intervalType: number }): AppThunk =>
  async (dispatch) => {
    dispatch(_updateConversionWindowIntervalType({ intervalType }));
  };

// Breakdown
export const addBreakdown =
  ({ breakdown }: { breakdown: any }): AppThunk =>
  async (dispatch) => {
    dispatch(_addBreakdown({ breakdown }));
  };

export const removeBreakdown = (): AppThunk => async (dispatch) => {
  dispatch(_removeBreakdown());
};

// Occurrences
export const editOccurrences =
  ({ occurrences }: { occurrences: number[][] }): AppThunk =>
  async (dispatch) => {
    dispatch(_editOccurrences({ occurrences }));
  };

// Settings
export const updateMailSetting =
  (isEnabled: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().report;

    dispatch(_updateMailSetting(isEnabled));

    await api.updateReportMailSetting({
      appId: String(state.data.appId),
      reportId: String(state.data.id),
      isEnabled,
      dispatch,
    });
  };

// Funnel
export const addFunnelStep =
  (configKey: string): AppThunk =>
  async (dispatch) => {
    dispatch(_addFunnelStep({ configKey }));
  };

export const removeFunnelStep =
  (configKey: string): AppThunk =>
  async (dispatch) => {
    dispatch(_removeFunnelStep({ configKey }));
  };

export const updateFunnelOrder =
  (order: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(_updateFunnelOrder({ order }));
  };
