import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { EVENT } from "core/constants/filters";
import { insightsApi } from "core/models/insights";
import {
  addAudienceFilter as _addAudienceFilter,
  addAudienceFilterGroup as _addAudienceFilterGroup,
  addBreakdown as _addBreakdown,
  addFunnelStep as _addFunnelStep,
  addSavedAudience as _addSavedAudience,
  addTrackEvent as _addTrackEvent,
  disableConversionWindow as _disableConversionWindow,
  editOccurrences as _editOccurrences,
  enableConversionWindow as _enableConversionWindow,
  fetchReport as _fetchReport,
  loadReportSetup as _loadReportSetup,
  removeAudienceFilter as _removeAudienceFilter,
  removeAudienceFilterGroup as _removeAudienceFilterGroup,
  removeBreakdown as _removeBreakdown,
  removeFunnelStep as _removeFunnelStep,
  removeSavedAudience as _removeSavedAudience,
  removeTrackEvent as _removeTrackEvent,
  selectTrackEvent as _selectTrackEvent,
  updateAudience as _updateAudience,
  updateAudienceFilter as _updateAudienceFilter,
  updateConversionWindowIntervalCount as _updateConversionWindowIntervalCount,
  updateConversionWindowIntervalType as _updateConversionWindowIntervalType,
  updateEnum as _updateEnum,
  updateEventOrdering as _updateEventOrdering,
  updateFilterGroupJoinOperator as _updateFilterGroupJoinOperator,
  updateFunnelOrder as _updateFunnelOrder,
  updateJoinOperator as _updateJoinOperator,
  updateMailSetting as _updateMailSetting,
  updatePageEvent as _updatePageEvent,
  updateReportSetup as _updateReportSetup,
  updateReportTimerange as _updateReportTimerange,
  updateReportType as _updateReportType,
  updateSectionName as _updateSectionName,
  updateSetting as _updateSetting,
  updateTrackEvent as _updateTrackEvent,
} from "core/models/report/actions";
import { selector as reportSelector } from "core/models/report/selectors";
import { getLevelParam } from "core/modules/reports/utils";
import { IAudience, IAudienceFilters } from "core/types/Audience";
import { IBreakdown } from "core/types/Breakdown";
import { IEvent } from "core/types/Event";
import { IFilter, IFilterGroup, JoinOperatorValue } from "core/types/Filters.d";
import { IReport } from "core/types/Report";
import { ITemplateConfigSetup } from "core/types/TemplateConfig";

export enum ReportState {
  UNPERSISTED = 0,
  PERSISTED = 1,
}

export const useReportSetup = () => {
  const dispatch = useDispatch();
  const paramReportLevel = getLevelParam();
  const { appId, reportId } = useParams<{
    appId: string;
    reportId: string;
  }>() as {
    appId: string;
    reportId: string;
  };
  const {
    data: currentReport,
    isUpdating,
    isLoading,
    hasBeenPersisted,
    serializeKey,
  } = useSelector(reportSelector);

  const isCurrentlyAddingFilter = currentReport?.audience?.filterGroups?.some(
    (filterGroup) =>
      filterGroup?.filters?.some(
        (filter: IFilter) =>
          filter.adding &&
          !(filter?.body?.comparisonType?.toString() || filter?.body?.eventId),
      ),
  );
  const isUsingEventFilters = currentReport?.audience?.filterGroups?.some(
    (filterGroup: IFilterGroup) =>
      filterGroup.filters?.some((filter: IFilter) => filter.type === EVENT),
  );

  function fetchReport() {
    dispatch(_fetchReport({ appId, reportId, level: paramReportLevel }));
  }

  function invalidateInsights() {
    dispatch(insightsApi.util.invalidateTags(["Insight"]));
  }

  function updateReportTimerange({
    timerangeType,
    timerangeValue,
    timerangeStartTimestamp,
    timerangeEndTimestamp,
  }: {
    timerangeType: string | number;
    timerangeValue: string | number;
    timerangeStartTimestamp: string;
    timerangeEndTimestamp: string;
  }) {
    dispatch(
      _updateReportTimerange({
        timerangeType,
        timerangeValue,
        timerangeStartTimestamp,
        timerangeEndTimestamp,
      }),
    );
  }

  function updateReportType({ reportType }: { reportType: number }) {
    dispatch(_updateReportType({ reportType }));
  }

  function updateSectionName({
    name,
    configKey,
  }: {
    name: string;
    configKey: string;
  }) {
    dispatch(_updateSectionName({ name, configKey }));
  }

  function updateEnum({
    option,
    configKey,
  }: {
    option: any;
    configKey: string;
  }) {
    dispatch(_updateEnum({ option, configKey }));
  }

  function addTrackEvent({
    event,
    configKey,
  }: {
    event: IEvent;
    configKey: string;
  }) {
    dispatch(_addTrackEvent({ event, configKey }));
  }

  function selectTrackEvent({
    event,
    configKey,
  }: {
    event: IEvent;
    configKey: string;
  }) {
    dispatch(_selectTrackEvent({ event, configKey }));
  }

  function updateTrackEvent({
    event,
    configKey,
  }: {
    event: IEvent;
    configKey: string;
  }) {
    dispatch(_updateTrackEvent({ event, configKey }));
  }

  function removeTrackEvent({
    event,
    configKey,
  }: {
    event: IEvent;
    configKey: string;
  }) {
    dispatch(_removeTrackEvent({ event, configKey }));
  }

  function updateEventOrdering({
    configKey,
    events,
  }: {
    configKey: string;
    events: any[];
  }) {
    dispatch(_updateEventOrdering({ configKey, events }));
  }

  function enableConversionWindow() {
    dispatch(_enableConversionWindow());
  }

  function disableConversionWindow() {
    dispatch(_disableConversionWindow());
  }

  function updateConversionWindowIntervalCount({
    intervalCount,
  }: {
    intervalCount: number;
  }) {
    dispatch(_updateConversionWindowIntervalCount({ intervalCount }));
  }

  function updateConversionWindowIntervalType({
    intervalType,
  }: {
    intervalType: number;
  }) {
    dispatch(_updateConversionWindowIntervalType({ intervalType }));
  }

  function addBreakdown({ breakdown }: { breakdown: IBreakdown }) {
    dispatch(_addBreakdown({ breakdown }));
  }

  function removeBreakdown() {
    dispatch(_removeBreakdown());
  }

  function editOccurrences({ occurrences }: { occurrences: number[][] }) {
    dispatch(_editOccurrences({ occurrences }));
  }

  function updateReportSetup({
    setup,
    state,
  }: {
    setup: any;
    state?: ReportState;
  }) {
    dispatch(_updateReportSetup({ setup, state }));
  }

  function loadReportSetup({
    report,
    setup,
  }: {
    report: IReport;
    setup: ITemplateConfigSetup;
  }) {
    dispatch(_loadReportSetup({ report, setup }));
  }

  function updateSetting({
    setting,
    configKey,
  }: {
    setting: any;
    configKey: string;
  }) {
    dispatch(_updateSetting({ setting, configKey }));
  }

  // audience filters

  function updateAudience(audience: IAudienceFilters) {
    dispatch(_updateAudience({ audience }));
  }

  function addAudienceFilterGroup({ filterGroup }: { filterGroup: any }) {
    dispatch(_addAudienceFilterGroup({ filterGroup }));
  }

  function removeAudienceFilterGroup({
    filterGroupIndex,
  }: {
    filterGroupIndex: number;
  }) {
    dispatch(_removeAudienceFilterGroup({ filterGroupIndex }));
  }

  function updateJoinOperator({ operator }: { operator: JoinOperatorValue }) {
    dispatch(_updateJoinOperator({ operator }));
  }

  function addAudienceFilter({
    filterGroupIndex,
    filter,
  }: {
    filterGroupIndex: number;
    filter: any;
  }) {
    dispatch(_addAudienceFilter({ filterGroupIndex, filter }));
  }

  function removeAudienceFilter({
    filterGroupIndex,
    filter,
  }: {
    filterGroupIndex: number;
    filter: any;
  }) {
    dispatch(_removeAudienceFilter({ filterGroupIndex, filter }));
  }

  function updateAudienceFilter({
    filterGroupIndex,
    filter,
  }: {
    filterGroupIndex: number;
    filter: any;
  }) {
    dispatch(_updateAudienceFilter({ filterGroupIndex, filter }));
  }

  function updateFilterGroupJoinOperator({
    filterGroupIndex,
    operator,
  }: {
    filterGroupIndex: number;
    operator: JoinOperatorValue;
  }) {
    dispatch(_updateFilterGroupJoinOperator({ filterGroupIndex, operator }));
  }

  // audience filters

  function updatePageEvent({
    event,
    configKey,
  }: {
    event: any;
    configKey: string;
  }) {
    dispatch(_updatePageEvent({ event, configKey }));
  }

  function addSavedAudience({ audience }: { audience: IAudience }) {
    dispatch(_addSavedAudience({ audience }));
  }

  function removeSavedAudience() {
    dispatch(_removeSavedAudience());
  }

  function updateMailSetting(isEnabled: boolean) {
    dispatch(_updateMailSetting(isEnabled));
  }

  function addFunnelStep(configKey: string) {
    dispatch(_addFunnelStep(configKey));
  }

  function removeFunnelStep(configKey: string) {
    dispatch(_removeFunnelStep(configKey));
  }

  function updateFunnelOrder(order: string[]) {
    dispatch(_updateFunnelOrder(order));
  }

  return {
    currentReport,
    hasBeenPersisted,
    isCurrentlyAddingFilter,
    isUsingEventFilters,
    isUpdating,
    isLoading,
    addAudienceFilterGroup,
    addAudienceFilter,
    enableConversionWindow,
    disableConversionWindow,
    updateConversionWindowIntervalCount,
    updateConversionWindowIntervalType,
    addBreakdown,
    addTrackEvent,
    editOccurrences,
    fetchReport,
    updateAudience,
    removeAudienceFilterGroup,
    removeAudienceFilter,
    removeBreakdown,
    removeTrackEvent,
    selectTrackEvent,
    updateAudienceFilter,
    updateEnum,
    updateEventOrdering,
    updateJoinOperator,
    updateFilterGroupJoinOperator,
    updatePageEvent,
    updateReportSetup,
    updateReportTimerange,
    updateSectionName,
    updateSetting,
    updateTrackEvent,
    addSavedAudience,
    removeSavedAudience,
    serializeKey,
    invalidateInsights,
    updateMailSetting,
    addFunnelStep,
    removeFunnelStep,
    updateFunnelOrder,
    updateReportType,
    loadReportSetup,
  };
};
