import { useEffect, useId } from "react";
import { useParams } from "react-router-dom";

import { useAppObjects } from "@/core/hooks/useAppObjects";
import { Measure, GraphType } from "@/core/types/ViewInsight";
import { InsightEditorContainer } from "@/modules/Dashboard/InsightBuilder/EditorContainer";
import { JuneLoadingPage } from "@/modules/Dashboard/LoadingPage";
import { useNavigation } from "core/hooks/useNavigation";
import {
  useGetViewInsightPreviewQuery,
  useGetViewInsightQuery,
} from "core/models/viewInsights";

export const AddInsightRoute: React.FC = () => {
  const { appId, viewId } = useParams();
  const { userAppObject } = useAppObjects();
  const { navigation, toggleSidebar } = useNavigation();
  const id = useId();
  const { data: previewInsight, isLoading } = useGetViewInsightPreviewQuery({
    appId: Number(appId),
    viewId: Number(viewId),
    measure: Measure.ActiveUsers,
    graphType: GraphType.Area,
    objectType: userAppObject?.objectType,
    uuid: id,
  });

  const { data: viewInsight, isLoading: isLoadingViewInsight } =
    useGetViewInsightQuery({
      id: Number(previewInsight?.id),
      viewId: Number(viewId),
      appId: Number(appId),
    });

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !previewInsight || isLoadingViewInsight || !viewInsight) {
    return <JuneLoadingPage />;
  }

  return (
    <div className="h-screen w-full overflow-hidden">
      <InsightEditorContainer
        viewInsight={viewInsight}
        isAddingNewInsight={true}
      />
    </div>
  );
};
