import { FavouriteObjectType } from "core/models/favourites";
import { IReport } from "core/types/Report";
import { FavouritesMenuItem } from "modules/Company/FavouritesMenuItem";

export const FavouriteItem: React.FC<{
  report: IReport;
}> = ({ report }) => {
  return (
    <FavouritesMenuItem
      objectId={report.id.toString()}
      objectType={FavouriteObjectType.Report}
      appId={report.appId}
    />
  );
};
