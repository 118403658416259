import { useParams } from "react-router-dom";

import { Spinner } from "@/Components/ui/spinner";
import { ViewInsightsContainer } from "@/modules/ViewInsights/Container";
import { useAppObjects } from "core/hooks/useAppObjects";
import { ViewLocation } from "core/hooks/useViews";
import { IView } from "core/models/views";
import { AppObjectType } from "core/types/AppObject";
import { ContactActivity } from "modules/Contacts/Contact/ContactActivity";
import { ContactMostTriggeredEvents } from "modules/Contacts/Contact/ContactMostTriggeredEvents";
import { ContactPages } from "modules/Contacts/Contact/ContactPages";

export const ObjectOverviewSection: React.FC<{
  view: IView;
  isLoadingView: boolean;
  showSQLModal: boolean;
  setShowSQLModal: (show: boolean) => void;
  showAudienceModal: boolean;
  setShowAudienceModal: (show: boolean) => void;
}> = ({
  view,
  isLoadingView,
  showSQLModal,
  setShowSQLModal,
  showAudienceModal,
  setShowAudienceModal,
}) => {
  const { appObjectId, objectId } = useParams<{
    appObjectId: string;
    objectId: string;
  }>();
  const { appObjects } = useAppObjects();
  const currentObject = appObjects.find((o) => o.id === Number(appObjectId));

  if (isLoadingView) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!view) {
    return <div>No view found</div>;
  }

  return (
    <>
      {currentObject?.objectType === AppObjectType.User ? (
        <div className="flex w-full flex-col gap-2">
          <ContactActivity contactId={objectId} />
          <div className="flex flex-row gap-2">
            <ContactMostTriggeredEvents contactId={objectId} />
            <ContactPages contactId={objectId} />
          </div>
        </div>
      ) : (
        <div className="flex max-w-full justify-center">
          <ViewInsightsContainer
            view={view}
            location={ViewLocation.Company}
            viewId={Number(view.id)}
            columns={3}
            paywallInsights
            showAudienceModal={showAudienceModal}
            setShowAudienceModal={setShowAudienceModal}
          />
        </div>
      )}
    </>
  );
};
