import { Box, Stack, Text } from "@chakra-ui/react";

import { ICustomTooltipProps as ITooltipProps } from "core/types/CustomTooltip";

interface IProps extends ITooltipProps {
  hideEventNames?: boolean;
}

export const BarCustomTooltip: React.FC<IProps> = ({
  label,
  payload,
  active,
  hideEventNames = false,
  ...props
}) => {
  if (active && payload && payload.length) {
    // Get the first payload item to access the complete data
    const firstPayload = payload[0].payload;

    // Calculate total count per feature
    const featureTotals = Object.entries(firstPayload)
      .filter(([key]) => key !== "x")
      .map(([key, value]) => ({ name: key, value: value as number }))
      .sort((a, b) => b.value - a.value);

    const totalCount = featureTotals.reduce(
      (sum, feature) => sum + feature.value,
      0,
    );

    return (
      <Box
        bg="white"
        px={1.5}
        py={1.5}
        borderRadius="md"
        shadow="md"
        border="1px solid"
        borderColor="gray.200"
        {...props}
      >
        <div className="flex h-full items-center gap-x-1">
          <div className="h-4 w-1 rounded-full bg-gray-200" />
          <div className="flex gap-2">
            <Text fontSize="xs" fontWeight="medium">
              {totalCount.toLocaleString()} total
            </Text>
            <Text fontSize="xs" color="gray.600">
              {label}
            </Text>
          </div>
        </div>
        {featureTotals.map((feature, idx) => {
          const payloadItem = payload.find((p) => p.name === feature.name);
          return (
            <Stack direction="row" mt={1} spacing="0" align="center" key={idx}>
              <div className="flex w-full items-center justify-between gap-2">
                <div className="flex w-full items-center justify-between gap-x-1 text-xs">
                  <div className="flex items-center gap-x-1">
                    <Box
                      h="2"
                      w="2"
                      bg={payloadItem?.color}
                      borderRadius="2px"
                    ></Box>
                    <Text fontSize="xs" fontWeight="medium" fontFamily="mono">
                      {feature.value.toLocaleString()}{" "}
                    </Text>
                    <Text fontSize="xs">
                      {feature.name.toLocaleLowerCase()}
                    </Text>
                  </div>
                  <div className="flex justify-end self-end">
                    <p className="ml-1 inline-block font-mono text-gray-600">
                      {feature.value
                        ? ((feature.value / totalCount) * 100).toFixed(1)
                        : 0}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </Stack>
          );
        })}
      </Box>
    );
  }
  return null;
};
