import { DataTypeString } from "core/models/traits";
import { ComparisonType, JoinOperatorValue } from "core/types/Filters";

export const IS = ComparisonType.IS;
export const IS_NOT = ComparisonType.IS_NOT;
export const STARTS_WITH = ComparisonType.STARTS_WITH;
export const ENDS_WITH = ComparisonType.ENDS_WITH;
export const CONTAINS = ComparisonType.CONTAINS;
export const DOES_NOT_CONTAIN = ComparisonType.DOES_NOT_CONTAIN;
export const IS_UNKNOWN = ComparisonType.IS_UNKNOWN;
export const HAS_ANY_VALUE = ComparisonType.HAS_ANY_VALUE;
export const GREATER_THAN = ComparisonType.GREATER_THAN;
export const LESS_THAN = ComparisonType.LESS_THAN;
export const BEFORE = ComparisonType.BEFORE;
export const AFTER = ComparisonType.AFTER;
export const ON = ComparisonType.ON;
export const LESS_THAN_DAYS_AGO = ComparisonType.LESS_THAN_DAYS_AGO;
export const MORE_THAN_DAYS_AGO = ComparisonType.MORE_THAN_DAYS_AGO;
export const LESS_THAN_DAYS_FROM_NOW = ComparisonType.LESS_THAN_DAYS_FROM_NOW;
export const MORE_THAN_DAYS_FROM_NOW = ComparisonType.MORE_THAN_DAYS_FROM_NOW;

export interface TraitComparisonType {
  value: ComparisonType;
  name: string;
  hasInput: boolean;
  dataTypes: DataTypeString[];
}

export const TRAIT_COMPARISON_TYPES: TraitComparisonType[] = [
  {
    value: IS,
    name: "is",
    hasInput: true,
    dataTypes: [
      DataTypeString.STRING,
      DataTypeString.NUMBER,
      DataTypeString.BOOLEAN,
      DataTypeString.WORKSPACE_MEMBER,
      DataTypeString.OPTIONS,
      DataTypeString.OBJECT_ID,
    ],
  },
  {
    value: IS_NOT,
    name: "is not",
    hasInput: true,
    dataTypes: [
      DataTypeString.STRING,
      DataTypeString.NUMBER,
      DataTypeString.BOOLEAN,
      DataTypeString.WORKSPACE_MEMBER,
      DataTypeString.OPTIONS,
    ],
  },
  {
    value: STARTS_WITH,
    name: "starts with",
    hasInput: true,
    dataTypes: [DataTypeString.STRING],
  },
  {
    value: ENDS_WITH,
    name: "ends with",
    hasInput: true,
    dataTypes: [DataTypeString.STRING],
  },
  {
    value: CONTAINS,
    name: "contains",
    hasInput: true,
    dataTypes: [DataTypeString.STRING],
  },
  {
    value: DOES_NOT_CONTAIN,
    name: "does not contain",
    hasInput: true,
    dataTypes: [DataTypeString.STRING],
  },
  {
    value: IS_UNKNOWN,
    name: "is unknown",
    hasInput: false,
    dataTypes: [
      DataTypeString.STRING,
      DataTypeString.NUMBER,
      DataTypeString.BOOLEAN,
      DataTypeString.DATE,
    ],
  },
  {
    value: HAS_ANY_VALUE,
    name: "has any value",
    hasInput: false,
    dataTypes: [
      DataTypeString.STRING,
      DataTypeString.NUMBER,
      DataTypeString.BOOLEAN,
      DataTypeString.DATE,
    ],
  },
  {
    value: GREATER_THAN,
    name: "greater than",
    hasInput: true,
    dataTypes: [DataTypeString.NUMBER],
  },
  {
    value: LESS_THAN,
    name: "less than",
    hasInput: true,
    dataTypes: [DataTypeString.NUMBER],
  },
  {
    value: BEFORE,
    name: "before",
    hasInput: true,
    dataTypes: [DataTypeString.DATE],
  },
  {
    value: AFTER,
    name: "after",
    hasInput: true,
    dataTypes: [DataTypeString.DATE],
  },
  {
    value: ON,
    name: "on",
    hasInput: true,
    dataTypes: [DataTypeString.DATE],
  },
  {
    value: LESS_THAN_DAYS_AGO,
    name: "less than days ago",
    hasInput: true,
    dataTypes: [DataTypeString.DATE],
  },
  {
    value: MORE_THAN_DAYS_AGO,
    name: "more than days ago",
    hasInput: true,
    dataTypes: [DataTypeString.DATE],
  },
  {
    value: LESS_THAN_DAYS_FROM_NOW,
    name: "less than days from now",
    hasInput: true,
    dataTypes: [DataTypeString.DATE],
  },
  {
    value: MORE_THAN_DAYS_FROM_NOW,
    name: "more than days from now",
    hasInput: true,
    dataTypes: [DataTypeString.DATE],
  },
];

export const COMPARISON_TO_NAME = [
  "is",
  "is not",
  "starts with",
  "ends with",
  "contains",
  "does not contain",
  "is unknown",
  "has any value",
  "greater than",
  "less than",
  "before",
  "after",
  "on",
  "less than days ago",
  "more than days ago",
  "less than days from now",
  "more than days from now",
] as string[];

export const NAME_TO_COMPARISON = {
  is: IS,
  is_not: IS_NOT,
  starts_with: STARTS_WITH,
  ends_with: ENDS_WITH,
  contains: CONTAINS,
  does_not_contain: DOES_NOT_CONTAIN,
  is_unknown: IS_UNKNOWN,
  has_any_value: HAS_ANY_VALUE,
  greater_than: GREATER_THAN,
  less_than: LESS_THAN,
  before: BEFORE,
  after: AFTER,
  on: ON,
  less_than_days_ago: LESS_THAN_DAYS_AGO,
  more_than_days_ago: MORE_THAN_DAYS_AGO,
  less_than_days_from_now: LESS_THAN_DAYS_FROM_NOW,
  more_than_days_from_now: MORE_THAN_DAYS_FROM_NOW,
};

export const AND = JoinOperatorValue.AND;
export const OR = JoinOperatorValue.OR;

export const OPERATOR_TO_NAME = {
  0: "and",
  1: "or",
};
