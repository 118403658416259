import { Button, Tooltip } from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import { useAudiences } from "core/hooks/useAudiences";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { IAudience } from "core/types/Audience";

export const AddAudienceMenu: React.FC<{
  isDisabled: boolean;
  onAddAudience: (audience: IAudience) => void;
}> = ({ isDisabled, onAddAudience }) => {
  const currentApp = useCurrentApp();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { audiences, isLoading } = useAudiences({
    appId: currentApp.id,
    query: searchQuery,
  });
  const savedAudiences = audiences?.audiences || [];
  return (
    <Popover className="relative">
      <Tooltip
        fontSize="xs"
        label={
          isDisabled
            ? "You cannot add an audience when you have custom filters"
            : "Add saved audience"
        }
        placement="top"
        bg="white"
        textColor="gray.800"
        className="rounded-md border border-gray-200"
        shouldWrapChildren
      >
        <PopoverButton data-testid="audience-add-filter-button">
          <Button
            isDisabled={isDisabled}
            colorScheme="purple"
            variant="ghost"
            leftIcon={<PlusIcon className="h-4" />}
          >
            Add audience
          </Button>
        </PopoverButton>
      </Tooltip>
      <PopoverPanel>
        {({ close }) => (
          <>
            <Command className="w-full min-w-[280px]">
              <Lifecycle onUnmount={() => setSearchQuery("")} />
              <CommandInput
                placeholder="Search audience..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <CommandList>
                {isLoading ? (
                  <div className="p-2 text-sm text-gray-500">Loading...</div>
                ) : savedAudiences.length === 0 ? (
                  <div className="p-2 text-sm text-gray-500">
                    No audiences found
                  </div>
                ) : (
                  savedAudiences.map((audience) => (
                    <CommandOption
                      key={audience.id}
                      value={audience.name || ""}
                      className="w-full"
                      onClick={() => {
                        onAddAudience(audience);
                        close();
                      }}
                    >
                      <p>{audience.name}</p>
                    </CommandOption>
                  ))
                )}
              </CommandList>
            </Command>
          </>
        )}
      </PopoverPanel>
    </Popover>
  );
};
