import { GridItem } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS, Transform } from "@dnd-kit/utilities";
import { GripHorizontal, TrashIcon } from "lucide-react";
import { Suspense, useRef, useState } from "react";
import { toast } from "sonner";

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/Components/ui/context-menu";
import { cn } from "@/lib/utils";
import { useEditDashboard } from "core/hooks/useEditDashboard";
import { ViewLocation } from "core/hooks/useViews";
import {
  useDeleteViewInsightMutation,
  useUpdateViewInsightMutation,
  IViewInsight,
} from "core/models/viewInsights";
import { IView } from "core/models/views";
import { Measure } from "core/types/ViewInsight";
import { GRID_TO_PX } from "modules/ViewInsight/Builder/LayoutContainer";
import { AudienceModalContainer } from "modules/ViewInsight/Modal/AudienceModalContainer";
import { InsightBuilderContainer } from "modules/ViewInsight/Modal/InsightBuilderContainer";
import { SQLEditorContainer } from "modules/ViewInsight/Modal/SQLEditorContainer";
import { ViewInsight } from "modules/ViewInsight/ViewInsight";
import { GridOverlay } from "modules/ViewInsights/GridOverlay";
import { ResizeHandle } from "modules/ViewInsights/ResizeHandle";

// Helper function to extract pixel value from GRID_TO_PX
const extractPixelValue = (gridIndex: number): number => {
  const pixelString = GRID_TO_PX[gridIndex] || GRID_TO_PX[1]; // Default to first grid size if invalid
  return parseInt(pixelString.replace("px", ""));
};

export const SortableInsight: React.FC<{
  viewInsight: IViewInsight;
  view: IView;
  location: ViewLocation;
  shouldBePaywalled?: boolean;
  paywallInsights?: boolean;
  onRefetch: () => Promise<unknown>;
}> = ({
  viewInsight,
  view,
  location,
  shouldBePaywalled,
  paywallInsights,
  onRefetch,
}) => {
  const { openedInsight, clearOpenedViewInsight } = useEditDashboard();
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({
      id: viewInsight.id,
    });
  const [deleteViewInsight] = useDeleteViewInsightMutation();
  const [updateViewInsight] = useUpdateViewInsightMutation();
  const [isResizing, setIsResizing] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(viewInsight.gridWidth || 1);
  const [currentHeight, setCurrentHeight] = useState(
    viewInsight.gridHeight || 1,
  );
  const [resizeDirection, setResizeDirection] = useState<
    "horizontal" | "vertical"
  >("horizontal");

  const isAudienceMeasure = viewInsight.measure === Measure.Audience;

  const resizeRef = useRef({
    startX: 0,
    startY: 0,
    startWidth: 0,
    startHeight: 0,
    currentWidth: viewInsight.gridWidth || 1,
    currentHeight: viewInsight.gridHeight || 1,
    isResizing: false,
    direction: "horizontal" as "horizontal" | "vertical",
  });

  function handleDelete() {
    deleteViewInsight({
      appId: Number(viewInsight.appId),
      id: viewInsight.id,
    })
      .unwrap()
      .then(() => {
        toast.success("Deleted");
      });
  }

  const handleResizeStart = (
    e: React.MouseEvent,
    direction: "horizontal" | "vertical" = "horizontal",
  ) => {
    // Don't allow horizontal resizing for Audience measure insights
    if (isAudienceMeasure && direction === "horizontal") {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    setResizeDirection(direction);

    resizeRef.current = {
      startX: e.pageX,
      startY: e.pageY,
      startWidth: viewInsight.gridWidth || 1,
      startHeight: viewInsight.gridHeight || 1,
      currentWidth: viewInsight.gridWidth || 1,
      currentHeight: viewInsight.gridHeight || 1,
      isResizing: true,
      direction,
    };

    setIsResizing(true);
    setCurrentWidth(viewInsight.gridWidth || 1);
    setCurrentHeight(viewInsight.gridHeight || 1);

    const handleResizeMove = (e: MouseEvent) => {
      if (!resizeRef.current.isResizing) return;

      if (resizeRef.current.direction === "horizontal") {
        const deltaX = e.pageX - resizeRef.current.startX;
        // Calculate the target width in pixels first
        const currentWidthPx = extractPixelValue(resizeRef.current.startWidth);
        const targetWidthPx = currentWidthPx + deltaX;

        // Convert to grid units and ensure it stays within bounds
        const targetGridUnits = Math.max(
          1,
          Math.min(3, Math.round(targetWidthPx / 315)),
        );

        // Update both ref and state if width changed
        if (targetGridUnits !== resizeRef.current.currentWidth) {
          resizeRef.current.currentWidth = targetGridUnits;
          setCurrentWidth(targetGridUnits);
        }
      } else {
        const deltaY = e.pageY - resizeRef.current.startY;
        // Calculate the target height in pixels using the same scale as width
        const currentHeightPx = extractPixelValue(
          resizeRef.current.startHeight,
        );
        const targetHeightPx = currentHeightPx + deltaY;

        // Convert to grid units and ensure it stays within bounds (1-3 for height, same as width)
        const targetGridUnits = Math.max(
          1,
          Math.min(3, Math.round(targetHeightPx / 315)),
        );

        // Update both ref and state if height changed
        if (targetGridUnits !== resizeRef.current.currentHeight) {
          resizeRef.current.currentHeight = targetGridUnits;
          setCurrentHeight(targetGridUnits);
        }
      }
    };

    const handleResizeEnd = () => {
      const finalWidth = resizeRef.current.currentWidth;
      const finalHeight = resizeRef.current.currentHeight;
      const direction = resizeRef.current.direction;

      // Only update if the dimensions actually changed
      if (
        (direction === "horizontal" && finalWidth !== viewInsight.gridWidth) ||
        (direction === "vertical" && finalHeight !== viewInsight.gridHeight)
      ) {
        const updatePayload = {
          id: viewInsight.id,
          appId: viewInsight.appId,
          ...(direction === "horizontal"
            ? { gridWidth: finalWidth }
            : { gridHeight: finalHeight }),
        };

        updateViewInsight(updatePayload)
          .unwrap()
          .then(() => {
            toast.success(
              direction === "horizontal" ? "Width updated" : "Height updated",
            );
          })
          .catch((error) => {
            console.error("Resize error:", error, updatePayload);
            toast.error(`Failed to resize insight`);
            if (direction === "horizontal") {
              const resetWidth = viewInsight.gridWidth || 1;
              resizeRef.current.currentWidth = resetWidth;
              setCurrentWidth(resetWidth);
            } else {
              const resetHeight = viewInsight.gridHeight || 1;
              resizeRef.current.currentHeight = resetHeight;
              setCurrentHeight(resetHeight);
            }
          });
      }

      resizeRef.current.isResizing = false;
      setIsResizing(false);
      window.removeEventListener("mousemove", handleResizeMove);
      window.removeEventListener("mouseup", handleResizeEnd);
    };

    window.addEventListener("mousemove", handleResizeMove);
    window.addEventListener("mouseup", handleResizeEnd);
  };

  const displayWidth =
    isResizing && resizeDirection === "horizontal"
      ? resizeRef.current.currentWidth
      : currentWidth;

  const displayHeight =
    isResizing && resizeDirection === "vertical"
      ? resizeRef.current.currentHeight
      : currentHeight;

  const style = {
    transform: transform
      ? CSS.Transform.toString({
          x: transform.x,
          y: transform.y,
          scaleX: 1,
          scaleY: 1,
        } as Transform)
      : "",
    width: GRID_TO_PX[displayWidth],
    maxWidth: GRID_TO_PX[displayWidth],
    minWidth: GRID_TO_PX[displayWidth],
    height: isAudienceMeasure ? GRID_TO_PX[displayHeight] : "100%",
    maxHeight: isAudienceMeasure ? GRID_TO_PX[displayHeight] : "100%",
    minHeight: isAudienceMeasure ? GRID_TO_PX[displayHeight] : "100%",
    zIndex: isDragging || isResizing ? 999 : "auto",
    position: "relative" as const,
    cursor: isResizing
      ? resizeDirection === "horizontal"
        ? "se-resize"
        : "ns-resize"
      : "auto",
    opacity: isDragging ? 0.5 : 1,
  } as const;

  return (
    <GridItem
      ref={setNodeRef}
      style={style}
      colSpan={displayWidth}
      {...attributes}
      className={cn(
        "group relative transition-all duration-75",
        isResizing && "select-none",
        isDragging && "z-50",
      )}
    >
      {isResizing && (
        <GridOverlay
          targetWidth={
            resizeDirection === "horizontal" ? displayWidth : undefined
          }
          targetHeight={
            resizeDirection === "vertical" ? displayHeight : undefined
          }
          direction={resizeDirection}
        />
      )}
      <div
        {...listeners}
        className={cn(
          "absolute -top-1.5 left-[48%] flex h-4 w-6 cursor-grab items-center justify-center rounded-md border border-gray-200 bg-white p-0.5 opacity-0 shadow-sm transition-all duration-200 hover:bg-gray-50 group-hover:opacity-100",
          isDragging && "top-1 cursor-grabbing bg-gray-100 opacity-0",
        )}
        style={{ zIndex: 10 }}
      >
        <div className="flex flex-col">
          <GripHorizontal className="h-4 w-4 text-gray-600" />
        </div>
      </div>
      {!isAudienceMeasure && (
        <ResizeHandle
          onResizeStart={(e) => handleResizeStart(e, "horizontal")}
          isDragging={isDragging}
          direction="horizontal"
        />
      )}
      {isAudienceMeasure && (
        <ResizeHandle
          onResizeStart={(e) => handleResizeStart(e, "vertical")}
          isDragging={isDragging}
          direction="vertical"
        />
      )}
      <div style={{ width: "100%", height: "100%" }}>
        <ContextMenu>
          <ContextMenuTrigger>
            <ViewInsight
              view={view}
              location={location}
              viewInsight={viewInsight}
              isBlurred={
                shouldBePaywalled &&
                paywallInsights &&
                !viewInsight.isAutoGenerated
              }
              query={viewInsight.query}
              isAI={Boolean(viewInsight.queryId)}
              isDragging={isDragging}
              gridWidth={displayWidth}
            />
          </ContextMenuTrigger>
          <ContextMenuContent>
            <ContextMenuItem className="text-xs" onClick={handleDelete}>
              <div className="flex items-center gap-2">
                <TrashIcon className="h-3 w-3 text-gray-600" />
                Delete
              </div>
            </ContextMenuItem>
          </ContextMenuContent>
        </ContextMenu>
      </div>
      <Suspense fallback={<div />}>
        {viewInsight.audience ? (
          <AudienceModalContainer
            onClose={clearOpenedViewInsight}
            viewInsight={viewInsight}
            isOpen={openedInsight?.viewInsight?.id === viewInsight.id}
          />
        ) : viewInsight.query ? (
          <SQLEditorContainer
            isOpen={openedInsight?.viewInsight?.id === viewInsight.id}
            onClose={clearOpenedViewInsight}
            viewInsight={viewInsight}
            onRefetch={onRefetch}
          />
        ) : (
          openedInsight?.viewInsight?.id === viewInsight.id && (
            <InsightBuilderContainer
              isOpen={openedInsight.viewInsight?.id === viewInsight.id}
              onClose={clearOpenedViewInsight}
              viewId={viewInsight.viewId}
              viewInsight={viewInsight}
              onRefetch={onRefetch}
            />
          )
        )}
      </Suspense>
    </GridItem>
  );
};
