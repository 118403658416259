import { TraitOption as TraitOptionItem } from "core/components/Traits/TraitOption";
import { CommandOption } from "core/design-system/components/Command";
import { ITraitKeyResponse } from "core/models/traits";

interface ITraitMenu {
  onClick: () => void;
  trait: ITraitKeyResponse;
  testId: string;
  disabled?: boolean;
}

export const TraitOption: React.FC<ITraitMenu> = ({
  onClick,
  trait,
  testId,
  disabled,
}) => {
  return (
    <CommandOption
      className="w-full"
      testId={testId}
      value={onClick}
      disabled={disabled}
    >
      <div className="flex w-full justify-between">
        <TraitOptionItem trait={trait} isDisabled={disabled} />
      </div>
    </CommandOption>
  );
};
