import { Divider, Tooltip } from "@chakra-ui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";

import { useAppObjects } from "@/core/hooks/useAppObjects";
import { ObjectTraitText } from "@/core/modules/audience/Filters/ObjectTraitText";
import { IAppObject } from "@/core/types/AppObject";
import { TRAIT_LEVEL_TO_SLUG } from "@/modules/ComputedTrait/Level";
import { FilterTypeToTraitLevel } from "core/constants/report-setup";
import { ITraitKeyResponse, useGetTraitQuery } from "core/models/traits";
import { Comparison } from "core/modules/audience/Filters/TraitFilter/Comparison";
import { Icon } from "core/modules/audience/Filters/TraitFilter/Icon";
import { Trait } from "core/modules/audience/Filters/TraitFilter/Trait";
import { Value } from "core/modules/audience/Filters/TraitFilter/Value";
import { ComparisonType, IFilter } from "core/types/Filters.d";
import { cx } from "helpers/cx";

interface ITraitValueEditorProps {
  filter: IFilter;
  traitValue: string | string[];
  setTraitValue: (value: string | string[]) => void;
  setFilter: (filter: IFilter) => void;
  onSaveFilter: (filter: IFilter) => void;
  onRemoveFilter: (filter: IFilter) => void;
  onSelectTrait: (trait: ITraitKeyResponse) => void;
  onChangeComparison: (comparison: ComparisonType) => void;
  onChangeTraitValue: ({
    value,
    saveFilter,
  }: {
    value: string;
    saveFilter?: boolean;
  }) => void;
  isLast: boolean;
  isDisabled?: boolean;
  disabledTooltipText?: string;
  truncate?: boolean;
}

export const TraitValueEditor: React.FC<ITraitValueEditorProps> = ({
  filter,
  setFilter,
  traitValue,
  setTraitValue,
  onSaveFilter,
  onRemoveFilter,
  onChangeComparison,
  onSelectTrait,
  onChangeTraitValue,
  isDisabled = false,
  disabledTooltipText,
  isLast,
  truncate,
}) => {
  const { appId } = useParams();
  const { trait } = filter.body;
  const comparisonType = filter.body.comparisonType || 0;
  const level = FilterTypeToTraitLevel[filter.type];
  const { data: traitData } = useGetTraitQuery({
    appId: Number(appId),
    trait: trait as string,
    level,
  });

  const { appObjects } = useAppObjects();

  return (
    <Tooltip
      label={isDisabled ? disabledTooltipText : ""}
      shouldWrapChildren
      hasArrow
    >
      <div
        className={cx(
          "flex w-full items-center gap-0.5 px-2 py-1",
          isDisabled && "opacity-30",
          isLast && "rounded-lg",
        )}
      >
        {appObjects &&
        appObjects.find(
          (appObject: IAppObject) =>
            TRAIT_LEVEL_TO_SLUG[appObject.slug] === level,
        ) ? (
          <ObjectTraitText
            object={
              appObjects.find(
                (appObject) => TRAIT_LEVEL_TO_SLUG[appObject.slug] === level,
              )!
            }
          />
        ) : (
          <div>
            <Icon filterType={filter.type} />
          </div>
        )}
        <div>
          <Trait trait={trait || ""} onSelectTrait={onSelectTrait} />
        </div>
        <Divider colorScheme="red" orientation="vertical" />
        <div>
          <Comparison
            comparisonType={comparisonType}
            onSelectComparisonType={onChangeComparison}
            trait={traitData}
          />
        </div>
        {![ComparisonType.HAS_ANY_VALUE, ComparisonType.IS_UNKNOWN].includes(
          comparisonType,
        ) && <Divider colorScheme="red" orientation="vertical" />}
        <div>
          <Value
            value={traitValue}
            setValue={setTraitValue}
            comparisonType={comparisonType}
            filter={filter}
            setFilter={setFilter}
            onChangeTraitValue={onChangeTraitValue}
            onSaveFilter={onSaveFilter}
            truncate={truncate}
            dataType={traitData?.dataType}
          />
        </div>
        <Tooltip label="Remove filter" placement="top" hasArrow>
          <div
            className="cursor-pointer px-0.5 py-1 text-gray-500 hover:text-gray-800"
            onClick={() => onRemoveFilter(filter)}
            data-testid="audience-remove-trait-button"
          >
            <XMarkIcon className="h-4" />
          </div>
        </Tooltip>
      </div>
    </Tooltip>
  );
};
