import { CopyIcon, PencilIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "Components/ui/context-menu";
import { Input } from "Components/ui/input";
import { Separator } from "Components/ui/separator";
import { ITrait, useUpdateTraitMutation } from "core/models/objects/traits";
import { DataTypeString } from "core/models/traits";
import { getInputType } from "core/modules/audience/Filters/TraitFilter/ValueInput";
import { cn } from "lib/utils";
import { FormattedValue } from "modules/Object/Sections/Traits/FormattedValue";

export const Value: React.FC<{
  trait: ITrait;
  className?: string;
}> = ({ trait, className }) => {
  const { appId, appObjectId, objectId } = useParams();
  const [value, setValue] = useState(trait.value);
  const [isEditing, setIsEditing] = useState(false);
  const [updateTrait] = useUpdateTraitMutation();
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);
  const type = getInputType(trait.trait?.dataType ?? DataTypeString.STRING);

  const isEditable =
    !trait?.trait?.isComputed &&
    trait?.trait?.isEditable &&
    trait?.trait?.dataType !== DataTypeString.WORKSPACE_MEMBER &&
    trait?.trait?.dataType !== DataTypeString.BOOLEAN;

  function onUpdateTrait(val: string) {
    if (val === trait.value) {
      setIsEditing(false);
      return;
    }

    return updateTrait({
      appId: Number(appId),
      appObjectId: Number(appObjectId),
      objectId: String(objectId),
      trait: trait.name,
      value: val,
    })
      .unwrap()
      .then(() => {
        setIsEditing(false);
        toast.success("Trait updated", {
          description: "The trait has been successfully updated",
        });
      })
      .catch(() => {
        setValue(trait.value);
        setIsEditing(false);
        toast.error("Error updating trait");
      });
  }

  useEffect(() => {
    setValue(trait.value);
  }, [trait.value]);

  useEffect(() => {
    if (isEditing && inputRef.current && hiddenSpanRef.current) {
      inputRef.current.focus();
      inputRef.current.style.width = `${hiddenSpanRef.current.offsetWidth + 8}px`;

      if (type === "date") {
        inputRef.current.showPicker?.();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, value]);

  if (!isEditing) {
    return (
      <div
        className={cn(
          "flex items-center gap-1 overflow-hidden px-1",
          !trait?.trait?.isComputed && "rounded-md hover:bg-gray-100",
          className,
        )}
        onClick={() => isEditable && setIsEditing(true)}
      >
        <ContextMenu>
          <ContextMenuTrigger>
            <FormattedValue
              trait={trait}
              value={value}
              onUpdateTrait={onUpdateTrait}
            />
          </ContextMenuTrigger>
          <ContextMenuContent>
            <ContextMenuItem
              className="flex w-full cursor-pointer items-center justify-between gap-1 text-xs"
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(value);
              }}
            >
              <CopyIcon className="size-3" />
              Copy
            </ContextMenuItem>
            <Separator className="my-1 bg-gray-100" />
            <ContextMenuItem
              className="flex w-full cursor-pointer items-center justify-between gap-1 text-xs"
              disabled={
                trait?.trait?.isComputed ||
                trait?.trait?.dataType === DataTypeString.WORKSPACE_MEMBER
              }
              onClick={(e) => {
                e.preventDefault();
                setIsEditing(true);
              }}
            >
              <PencilIcon className="size-3" />
              Edit
            </ContextMenuItem>
          </ContextMenuContent>
        </ContextMenu>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-1 truncate py-[1px]">
      <p className="flex-shrink-0">{trait.trait?.prefix}</p>
      <span
        ref={hiddenSpanRef}
        className="invisible absolute whitespace-pre"
        aria-hidden="true"
      >
        {value}
      </span>
      <Input
        ref={inputRef}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => onUpdateTrait(value)}
        className="flex h-full px-0 py-0 pr-1 text-right text-sm ring-1 ring-purple-200 focus:border-none focus:ring-2"
        type={type}
      />
      <p className="flex-shrink-0">{trait.trait?.suffix}</p>
    </div>
  );
};
