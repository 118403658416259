import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, Flex, Tooltip } from "@chakra-ui/react";

import { DataTable } from "core/components/AI/Graph/DataTable";
import { CustomTooltip } from "core/components/AI/Graph/Tooltip";
import { Area, AreaChart } from "core/design-system/charts/AreaChart";
import {
  BarChart,
  BarItem,
  BarTooltip,
} from "core/design-system/charts/BarChart";
import {
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { IViewInsight } from "core/models/viewInsights";
import { IQuery } from "core/types/Query";
import { formatValue } from "lib/format";

export interface IResultColumn {
  name: string;
  type: string;
}

interface IData {
  [key: string]: string | number;
}

export function formatData(data: IData[], relative: boolean = false) {
  return data?.map((obj: any) => {
    const entries = Object.entries(obj);
    return Object.fromEntries(
      entries.map(([k, v]) => {
        const value = v as string;
        const _value = formatValue(value, relative);
        return [k, _value];
      }),
    );
  });
}

export const Graph: React.FC<{
  result: {
    columns: { name: string; type: string }[];
    data: { [key: string]: string }[];
  };
  query?: IQuery;
  yKey: string;
  xKey: string;
  page?: number;
  setPage?: (page: number) => void;
  hasMore?: boolean;
  color?: IColorPalette;
  viewInsight?: IViewInsight;
}> = ({
  result,
  query,
  yKey,
  xKey,
  page,
  setPage,
  hasMore,
  color = colors.purple,
}) => {
  if (!result) return null;

  const data = formatData(result.data, query?.graphType === "list");

  if (query?.graphType === "list")
    return (
      <DataTable
        data={data}
        columns={result?.columns}
        page={page}
        setPage={setPage}
        hasMore={hasMore}
      />
    );

  if (query?.graphType === "bar_chart") {
    return (
      <Flex h="full" w="full" position="relative">
        <BarChart
          margin={{ top: 10, left: -20, right: 20, bottom: 10 }}
          data={data}
        >
          <BarTooltip
            content={<CustomTooltip yDataKey={yKey} xDataKey={xKey} />}
          />
          {/*@ts-ignore*/}
          <CommonXAxis dataKey={xKey} />
          {/*@ts-ignore*/}
          <CommonYAxis />
          <CommonGrid />
          <BarItem barSize={40} fill={color[400]} dataKey={yKey} />
        </BarChart>
        {hasMore && (
          <Tooltip label="Show more data" placement="right">
            <Button
              size="xs"
              colorScheme="purple"
              variant="ghost"
              position="absolute"
              right="10px"
              top="110px"
              bg="purple.50"
              onClick={() => setPage && setPage((page || 1) + 1)}
            >
              <ArrowForwardIcon />
            </Button>
          </Tooltip>
        )}
      </Flex>
    );
  }

  if (query?.graphType === "area_chart") {
    return (
      <Flex h="full" w="full" position="relative">
        <AreaChart
          margin={{ top: 10, left: -20, right: 10, bottom: 0 }}
          height="100%"
          data={data}
        >
          <CommonGrid />
          {/* @ts-ignore */}
          <CommonXAxis dataKey={xKey} />
          {/* @ts-ignore */}
          <CommonYAxis />
          {/* @ts-ignore */}
          <CommonTooltip
            content={<CustomTooltip yDataKey={yKey} xDataKey={xKey} />}
          />
          <Area dataKey={yKey} />
        </AreaChart>
        {hasMore && (
          <Tooltip label="Show more data" placement="right">
            <Button
              size="xs"
              colorScheme="purple"
              variant="ghost"
              position="absolute"
              right="0px"
              top="110px"
              bg="purple.50"
              onClick={() => setPage && setPage((page || 1) + 1)}
            >
              <ArrowForwardIcon />
            </Button>
          </Tooltip>
        )}
      </Flex>
    );
  }

  return null;
};
