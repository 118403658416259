import {
  IGetAudiencesResponse,
  useGetAudiencesQuery,
} from "core/models/audiences";

interface IUseAudiences {
  appId: number;
  query?: string;
}
interface IUseAudiencesReturn {
  audiences: IGetAudiencesResponse | undefined;
  isLoading: boolean;
}

export const useAudiences = ({
  appId,
  query,
}: IUseAudiences): IUseAudiencesReturn => {
  const { data, isLoading } = useGetAudiencesQuery({ appId, query });

  return { audiences: data, isLoading };
};
