import {
  AdjustmentsVerticalIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbPage,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbSeparator,
  BreadcrumbLink,
} from "Components/ui/breadcrumb";
import { Button } from "Components/ui/button";
import { Shareable } from "core/components/Shareable";
import { FavouriteObjectType } from "core/models/favourites";
import { IView } from "core/models/views";
import { FavouritesMenuItem } from "modules/Company/FavouritesMenuItem";
import { AddMenu } from "modules/Views/AddMenu";
import { ViewMenu } from "modules/Views/Menu";

export const DashboardNav: React.FC<{
  view: IView;
  showFilters: boolean;
  setShowFilters: (show: boolean) => void;
  showAudienceModal: boolean;
  setShowAudienceModal: (show: boolean) => void;
}> = ({
  view,
  setShowFilters,
  showFilters,
  showAudienceModal,
  setShowAudienceModal,
}) => {
  const { appId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <div className="ml-1 w-full">
        <Breadcrumb>
          <BreadcrumbList className="flex items-center gap-1">
            <BreadcrumbItem>
              <BreadcrumbLink
                onClick={() => navigate(`/a/${appId}/dashboards`)}
                className="cursor-pointer text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                Dashboards
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="text-gray-500" />
            <BreadcrumbItem>
              <BreadcrumbPage className="flex items-center gap-1">
                {view?.emoji}
                <p className="text-sm font-medium text-gray-500">
                  {view?.name}
                </p>
                <FavouritesMenuItem
                  menuItem={false}
                  objectType={FavouriteObjectType.View}
                  objectId={String(view?.id)}
                  appId={Number(appId)}
                />
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="flex items-center gap-1">
        <AddMenu
          onAddInsightClick={() => {
            navigate(`/a/${appId}/dashboard/${view?.id}/graph/new`);
          }}
          onAddSQLClick={() => {
            navigate(`/a/${appId}/dashboard/${view?.id}/sql/new`);
          }}
          onAddAudienceClick={() => {
            setShowAudienceModal(!showAudienceModal);
          }}
          size="sm"
        />
        {view && <Shareable shareable={view} shareableType="View" size="sm" />}
        <Button
          variant="ghost"
          onClick={() => setShowFilters(!showFilters)}
          size="sm"
        >
          <div className="flex items-center gap-1">
            <AdjustmentsVerticalIcon className="h-[17px] w-[17px]" />
            Filters
          </div>
        </Button>
        <Button
          variant="ghost"
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            window.Intercom("showArticle", "10743301");
          }}
        >
          <BookOpenIcon className="h-[17px] w-[17px] text-gray-900" />
          <span className="text-xs font-medium text-gray-900">Learn</span>
        </Button>
        {view && <ViewMenu view={view} />}
      </div>
    </>
  );
};
