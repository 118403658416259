import { Table, TableContainer, Tbody, Thead } from "@chakra-ui/react";

import { IList } from "core/components/List/List.types";
import { LoadingList } from "core/components/List/LoadingList";
import { Pagination } from "core/components/List/Pagination";
import { ListEmptyState } from "core/components/List/private/ListEmptyState";
import { ListHeading } from "core/components/List/private/ListHeading";
import { ListRow } from "core/components/List/private/ListRow";

export const List = <T extends any>(props: IList<T>) => {
  const {
    isLoading = false,
    rows,
    config,
    mutate,
    pagination,
    pagy,
    sortedBy,
    setSortedBy,
    canAddAdditionalColumns = false,
    hideAvatar,
    onListRowClick,
    onListRowMouseEnter,
    onListRowMouseLeave,
    tableTraitConfigs,
    createTraitConfig,
    deleteTraitConfig,
    traits,
    skipPagination,
    hideHeader,
    ...restProps
  } = props;

  if (isLoading) {
    return <LoadingList hideAvatar={hideAvatar} config={config} />;
  }

  return (
    <TableContainer maxW="full" overflowX="scroll" {...restProps}>
      <Table variant="simple">
        {!hideHeader && (
          <Thead>
            <ListHeading
              canAddAdditionalColumns={canAddAdditionalColumns}
              columns={config?.columns}
              sortedBy={sortedBy}
              setSortedBy={setSortedBy}
              isUserResource={config.resourceName === "users"}
              tableTraitConfigs={tableTraitConfigs}
              createTraitConfig={createTraitConfig}
              deleteTraitConfig={deleteTraitConfig}
              traits={traits}
            />
          </Thead>
        )}
        <Tbody>
          {rows?.map((row, index) => (
            <ListRow
              index={row?.uuid || index}
              key={index}
              mutate={mutate}
              row={row}
              pagy={pagy}
              columns={config?.columns}
              canAddAdditionalColumns={canAddAdditionalColumns}
              isUserResource={config.resourceName === "users"}
              onMouseEnter={onListRowMouseEnter}
              onMouseLeave={onListRowMouseLeave}
              onClick={onListRowClick}
              tableTraitConfigs={tableTraitConfigs}
              traits={traits}
              isLastRow={index === rows.length - 1}
            />
          ))}
        </Tbody>
      </Table>
      {rows?.length === 0 && (
        <ListEmptyState
          resourceName={config?.resourceName || ""}
          isEventResource={config?.resourceName === "events"}
          eventType={config?.eventType}
        />
      )}
      {pagination && pagy && (
        <Pagination
          pagination={pagination}
          pagy={pagy}
          resourceName={config?.resourceName || "objects"}
          skipPagination={skipPagination}
          rowCount={rows?.length}
        />
      )}
    </TableContainer>
  );
};
