import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";
import type { SnakeCasedProperties, SnakeCasedPropertiesDeep } from "type-fest";

import { ViewLocation } from "core/hooks/useViews";
import { baseQuery } from "core/initializers/rtk";
import { IViewInsight } from "core/models/viewInsights";
import { IAudience } from "core/types/Audience";
import { IPagy } from "core/types/Pagy";

export interface IView {
  id: number;
  appId: number;
  name: string;
  viewInsights: IViewInsight[];
  pinned: boolean;
  emoji?: string;
  updatedAt: string;
  createdAt: string;
  audience: IAudience;
  useInternalUsers: boolean;
  order: number;
  location: ViewLocation;
  hasPassword: boolean;
}

interface IGetViewsResponse {
  views: IView[];
  pagy: IPagy;
}

interface IViewToken {
  id: number;
  token: string;
  insightId: number;
  password?: string;
}

interface GetViewTokenArgs {
  appId: number;
  viewId: number;
  groupId?: string;
}

export const viewsApi = createApi({
  baseQuery,
  reducerPath: "viewsApi",
  tagTypes: ["Views", "ShareTokens"],
  endpoints: (builder) => ({
    getView: builder.query<
      IView,
      {
        id: number;
        appId: number;
        sharingSecretToken?: string | null;
        groupId?: string | null;
        order?: number;
      }
    >({
      query: ({ id, appId, sharingSecretToken = null, groupId = null }) => ({
        url: `/views/${id}`,
        params: humps.decamelizeKeys({ appId, sharingSecretToken, groupId }),
      }),
      providesTags: ["Views"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IView>): IView =>
        humps.camelizeKeys(response) as IView,
    }),
    getViews: builder.query<
      IGetViewsResponse,
      {
        appId: number;
        location: ViewLocation;
        pinned?: boolean;
        page?: number;
        query?: string;
        order?: number;
      }
    >({
      query: ({ appId, location, query, page = 1, pinned = true }) => ({
        url: `/views`,
        params: humps.decamelizeKeys({ appId, location, query, page, pinned }),
      }),
      providesTags: ["Views"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetViewsResponse>,
      ): IGetViewsResponse => humps.camelizeKeys(response) as IGetViewsResponse,
    }),
    addView: builder.mutation<
      IView,
      {
        appId: number;
        name?: string;
        template?: string;
        templateParams?: any;
        pin: boolean;
      }
    >({
      query: ({ appId, name, template, templateParams, pin }) => ({
        url: `/views`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          name,
          template,
          templateParams: JSON.stringify(templateParams),
          pin,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["Views"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IView>): IView =>
        humps.camelizeKeys(response) as IView,
    }),
    deleteView: builder.mutation<{}, { appId: number; id: number }>({
      query: ({ appId, id }) => ({
        url: `/views/${id}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId, id }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["Views"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IView[]>,
      ): IView[] => humps.camelizeKeys(response) as IView[],
    }),
    updateView: builder.mutation<
      {},
      {
        appId: number;
        id: number;
        order?: number;
        emoji?: string;
        name?: string;
        useInternalUsers?: boolean;
        audienceId?: number;
      }
    >({
      query: ({
        appId,
        id,
        order,
        emoji,
        name,
        useInternalUsers,
        audienceId,
      }) => ({
        url: `/views/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          appId,
          id,
          order,
          emoji,
          name,
          useInternalUsers,
          audienceId,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["Views"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IView[]>,
      ): IView[] => humps.camelizeKeys(response) as IView[],
    }),
    duplicateView: builder.mutation<IView, { appId: number; id: number }>({
      query: ({ appId, id }) => ({
        url: `/views/${id}/duplicate`,
        method: "POST",
        params: humps.decamelizeKeys({ appId, id }),
      }),
      invalidatesTags: ["Views"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IView>): IView =>
        humps.camelizeKeys(response) as IView,
    }),
    shareView: builder.mutation<
      void,
      { appId: number; viewId: number; groupId?: string }
    >({
      query: ({ appId, viewId, groupId }) => ({
        url: `/views/${viewId}/share`,
        method: "PUT",
        body: humps.decamelizeKeys({
          appId,
          id: viewId,
          groupId,
        }),
      }),
      invalidatesTags: ["ShareTokens"],
    }),
    unshareView: builder.mutation<
      void,
      { appId: number; viewId: number; groupId?: string }
    >({
      query: ({ appId, viewId, groupId }) => ({
        url: `/views/${viewId}/unshare`,
        method: "DELETE",
        body: humps.decamelizeKeys({
          appId,
          id: viewId,
          groupId,
        }),
      }),
      invalidatesTags: ["ShareTokens"],
    }),
    getViewToken: builder.query<IViewToken, GetViewTokenArgs>({
      query: ({ appId, viewId }) => ({
        url: `/views/${viewId}/token`,
        params: { app_id: appId, id: viewId },
      }),
      providesTags: ["ShareTokens"],
      transformResponse: (response: SnakeCasedProperties<IViewToken>) =>
        humps.camelizeKeys(response) as IViewToken,
    }),
    pinView: builder.mutation<
      void,
      { appId: number; viewId: number; pinned: boolean }
    >({
      query: ({ appId, viewId, pinned }) => ({
        url: `/views/${viewId}/pin`,
        method: "PUT",
        body: humps.decamelizeKeys({
          appId,
          id: viewId,
          pinned,
        }),
      }),
      invalidatesTags: ["Views"],
    }),
    batchUpdateOrder: builder.mutation<
      void,
      { appId: number; views: { id: number; order: number }[] }
    >({
      query: ({ appId, views }) => ({
        url: `/views/batch_update_order`,
        method: "PUT",
        body: humps.decamelizeKeys({ appId, views }),
      }),
    }),
    enablePassword: builder.mutation<void, { appId: number; viewId: number }>({
      query: ({ appId, viewId }) => ({
        url: `/views/${viewId}/enable_password`,
        method: "POST",
        body: humps.decamelizeKeys({ appId, viewId }),
      }),
      invalidatesTags: ["ShareTokens"],
    }),
    updatePassword: builder.mutation<
      void,
      { appId: number; viewId: number; password: string }
    >({
      query: ({ appId, viewId, password }) => ({
        url: `/views/${viewId}/update_password`,
        method: "PUT",
        body: humps.decamelizeKeys({ appId, viewId, password }),
      }),
      invalidatesTags: ["ShareTokens"],
    }),
    disablePassword: builder.mutation<void, { appId: number; viewId: number }>({
      query: ({ appId, viewId }) => ({
        url: `/views/${viewId}/disable_password`,
        method: "DELETE",
        body: humps.decamelizeKeys({ appId, viewId }),
      }),
      invalidatesTags: ["ShareTokens"],
    }),
    checkPassword: builder.mutation<
      void,
      { appId: number; viewId: number; password: string }
    >({
      query: ({ appId, viewId, password }) => ({
        url: `/views/${viewId}/check_password`,
        method: "POST",
        body: humps.decamelizeKeys({ appId, viewId, password }),
      }),
    }),
  }),
});

export const {
  useLazyGetViewQuery,
  useGetViewQuery,
  useGetViewsQuery,
  useAddViewMutation,
  useDeleteViewMutation,
  useUpdateViewMutation,
  usePinViewMutation,
  useShareViewMutation,
  useUnshareViewMutation,
  useGetViewTokenQuery,
  useDuplicateViewMutation,
  useBatchUpdateOrderMutation,
  useEnablePasswordMutation,
  useUpdatePasswordMutation,
  useDisablePasswordMutation,
  useCheckPasswordMutation,
} = viewsApi;
