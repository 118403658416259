import { useParams } from "react-router-dom";

import TickText from "@/core/design-system/charts/TickText";
import { thousandsToK } from "@/core/helpers/thousands";
import { simplifyXAxisTicks, yAxisWidth } from "@/helpers/axis";
import {
  BarChart,
  BarItem,
  BarRectangle,
} from "core/design-system/charts/BarChart";
import {
  CommonCustomTooltip,
  CommonGrid,
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { Size } from "core/types/ViewInsight";

const MOCK_DATA = [
  {
    x: "2024-01-01",
    y: 100,
  },
  {
    x: "2024-01-02",
    y: 200,
  },
  {
    x: "2024-01-03",
    y: 300,
  },
  {
    x: "2024-01-04",
    y: 600,
  },
  {
    x: "2024-01-05",
    y: 1200,
  },
  {
    x: "2024-01-06",
    y: 2400,
  },
  {
    x: "2024-01-07",
    y: 4800,
  },
];

export const SimpleBarChart: React.FC<{
  data: any[];
  xDataKey?: string;
  yDataKey?: string;
  margin?: { right: number; top: number; bottom: number; left: number };
  color?: IColorPalette;
  size?: Size;
  useMockData?: boolean;
  slug?: string;
  isClickable?: boolean;
  onClickDataPoint?: (payload: { x: string; y: string }) => void;
  showAxis?: boolean;
}> = ({
  data,
  xDataKey = "x",
  yDataKey = "y",
  margin = { right: 5, top: 5, bottom: 0, left: 0 },
  color = colors.purple,
  size = Size.Small,
  useMockData = false,
  slug,
  isClickable = false,
  onClickDataPoint,
  showAxis = true,
}) => {
  const chartData = useMockData ? MOCK_DATA : data;
  const { sharingSecretToken } = useParams();

  const { firstTick, lastTick } = simplifyXAxisTicks(chartData, "x");
  const { lastTick: lastYTick } = simplifyXAxisTicks(chartData, "y");
  const showTicks = [Size.Medium, Size.Large].includes(size);
  const allTicks = data?.map((item) => item[xDataKey]);

  const yTickWidth = yAxisWidth(lastYTick);

  return (
    <BarChart
      showYAxis={showAxis}
      showXAxis={showAxis}
      margin={margin}
      data={chartData}
      id="loaded"
    >
      <CommonGrid horizontal={true} />
      {/* @ts-ignore */}
      <CommonTooltip content={<CommonCustomTooltip yAxisLabelAffix={slug} />} />
      <BarItem
        fill={color[300]}
        dataKey={yDataKey}
        background={{ fill: "transparent" }}
        minPointSize={2}
        shape={<BarRectangle radius={[3, 3, 3, 3]} po />}
        cursor={isClickable ? "pointer" : "auto"}
        onClick={
          !sharingSecretToken && isClickable
            ? (dataPoint, barIndex, event) => {
                event.preventDefault();
                event.stopPropagation();

                onClickDataPoint?.(dataPoint?.payload);
              }
            : undefined
        }
      />
      {showAxis && (
        /* @ts-ignore */
        <CommonXAxis
          ticks={showTicks ? allTicks : [firstTick, lastTick]}
          dataKey={xDataKey}
        />
      )}
      {showAxis && (
        /* @ts-ignore */
        <CommonYAxis
          dataKey={yDataKey}
          width={yTickWidth}
          tick={({ x, y, ...props }: any) => {
            const { value } = props?.payload;
            return (
              <TickText {...props} y={y} x={x - 2}>
                {thousandsToK(value)}
              </TickText>
            );
          }}
        />
      )}
    </BarChart>
  );
};
