import moment from "moment";

import {
  TableHead,
  TableHeader,
  TableRow,
  TableBody,
  TableCell,
  Table,
} from "@/Components/ui/table";
import { IView } from "@/core/models/views";
import { DeleteAction } from "@/modules/Dashboard/List/Actions/DeleteAction";
import { FavoriteAction } from "@/modules/Dashboard/List/Actions/FavoriteAction";
import { PinAction } from "@/modules/Dashboard/List/Actions/PinAction";
import { DuplicateAction } from "@/modules/Dashboard/List/DuplicateAction";
import { LoadingRow } from "@/modules/Dashboard/List/LoadingRow";
import { NameCell } from "@/modules/Dashboard/List/NameCell";

const HoverActions = ({ view }: { view: IView }) => {
  return (
    <div className="inline-flex flex-row items-center gap-1 rounded-md border border-gray-300 bg-white px-2 py-1 opacity-0 shadow-sm transition-opacity group-hover:opacity-100">
      <PinAction view={view} />
      <FavoriteAction view={view} />
      <DuplicateAction view={view} />
      <DeleteAction view={view} />
    </div>
  );
};

export const TableContainer = ({
  isLoading,
  views,
}: {
  isLoading: boolean;
  views: IView[];
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[70%]">Dashboard name</TableHead>
          <TableHead className="w-[30%]">Last updated</TableHead>
          <TableHead className="w-[5%]"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isLoading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <LoadingRow key={index} />
          ))
        ) : views.length > 0 ? (
          views.map((view) => (
            <TableRow key={view.id} className="group">
              <TableCell>
                <NameCell props={view} />
              </TableCell>
              <TableCell>
                <div className="text-sm font-medium text-gray-500">
                  Last updated {moment(view.updatedAt).fromNow()}
                </div>
              </TableCell>
              <TableCell className="text-right">
                <HoverActions view={view} />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={3} className="h-24 text-center">
              No dashboards found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
