import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { BorderBeam } from "@/Components/magicui/border-beam";
import { Button } from "@/Components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/Components/ui/card";
import { Input } from "@/Components/ui/input";
import { CompanyJuneFullLogo } from "@/core/design-system/components/Icon/Logos/CompanyJuneFullLogo";
import { ErrorPage } from "Components/Error/Page";
import { Loader } from "Components/Loader";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useCheckPasswordMutation, useGetViewQuery } from "core/models/views";
import { PublicView } from "core/modules/reports/PublicView";

const PublicViewRoute = () => {
  const { appId, viewId, sharingSecretToken, objectId } = useParams<{
    [key: string]: string;
  }>() as {
    appId: string;
    viewId: string;
    sharingSecretToken: string;
    objectId: string;
  };
  const groupIdQueryParam = useQueryParam("groupId");
  const groupId = groupIdQueryParam ? groupIdQueryParam : objectId;
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [checkPassword] = useCheckPasswordMutation();
  const {
    data: view,
    isLoading,
    isFetching,
    error,
  } = useGetViewQuery({
    appId: Number(appId),
    id: Number(viewId),
    sharingSecretToken,
    groupId,
  });

  function handleSubmitPassword() {
    checkPassword({ appId: Number(appId), viewId: Number(viewId), password })
      .unwrap()
      .then((res) => {
        setIsPasswordValid(true);
      })
      .catch(() => {
        setIsPasswordValid(false);
        toast.error("Invalid password");
      });
  }

  if (isLoading || isFetching) return <Loader />;
  if (error) {
    return (
      <ErrorPage
        title="You don't have permissions to view this dashboard"
        action={{
          text: "Log in",
          href: `${import.meta.env.VITE_APP_HOST}/login`,
        }}
      />
    );
  }

  if (view?.hasPassword && !isPasswordValid) {
    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center">
        <Card className="relative w-[450px] overflow-hidden shadow-sm">
          <CardHeader className="flex flex-col gap-y-0 space-y-0 py-0">
            <div className="flex justify-center">
              <CompanyJuneFullLogo className="h-20 w-20" />
            </div>
          </CardHeader>
          <CardContent>
            <p className="my-2 text-sm text-gray-500">
              Please enter the password to view the dashboard
            </p>
            <Input
              className="relative w-full"
              size="sm"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </CardContent>
          <CardFooter>
            <div className="flex w-full justify-end">
              <Button className="self-end" onClick={handleSubmitPassword}>
                Submit
              </Button>
            </div>
          </CardFooter>
          <BorderBeam
            duration={8}
            size={200}
            className="from-transparent via-purple-500 to-transparent"
          />
        </Card>
      </div>
    );
  }

  if (view) {
    return (
      <PublicView
        appId={Number(appId)}
        view={view}
        sharingSecretToken={sharingSecretToken}
      />
    );
  }

  return <ErrorPage />;
};

export default PublicViewRoute;
