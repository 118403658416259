import { Center, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useAppObjects } from "core/hooks/useAppObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useListConfig } from "core/hooks/useListConfig";
import { useGetAssociationListQuery } from "core/models/objects/associations";
import { IAudience, JoinOperatorValue } from "core/types/Audience";
import { EMPTY_FILTER_GROUPS } from "helpers/filters";
import { ObjectList } from "modules/People/ObjectList";

export const ObjectUsersList = ({
  useInternalUsers,
}: {
  useInternalUsers: boolean;
}) => {
  const { appId, appObjectId, objectId } = useParams();
  const { userAppObject } = useAppObjects();

  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("Last seen");
  const [sortOrder, setSortOrder] = useState("DESC");
  const { audience: internalUsersAudience } = useCurrentApp();

  const audience = useInternalUsers
    ? internalUsersAudience
    : (EMPTY_FILTER_GROUPS as unknown as IAudience);

  const { data, isLoading: isLoadingAssociations } = useGetAssociationListQuery(
    {
      appId: Number(appId),
      appObjectId: String(appObjectId),
      objectId: String(objectId),
      page,
      sortBy,
      sortOrder,
      filterGroups: audience.filterGroups,
      joinOperator: audience.joinOperator as JoinOperatorValue,
    },
  );

  const { listConfig } = useListConfig(userAppObject?.objectType);

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (data?.pagy && page < data.pagy.last) {
      setPage(page + 1);
    }
  };

  if (isLoadingAssociations || !userAppObject) {
    return (
      <Center h="100vh" minH="full">
        <Spinner />
      </Center>
    );
  }

  return (
    <ObjectList
      appObject={userAppObject}
      listConfig={listConfig}
      searchQuery={""}
      setSearchQuery={() => {}}
      entities={data?.contacts || []}
      isLoading={isLoadingAssociations}
      pagy={data?.pagy}
      currentPage={page}
      previousPage={previousPage}
      nextPage={nextPage}
      audienceFilters={EMPTY_FILTER_GROUPS}
      showSearchBar={false}
      showAddTraitButton={false}
      showExportCsvButton={false}
      skipPagination={false}
      sortBy={sortBy}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      setSortBy={setSortBy}
      totalCount={data?.pagy?.count || 0}
      matchedCount={data?.pagy?.count || 0}
      marginTop="mt-0"
    />
  );
};
