import humps from "humps";
import { useCallback, useState } from "react";
import { toast } from "sonner";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useMountedState } from "core/hooks/useMountedState";
import axios from "core/initializers/axios";
import { AppObjectType } from "core/types/AppObject";
import { IAudienceFilters } from "core/types/Audience";
import { JoinOperatorValue } from "core/types/Filters.d";
import { transformFilterGroups } from "helpers/params";

interface IUseObjectCsvExport {
  audienceFilters?: IAudienceFilters;
  groupId?: string;
  objectType: AppObjectType;
}

export const useObjectCsvExport = ({
  audienceFilters,
  groupId,
  objectType,
}: IUseObjectCsvExport) => {
  const { id: appId } = useCurrentApp();
  const isMounted = useMountedState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const requestCsvExport = useCallback(() => {
    if (appId) {
      setError(undefined);
      setLoading(true);
      axios
        .post(
          "/object_csv_exports",
          humps.decamelizeKeys({
            appId,
            objectType,
            filterGroups: JSON.stringify(
              transformFilterGroups(audienceFilters?.filterGroups || []),
            ),
            joinOperator:
              audienceFilters?.joinOperator || JoinOperatorValue.AND,
            groupId,
          }),
        )
        .then((response) => {
          if (isMounted()) {
            const title = "Export list";
            const description = `You will shortly receive an email with a CSV with your data`;
            toast.success(title, {
              description,
            });
          }
        })
        .catch((err) => {
          const title = "Export failed";
          const description = err?.response?.data?.error;
          const error = `${title}: ${description}`;
          setError(error);
          toast.error(title, {
            description,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isMounted, appId, audienceFilters, groupId, objectType]);

  return {
    requestCsvExport,
    csvExportLoading: loading,
    csvExportError: error,
  };
};
