import { ComponentDefaultProps } from "@chakra-ui/react";

import { CardContent } from "@/Components/ui/card";
import { IViewInsight } from "@/core/models/viewInsights";
import { cn } from "@/lib/utils";
import { Loader } from "core/components/ViewInsight/Loader";
import { Measure } from "core/types/ViewInsight";

export interface IBodyProps extends ComponentDefaultProps {
  viewInsight: IViewInsight;
  isLoading?: boolean;
}

export const Body: React.FC<IBodyProps> = ({
  children,
  isLoading,
  viewInsight,
  ...props
}) => {
  if (isLoading) return <Loader viewInsight={viewInsight} {...props} />;

  return (
    <CardContent
      data-testid="highlight-card-body"
      className={cn(
        "h-full max-h-full w-full max-w-full",
        viewInsight?.measure === Measure.Audience ? "p-0" : "p-3",
      )}
    >
      {children}
    </CardContent>
  );
};
