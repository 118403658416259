import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";

import { ContactAvatar } from "core/components/Avatars/ContactAvatar";
import { TooltipBody } from "core/components/TooltipBody";
import { getContactEmail } from "core/helpers/contactEmail";
import { getContactName } from "core/helpers/contactName";
import { IContact } from "core/types/Contact";

export interface ISlippingAwayContact {
  id: string;
  isIdentified: boolean;
  traits: IContact["traits"];
}

const ContactLink: React.FC<{
  props: ISlippingAwayContact;
  previewMode: boolean;
  sharingMode: boolean;
}> = ({ props, previewMode, sharingMode }) => {
  const { appId } = useParams();
  const { id, isIdentified } = props;
  const email = getContactEmail(props);
  const hasEmail = Boolean(email && email.length > 0);
  const name = getContactName(props);

  return (
    <Box>
      {previewMode || sharingMode ? (
        <Tooltip
          label={
            <TooltipBody text="User profiles are disabled in preview mode" />
          }
          placement="right"
          borderRadius="lg"
        >
          <Box>
            <Box pr={4}>
              <ContactAvatar size="sm" contact={{ id, traits: {} }} />
            </Box>
            <Text fontSize="sm" noOfLines={1}>
              {name}
            </Text>
            <Text fontSize="sm" color="gray.600" maxW="290px" noOfLines={1}>
              {hasEmail ? email : "No email"}
            </Text>
          </Box>
        </Tooltip>
      ) : (
        <Link to={`/a/${appId}/contact/${encodeURIComponent(id)}`}>
          <Flex align="center">
            <Box pr={4}>
              <ContactAvatar
                size="sm"
                contact={{ id, traits: {}, isIdentified }}
              />
            </Box>
            <Flex direction="column">
              <Text fontSize="sm" noOfLines={1}>
                {name}
              </Text>
              <Text fontSize="sm" color="gray.600" noOfLines={1}>
                {hasEmail ? email : "no email"}
              </Text>
            </Flex>
          </Flex>
        </Link>
      )}
    </Box>
  );
};

export default ContactLink;
