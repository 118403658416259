import {
  Pagination,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationContent,
} from "@/Components/ui/pagination";
import { IPagy } from "@/core/types/Pagy";

export const PaginationContainer = ({
  currentPage,
  previousPage,
  nextPage,
  pagy,
  setPage,
}: {
  currentPage: number;
  pagy: IPagy;
  previousPage: () => void;
  nextPage: () => void;
  setPage: (page: number) => void;
}) => {
  const generatePaginationItems = (currentPage: number, totalPages: number) => {
    const items = [];
    const maxVisible = 5;

    if (totalPages <= maxVisible) {
      for (let i = 1; i <= totalPages; i++) {
        items.push(i);
      }
    } else {
      items.push(1);

      if (currentPage > 3) {
        items.push("ellipsis");
      }

      for (
        let i = Math.max(2, currentPage - 1);
        i <= Math.min(currentPage + 1, totalPages - 1);
        i++
      ) {
        items.push(i);
      }

      if (currentPage < totalPages - 2) {
        items.push("ellipsis");
      }

      items.push(totalPages);
    }

    return items;
  };

  const hasPreviousPage = pagy.page > 1;
  const hasNextPage = pagy.page < pagy.last;

  return (
    <Pagination className="justify-end">
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            onClick={previousPage}
            className={
              !hasPreviousPage
                ? "pointer-events-none opacity-50"
                : "cursor-pointer"
            }
            aria-disabled={!hasPreviousPage}
          />
        </PaginationItem>

        {generatePaginationItems(currentPage, pagy.last).map((page, index) => (
          <PaginationItem key={`${page}-${index}`}>
            {page === "ellipsis" ? (
              <PaginationEllipsis />
            ) : (
              <PaginationLink
                onClick={() => {
                  if (typeof page === "number") {
                    setPage(page);
                  }
                }}
                isActive={currentPage === page}
                className={
                  currentPage === page
                    ? "pointer-events-none"
                    : "cursor-pointer"
                }
              >
                {page}
              </PaginationLink>
            )}
          </PaginationItem>
        ))}

        <PaginationItem>
          <PaginationNext
            onClick={nextPage}
            className={
              !hasNextPage ? "pointer-events-none opacity-50" : "cursor-pointer"
            }
            aria-disabled={!hasNextPage}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};
