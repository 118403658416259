import { useDisclosure } from "@chakra-ui/react";
import {
  ArrowDownTrayIcon,
  DocumentDuplicateIcon,
  ShareIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  ChartBarIcon,
  PaintBrushIcon,
  CubeTransparentIcon,
  CalendarDaysIcon,
  UserIcon,
  UsersIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/24/outline";
import { startCase } from "lodash";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

import { Button } from "@/Components/ui/button";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "@/Components/ui/dropdown-menu";
import { useAppObjects } from "@/core/hooks/useAppObjects";
import {
  COLOR_NAME_TO_PALETTE,
  GraphTypeToIcon,
  InsightTypeConfigs,
} from "@/core/hooks/useEditViewInsight";
import { usePaywall } from "@/core/hooks/usePaywall";
import { AppObjectType } from "@/core/types";
import { GraphType, Interval } from "@/core/types/ViewInsight";
import { cn } from "@/lib/utils";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { useSqlCsvExport } from "core/hooks/useSqlCsvExport";
import { IQueryResult } from "core/models/sql";
import {
  IViewInsight,
  useDeleteViewInsightMutation,
  useDuplicateViewInsightMutation,
  useUpdateViewInsightMutation,
} from "core/models/viewInsights";
import { Plan } from "core/types/App";
import { AlertMenuItem } from "modules/ViewInsight/Overflow/AlertMenuItem";
import { ShareModal } from "modules/ViewInsight/Share/ShareModal";

export const OverflowMenu: React.FC<{
  viewInsight: IViewInsight;
  result?: IQueryResult;
}> = ({ viewInsight, result = { columns: [], data: [] } }) => {
  const { groupId, objectId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [duplicate] = useDuplicateViewInsightMutation();
  const [deleteViewInsight] = useDeleteViewInsightMutation();
  const [updateInsight] = useUpdateViewInsightMutation();
  const isSQL = viewInsight.query !== undefined && viewInsight.query !== null;
  const appObject = viewInsight.appObject;
  const measure = viewInsight.measure;
  const { shouldBePaywalled } = usePaywall();
  const { userAppObject, groupAppObject, companyAppObject } = useAppObjects();

  const { data, headers } = useSqlCsvExport({ result });

  const shouldShowGraphType = (graphType: GraphType) => {
    return (
      measure &&
      Object.keys(InsightTypeConfigs[measure].graphTypes).includes(graphType) &&
      InsightTypeConfigs?.[measure]?.graphTypes?.[
        graphType
      ]?.appObjectTypes?.includes(appObject?.objectType || AppObjectType.User)
    );
  };

  const getGraphTypeDropdownItems = () => {
    return Object.values(GraphType)
      .filter((g) =>
        g === GraphType.ContributionGraph ? Boolean(groupId || objectId) : true,
      )
      .reverse()
      .map((graphType) => {
        if (shouldShowGraphType(graphType)) {
          return {
            icon: GraphTypeToIcon[graphType].outline,
            label: startCase(graphType.split(`_`).join(` `)),
            value: graphType,
          };
        }

        return null;
      })
      .filter((x) => !!x);
  };

  function handleObjectChange(objectType: AppObjectType) {
    updateInsight({
      id: viewInsight.id,
      appId: viewInsight.appId,
      objectType: objectType,
    });
  }

  function handleIntervalChange(interval: Interval) {
    updateInsight({
      id: viewInsight.id,
      appId: viewInsight.appId,
      interval: interval,
    });
  }

  function handleGraphTypeChange(graphType: GraphType) {
    updateInsight({
      id: viewInsight.id,
      appId: viewInsight.appId,
      graphType: graphType,
      interval: viewInsight.interval,
    });
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            data-testid="overflow-menu-button"
            variant="ghost"
            size="icon"
            className="mt-1"
          >
            <EllipsisVerticalIcon className="h-3 w-3 text-gray-500" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[160px]">
          <DropdownMenuItem
            className="text-xs"
            onClick={(e) => {
              e.stopPropagation();
              duplicate({
                id: viewInsight.id,
                appId: viewInsight.appId,
              });
            }}
          >
            <DocumentDuplicateIcon className="h-3 w-3 text-gray-600" />
            Duplicate
          </DropdownMenuItem>
          <DropdownMenuItem
            className="text-xs"
            onClick={(e) => {
              e.stopPropagation();
              deleteViewInsight({
                id: viewInsight.id,
                appId: viewInsight.appId,
              });
            }}
          >
            <TrashIcon className="h-3 w-3 text-gray-600" />
            Delete
          </DropdownMenuItem>
          {!viewInsight.content && (
            <DropdownMenuItem
              className="text-xs"
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
            >
              <ShareIcon className="h-3 w-3 text-gray-600" />
              Share
            </DropdownMenuItem>
          )}
          {isSQL && !viewInsight.content && (
            <CSVLink
              filename={
                viewInsight?.title?.split(" ").join(`_`).toLowerCase() || "data"
              }
              data={data}
              headers={headers}
              target="_blank"
            >
              <PaywallPopover
                feature="CSV downloads"
                redirect={`app/${viewInsight.appId}/home/${viewInsight.viewId}`}
                plan={Plan.Pro}
              >
                <DropdownMenuItem
                  className="text-xs"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ArrowDownTrayIcon className="h-3 w-3 text-gray-600" />
                  Download CSV
                </DropdownMenuItem>
              </PaywallPopover>
            </CSVLink>
          )}
          {!viewInsight.content && <AlertMenuItem viewInsight={viewInsight} />}
          {!viewInsight.content && (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="text-xs">
                <CalendarDaysIcon className="h-3 w-3 text-gray-600" />
                Interval
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleIntervalChange(Interval.Daily);
                    }}
                    className="text-xs capitalize"
                  >
                    <span>{Interval.Daily}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleIntervalChange(Interval.Weekly);
                    }}
                    className="text-xs capitalize"
                  >
                    <span>{Interval.Weekly}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleIntervalChange(Interval.Monthly);
                    }}
                    className="text-xs capitalize"
                  >
                    <span>{Interval.Monthly}</span>
                  </DropdownMenuItem>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )}

          {!viewInsight.content && (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="text-xs">
                <CubeTransparentIcon className="h-3 w-3 text-gray-600" />
                Object
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleObjectChange(userAppObject.objectType);
                    }}
                    className="text-xs"
                  >
                    <UserIcon className="h-3 w-3 text-gray-700" />
                    {userAppObject?.singularName}
                  </DropdownMenuItem>
                  {groupAppObject?.isEnabled && (
                    <DropdownMenuItem
                      disabled={shouldBePaywalled}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleObjectChange(groupAppObject.objectType);
                      }}
                      className="text-xs"
                    >
                      <UsersIcon className="h-3 w-3 text-gray-700" />
                      {groupAppObject?.singularName}
                    </DropdownMenuItem>
                  )}
                  {companyAppObject?.isEnabled && (
                    <DropdownMenuItem
                      disabled={shouldBePaywalled}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleObjectChange(companyAppObject.objectType);
                      }}
                      className="text-xs"
                    >
                      <BuildingOfficeIcon className="h-3 w-3 text-gray-700" />
                      {companyAppObject?.singularName}
                    </DropdownMenuItem>
                  )}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )}
          {!viewInsight.content && (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="text-xs">
                <ChartBarIcon className="h-3 w-3 text-gray-600" />
                Chart
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {getGraphTypeDropdownItems().map((item) => (
                    <DropdownMenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGraphTypeChange(item?.value as GraphType);
                      }}
                      className="text-xs"
                    >
                      <div className="flex flex-row items-center gap-1">
                        <span className="text-gray-600">{item?.icon}</span>{" "}
                        <span>{item?.label}</span>
                      </div>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )}
          {!viewInsight.content && (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="text-xs">
                <PaintBrushIcon className="h-3 w-3 text-gray-600" />
                Color
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {Object.keys(COLOR_NAME_TO_PALETTE).map((color) => (
                    <DropdownMenuItem
                      key={color}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateInsight({
                          id: viewInsight.id,
                          appId: viewInsight.appId,
                          color: color,
                        });
                      }}
                      className="text-xs"
                    >
                      <div className="flex flex-row items-center gap-2">
                        <div
                          className={cn(
                            "h-2 w-2 rounded-[2px]",
                            color && `bg-${color}-500`,
                          )}
                          style={{
                            backgroundColor:
                              COLOR_NAME_TO_PALETTE[color]?.[300],
                          }}
                        />
                        <span className="capitalize">{color}</span>
                      </div>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {isOpen && (
        <ShareModal
          viewInsight={viewInsight}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};
