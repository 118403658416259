import { cn } from "@/lib/utils";
import { Table } from "core/components/Table/People";
import { useAppObjects } from "core/hooks/useAppObjects";
import { usePeopleList } from "core/hooks/usePeopleList";
import { useQueryParam } from "core/hooks/useQueryParam";
import { useTableTraitConfig } from "core/hooks/useTableTraitConfig";
import { useTraits } from "core/hooks/useTraits";
import { IViewInsight } from "core/models/viewInsights";
import { JoinOperatorValue } from "core/types/Audience";

function getHeight(
  gridHeight: number,
  isScreenshotMode: boolean,
  isPreview: boolean,
) {
  if (isScreenshotMode) return "min-h-[600px] max-h-[600px]";
  if (gridHeight === 1)
    return isPreview
      ? "min-h-[265px] max-h-[265px]"
      : "min-h-[221px] max-h-[221px]";
  if (gridHeight === 2) return "min-h-[556px] max-h-[556px]";
  return "min-h-[900px] max-h-[900px]";
}

export const AudienceTable: React.FC<{
  viewInsight: IViewInsight;
  isPreview?: boolean;
}> = ({ viewInsight, isPreview = false }) => {
  const isScreenshotMode = useQueryParam("screenshot_mode") === "true";
  const {
    tableTraitConfigs,
    createTraitConfig,
    deleteTraitConfig,
    updateTraitConfigOrder,
  } = useTableTraitConfig(
    viewInsight.appObject?.objectType,
    undefined,
    viewInsight.id,
  );
  const {
    audienceFilters,
    entities,
    isLoading,
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
  } = usePeopleList({
    appObjectParam: viewInsight.appObject,
    skipUrlHashing: true,
    audienceFiltersParam: {
      filterGroups: viewInsight.audience?.filterGroups || [],
      joinOperator: viewInsight.audience?.joinOperator || JoinOperatorValue.AND,
    },
    viewInsightId: viewInsight.id,
  });
  const { userAppObject } = useAppObjects();
  const { traits } = useTraits(viewInsight.appObject?.objectType);

  if (!entities) return null;

  return (
    <div
      className={cn(
        "relative z-0 mt-2 h-full min-h-full overflow-scroll rounded-b-lg",
        isPreview && "mt-0",
      )}
    >
      <div className="h-[1px] w-full bg-gray-200" />
      <Table
        id="loaded"
        marginTop="mt-0"
        height={getHeight(viewInsight.gridHeight, isScreenshotMode, isPreview)}
        key={viewInsight.appObject?.objectType}
        data={entities || []}
        appObject={viewInsight.appObject || userAppObject}
        tableTraitConfigs={tableTraitConfigs || []}
        traits={traits || []}
        createTraitConfig={createTraitConfig}
        deleteTraitConfig={deleteTraitConfig}
        updateTraitConfigOrder={updateTraitConfigOrder}
        hasFilters={!!audienceFilters.filterGroups.length}
        onClearFilters={() => {}}
        isLoading={isLoading}
        sortBy={sortBy}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        showAddTraitButton={false}
        highlightSorting={false}
      />
    </div>
  );
};
