import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import { Button } from "@/Components/ui/button";
import { Spinner } from "@/Components/ui/spinner";
import {
  Command,
  CommandInput,
  CommandList,
  CommandOption,
} from "core/design-system/components/Command";
import { Lifecycle } from "core/design-system/components/Command/Lifecycle";
import { FrequencyBars } from "core/modules/reports/setup/Menu/FrequencyBars";
import { IEvent } from "core/types/Event";
import { IEventProperty } from "core/types/EventProperty";
import { IPropertyValue } from "core/types/Property";

export const PropertyValuePicker = ({
  isLoading,
  hasMore,
  propertyValues,
  onChangeTrackPropertyValue,
  event,
  property,
  searchTerm,
  setSearchTerm,
  onLoadMore,
}: {
  isLoading: boolean;
  hasMore: boolean;
  propertyValues: IPropertyValue[];
  onChangeTrackPropertyValue: (
    e: React.ChangeEvent<HTMLInputElement>,
    event: IEvent,
    prop: IEventProperty,
    selected: boolean,
  ) => void;
  event: IEvent;
  property: IEventProperty;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onLoadMore: () => void;
}) => {
  return (
    <Popover>
      {({ close }) => (
        <>
          <PopoverButton className="text-sm font-semibold">
            {property.body.value || "Select a value"}
          </PopoverButton>
          <PopoverPanel className="flex flex-col">
            <Lifecycle onUnmount={() => setSearchTerm("")} />
            <Command>
              <CommandInput
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <CommandList>
                {isLoading && (
                  <div className="flex w-full flex-col items-center">
                    <Spinner size="small" />
                  </div>
                )}
                {!isLoading &&
                  propertyValues.map(({ value, frequency }, index) => (
                    <CommandOption
                      key={index}
                      value={value}
                      onClick={() => {
                        onChangeTrackPropertyValue(
                          {
                            currentTarget: { value },
                          } as React.ChangeEvent<HTMLInputElement>,
                          event,
                          property,
                          true,
                        );
                        close();
                      }}
                    >
                      <div className="flex w-full justify-between">
                        <span className="text-sm">{value}</span>
                        <FrequencyBars frequency={frequency} />
                      </div>
                    </CommandOption>
                  ))}
                {propertyValues?.length === 0 && !isLoading && (
                  <p className="text-center text-sm text-gray-500">
                    No values found
                  </p>
                )}
              </CommandList>
              {hasMore && (
                <Button
                  onClick={onLoadMore}
                  variant="default"
                  size="sm"
                  className="mt-2 w-full"
                >
                  Load more
                </Button>
              )}
            </Command>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};
