import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import { Icon } from "@/core/design-system/components/Icon";
import { PropertyValueList } from "@/core/modules/reports/setup/Editor/PropertyValueList";
import { PROPERTY_COMPARISON_TEXT } from "core/hooks/useEditEventAlert";
import { usePropertyValues } from "core/hooks/usePropertyValues";
import { cx } from "helpers/cx";
import { PropertyCombobox } from "modules/Events/List/EventAlertEditor/PropertyCombobox";

interface IPropertyFiltersProps {
  appId: number;
  eventId: number;
  property: string | undefined;
  propertyValue: string | undefined;
  propertyComparisonOperator: string | undefined;
  setProperty: (property: string | undefined) => void;
  setPropertyValue: (value: string | undefined) => void;
  setPropertyComparisonOperator: (value: string | undefined) => void;
}

export const PropertyFilters: React.FC<IPropertyFiltersProps> = ({
  appId,
  eventId,
  property,
  propertyValue,
  propertyComparisonOperator,
  setProperty,
  setPropertyValue,
  setPropertyComparisonOperator,
}) => {
  const { data, isLoading, hasMore, loadMore, searchTerm, setSearchTerm } =
    usePropertyValues({
      appId,
      eventId,
      property: property ?? "",
    });

  const handleRemoveFilter = () => {
    setProperty(undefined);
  };

  return (
    <Box>
      <Flex align="center">
        {!property || property === "" ? (
          <PropertyCombobox
            appId={appId}
            eventId={eventId}
            property={property}
            setProperty={setProperty}
            isAddMode
          />
        ) : (
          <>
            <PropertyCombobox
              appId={appId}
              eventId={eventId}
              property={property}
              setProperty={setProperty}
            />
            <Menu>
              <MenuButton
                bg="gray.100"
                color="gray.900"
                _hover={{ bg: "gray.200" }}
                border="none"
                borderRadius="0px"
                isDisabled={!property}
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {PROPERTY_COMPARISON_TEXT[
                  propertyComparisonOperator as keyof typeof PROPERTY_COMPARISON_TEXT
                ] || "Select a comparison operator"}
              </MenuButton>
              <MenuList minWidth="200px">
                <MenuItem
                  onClick={() => setPropertyComparisonOperator("is")}
                  key={"is"}
                >
                  is
                </MenuItem>
                <MenuItem
                  onClick={() => setPropertyComparisonOperator("is_not")}
                  key={"is not"}
                >
                  is not
                </MenuItem>
                <MenuItem
                  onClick={() => setPropertyComparisonOperator("contains")}
                  key={"contains"}
                >
                  contains
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    setPropertyComparisonOperator("does_not_contain")
                  }
                  key={"does not contain"}
                >
                  does not contain
                </MenuItem>
                <MenuItem
                  onClick={() => setPropertyComparisonOperator("greater_than")}
                  key={"greater than"}
                >
                  greater than
                </MenuItem>
                <MenuItem
                  onClick={() => setPropertyComparisonOperator("less_than")}
                  key={"less than"}
                >
                  less than
                </MenuItem>
              </MenuList>
            </Menu>
            {propertyComparisonOperator === "contains" ||
            propertyComparisonOperator === "does_not_contain" ||
            propertyComparisonOperator === "greater_than" ||
            propertyComparisonOperator === "less_than" ? (
              <Popover>
                {({ close }) => (
                  <>
                    <PopoverButton className="flex items-center bg-gray-100 py-1 text-sm font-semibold">
                      <div className={cx("flex")}>
                        <p
                          data-testid="audience-trait-value"
                          className={cx(
                            "cursor-pointer truncate rounded-md px-1 py-0.5 text-sm font-semibold hover:bg-gray-200",
                            !propertyValue && "bg-gray-200",
                          )}
                        >
                          {propertyValue || "Select a value"}
                        </p>
                      </div>
                    </PopoverButton>
                    <PopoverPanel className="flex flex-col">
                      <div className="absolute z-popover mt-1 max-h-60 w-[200px] overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <div className="flex flex-col gap-2 px-2 py-1">
                          <Input
                            size="sm"
                            borderRadius="md"
                            focusBorderColor="purple.500"
                            value={propertyValue}
                            onChange={(e) => setPropertyValue(e.target.value)}
                          />
                          <Button
                            onClick={() => {
                              setPropertyValue(propertyValue);
                              close();
                            }}
                          >
                            Done
                          </Button>
                        </div>
                      </div>
                    </PopoverPanel>
                  </>
                )}
              </Popover>
            ) : (
              <Menu>
                <MenuButton
                  bg="gray.100"
                  color="gray.900"
                  _hover={{ bg: "gray.200" }}
                  border="none"
                  borderRadius="0px"
                  isDisabled={!property}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  {propertyValue ? propertyValue : "Select a value"}
                </MenuButton>
                <MenuList
                  minWidth="200px"
                  maxH="200px"
                  overflowY="scroll"
                  p={0}
                >
                  <PropertyValueList
                    isLoading={isLoading}
                    hasMore={hasMore}
                    propertyValues={data}
                    onChange={(value: string) => setPropertyValue(value)}
                    value={propertyValue ?? null}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    onLoadMore={loadMore}
                    ItemComponent={MenuItem}
                  />
                </MenuList>
              </Menu>
            )}
            <Tooltip label="Remove filter" placement="top" hasArrow>
              <Button
                data-testid="audience-remove-trait-button"
                bg="gray.100"
                color="gray.900"
                _hover={{ bg: "gray.200" }}
                borderLeftRadius="0px"
                border="none"
                onClick={handleRemoveFilter}
                borderLeft="none"
              >
                <Icon iconType="icon" name="close" h={2} w={2} />
              </Button>
            </Tooltip>
          </>
        )}
      </Flex>
    </Box>
  );
};
