import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";

import { useIsEditingView } from "core/contexts/EditingViewContext";
import { IViewInsight } from "core/models/viewInsights";

export const EmptyState = ({
  viewInsights,
  width,
}: {
  viewInsights?: IViewInsight[];
  width: string;
}) => {
  const { appId, viewId } = useParams();
  const navigate = useNavigate();
  const isEditing = useIsEditingView();

  const onCardClick = () => {
    navigate(`/a/${appId}/dashboard/${viewId}/graph/new`);
  };

  return (
    <>
      {(isEditing || viewInsights?.length === 0) && (
        <div style={{ width, height: "315px" }}>
          <div
            className="h-full w-full cursor-pointer rounded-lg border-2 border-dashed border-purple-300 transition-all duration-100 ease-in-out hover:border-purple-500 hover:bg-purple-50 hover:shadow-sm"
            onClick={onCardClick}
          >
            <div className="flex h-full w-full items-center justify-center">
              <div className="flex flex-col items-center gap-2">
                <SquaresPlusIcon className="h-5 w-5 text-purple-500" />
                <div className="px-20 text-center text-sm font-medium text-purple-500">
                  {viewInsights?.length === 0
                    ? "Add your first insight"
                    : "Add insight"}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
