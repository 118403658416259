import { Center, ComponentDefaultProps, Skeleton } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { SimpleAreaChart } from "@/core/components/ViewInsight/Graphs/SimpleAreaChart";
import { SimpleBarChart } from "@/core/components/ViewInsight/Graphs/SimpleBarChart";
import colors from "@/core/design-system/constants/theme/colors";
import { IViewInsight } from "@/core/models/viewInsights";
import { GraphType, Measure } from "@/core/types/ViewInsight";
import { cn } from "@/lib/utils";

const SkeletonOverlay = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  const [opacity, setOpacity] = useState(0.5);
  const [increasing, setIncreasing] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (increasing) {
        setOpacity((prev) => {
          if (prev >= 1) {
            setIncreasing(false);
            return 1;
          }
          return prev + 0.1;
        });
      } else {
        setOpacity((prev) => {
          if (prev <= 0.3) {
            setIncreasing(true);
            return 0.3;
          }
          return prev - 0.1;
        });
      }
    }, 200);
    return () => clearInterval(interval);
  }, [increasing]);

  return (
    <div
      className={cn(
        "h-full w-full",
        "transition-opacity duration-500",
        className,
      )}
      style={{ opacity }}
    >
      {children}
    </div>
  );
};

export const TableLoader: React.FC<ComponentDefaultProps> = (props) => {
  return (
    <SkeletonOverlay className="flex flex-col gap-3">
      <div className="h-[25px] w-full rounded-md bg-gray-200" />
      <div className="h-[25px] w-full rounded-md bg-gray-200" />
      <div className="h-[25px] w-full rounded-md bg-gray-200" />
      <div className="h-[25px] w-full rounded-md bg-gray-200" />
      <div className="h-[25px] w-full rounded-md bg-gray-200" />
      <div className="h-[25px] w-full rounded-md bg-gray-200" />
    </SkeletonOverlay>
  );
};

export const CohortGraphLoader: React.FC<ComponentDefaultProps> = (props) => {
  return (
    <SkeletonOverlay className="flex h-full w-full flex-col gap-2">
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
    </SkeletonOverlay>
  );
};

export const FunnelGraphLoader: React.FC<ComponentDefaultProps> = (props) => {
  return (
    <SkeletonOverlay className="flex h-full w-full flex-col gap-2">
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-full rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-full rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-full rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-full rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-full rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-full rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
      <div className="flex gap-2">
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
        <div className="h-[20px] w-full rounded-md bg-gray-200" />
        <div className="h-[20px] w-[45px] rounded-md bg-gray-200" />
      </div>
    </SkeletonOverlay>
  );
};

export const AreaChartLoader: React.FC<ComponentDefaultProps> = (props) => {
  return (
    <SkeletonOverlay>
      <SimpleAreaChart
        data={[
          { x: "Mon", y: 100 },
          { x: "Tue", y: 200 },
          { x: "Wed", y: 100 },
          { x: "Thu", y: 400 },
          { x: "Fri", y: 500 },
        ]}
        color={colors.gray}
        showAxis={false}
      />
    </SkeletonOverlay>
  );
};

export const BarChartLoader: React.FC<ComponentDefaultProps> = (props) => {
  return (
    <SkeletonOverlay>
      <SimpleBarChart
        data={[
          { x: "Mon", y: 200 },
          { x: "Tue", y: 100 },
          { x: "Wed", y: 200 },
          { x: "Thu", y: 100 },
          { x: "Fri", y: 300 },
        ]}
        showAxis={false}
        color={colors.gray}
      />
    </SkeletonOverlay>
  );
};

export const TrendChartLoader: React.FC<ComponentDefaultProps> = (props) => {
  return (
    <SkeletonOverlay className="flex flex-col items-center justify-center gap-2">
      <div className="h-[30%] w-[50%] rounded-md bg-gray-200" />
      <div className="h-[10%] w-[70%] rounded-md bg-gray-200" />
    </SkeletonOverlay>
  );
};

export const Loader: React.FC<
  {
    viewInsight: IViewInsight;
  } & ComponentDefaultProps
> = ({ viewInsight, ...props }) => {
  if (!viewInsight) return <Skeleton h="full" w="full" />;
  return (
    <Center
      data-testid="highlight-card-loader"
      h="full"
      w="full"
      px={3}
      py={3}
      {...props}
    >
      {viewInsight.content ? (
        <div className="flex flex-col gap-3">
          <div className="h-[25px] w-full rounded-md bg-gray-200" />
          <div className="h-[25px] w-full rounded-md bg-gray-200" />
          <div className="h-[25px] w-full rounded-md bg-gray-200" />
        </div>
      ) : viewInsight.graphType === GraphType.Bar &&
        viewInsight.measure === Measure.Funnel ? (
        <FunnelGraphLoader />
      ) : viewInsight.graphType === GraphType.Table ? (
        <TableLoader />
      ) : viewInsight.graphType === GraphType.Area ||
        viewInsight.graphType === GraphType.StackedLine ? (
        <AreaChartLoader />
      ) : viewInsight.graphType === GraphType.Bar ||
        viewInsight.graphType === GraphType.StackedBar ? (
        <BarChartLoader />
      ) : viewInsight.graphType === GraphType.Trend ? (
        <TrendChartLoader />
      ) : viewInsight.graphType === GraphType.CohortGraph ? (
        <CohortGraphLoader />
      ) : (
        <Skeleton h="full" w="full" />
      )}
    </Center>
  );
};
