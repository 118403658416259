import { useNavigate } from "react-router-dom";

import { IDENTIFY, PAGE, TRACK } from "core/constants/events";
import { IEventLog } from "core/types/EventLog";
import { cx } from "helpers/cx";
import { EVENT_LOG_DECORATION_COLORS } from "modules/Contacts/ContactEventLogGroups";

export const DisplayName: React.FC<{
  eventLog: IEventLog;
  showGroup: boolean;
}> = ({ eventLog, showGroup = true }) => {
  const navigate = useNavigate();

  if (eventLog.event.type === IDENTIFY) {
    return "identify";
  }

  if (eventLog.event.name && eventLog.event.type === TRACK) {
    if (eventLog.event.groupId && eventLog.group.name) {
      return (
        <p
          className={cx(
            EVENT_LOG_DECORATION_COLORS[eventLog.event.type],
            eventLog.properties && Object.keys(eventLog.properties).length > 0
              ? "cursor-help underline decoration-dotted underline-offset-2"
              : "",
            "inline-block font-medium text-gray-900",
          )}
        >
          {eventLog.event.name}
          {showGroup && (
            <p
              className="underline-none underline-gray-200 ml-1 inline-block cursor-pointer decoration-slice font-normal text-gray-600 hover:underline"
              onClick={() =>
                navigate(
                  `/a/${eventLog.event.appId}/group/${eventLog.event.groupId}`,
                )
              }
            >
              in {eventLog.group.name}
            </p>
          )}
        </p>
      );
    }

    return eventLog.event.name;
  }
  if (eventLog.event.type === PAGE) {
    return (
      eventLog.properties?.path ||
      eventLog.properties?.title ||
      eventLog.properties?.name ||
      eventLog.properties?.url ||
      "/"
    );
  }

  return eventLog.event.name || "";
};
