import { findIndex, isEmpty } from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "@/Components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/Components/ui/dialog";
import { ObjectFilter } from "@/core/modules/audience/Filters/ObjectFilter";
import { USER_TRAIT } from "core/constants/filters";
import { FilterType } from "core/constants/report-setup";
import {
  audienceHasGroupId,
  getAudienceGroupId,
  isGroupIdFilter,
} from "core/helpers/audienceHelper";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useQueryParam } from "core/hooks/useQueryParam";
import { Level } from "core/models/people";
import { ITraitKeyResponse } from "core/models/traits";
import {
  useGetViewTokenQuery,
  useUnshareViewMutation,
} from "core/models/views";
import { OperatorMenu } from "core/modules/audience/Filters/OperatorMenu";
import { TraitFilter } from "core/modules/audience/Filters/TraitFilter";
import { EventFilter } from "core/modules/reports/setup/Audience/EventFilter";
import { getLevelParam } from "core/modules/reports/utils";
import { AppObjectSlugs, IAppObject } from "core/types/AppObject";
import { IAudienceFilters } from "core/types/Audience";
import { ComparisonType, IFilter, JoinOperatorValue } from "core/types/Filters";
import { cx } from "helpers/cx";

interface IFilterProps {
  filter: IFilter;
  filterIndex: number;
  audienceFilters: IAudienceFilters;
  setAudienceFilters: (newAudienceFilters: IAudienceFilters) => void;
  onRemoveFilter: (filter: IFilter) => void;
  onChangeJoinOperator: (joinOperatorValue: JoinOperatorValue) => void;
  filterGroupIndex: number;
  highlight: boolean;
  setHighlight: (highlight: boolean) => void;
  isFirst: boolean;
  isLast: boolean;
  view?: string;
  truncate?: boolean;
  onUpdateReport?: () => void;
  hasGroupId: boolean;
  isDisabled?: boolean;
}

export function getDisabledFilterText(
  filterType: FilterType,
  level: string,
  groupId: string | undefined | null,
  companyAppObject: IAppObject,
  groupAppObject: IAppObject,
) {
  if (!!groupId && level === AppObjectSlugs.User) {
    return `Filters are disabled when viewing the report for a specific company`;
  }

  if (filterType === FilterType.CompanyFilter && !companyAppObject?.isEnabled) {
    return `${companyAppObject?.pluralName} are disabled`;
  }

  if (filterType === FilterType.GroupFilter && !groupAppObject?.isEnabled) {
    return `${groupAppObject?.pluralName} are disabled`;
  }

  if (filterType === FilterType.CompanyFilter && level === "group") {
    return `${companyAppObject?.singularName} filters don't apply on ${groupAppObject?.pluralName.toLocaleLowerCase()}`;
  }

  if (filterType === FilterType.GroupFilter && level === "company") {
    return `${groupAppObject?.singularName} filters don't apply on ${companyAppObject?.pluralName.toLocaleLowerCase()}`;
  }
}

export const Filter: React.FC<IFilterProps> = ({
  filter,
  filterIndex,
  filterGroupIndex,
  onRemoveFilter,
  onChangeJoinOperator,
  audienceFilters,
  setAudienceFilters,
  highlight,
  setHighlight,
  view,
  isFirst,
  isLast,
  truncate,
  onUpdateReport,
  hasGroupId,
  isDisabled,
}) => {
  const currentApp = useCurrentApp();
  const level = getLevelParam();
  const groupId = useQueryParam("groupId");
  const { viewId } = useParams();
  const [initialFilter, setInitialFilter] = useState<IFilter>(filter);
  const [traitValue, setTraitValue] = useState<string | string[]>(
    filter.body.value || "",
  );
  const [unshare] = useUnshareViewMutation();
  const { data: shareToken } = useGetViewTokenQuery({
    appId: currentApp.id,
    viewId: Number(viewId),
  });
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const { companyAppObject, groupAppObject } = useAppObjects();
  const filters =
    audienceFilters.filterGroups[filterGroupIndex]?.filters?.map(
      (filter: IFilter, index: number) => ({
        ...filter,
        id: index,
      }),
    ) || [];
  const joinOperator =
    audienceFilters.filterGroups[filterGroupIndex]?.joinOperator;

  const onSaveFilter = (filter: IFilter) => {
    if (
      filter.type === FilterType.EventFilter &&
      filter.body.eventName === undefined
    ) {
      return;
    }

    const index = findIndex(filters, (f: IFilter) => f.id === filter.id);
    const newFilters = [...filters];
    newFilters[index] = {
      ...filter,
      id: typeof filter.id === "number" ? filter.id : index,
    };

    const newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    newAudienceFilters.filterGroups[filterGroupIndex].filters = newFilters;
    setAudienceFilters(newAudienceFilters);

    trackFilterEvent(filter.type);
    onUpdateReport && onUpdateReport();
  };

  const handleRemoveFilter = () => {
    const isGroupFilter = isGroupIdFilter(filter);
    const hasAudienceGroupId = audienceHasGroupId(audienceFilters);

    if (isGroupFilter && hasAudienceGroupId && !isEmpty(shareToken)) {
      setShowRemoveDialog(true);
    } else {
      onRemoveFilter(filter);
    }
  };

  const handleConfirmRemove = async () => {
    if (currentApp && viewId) {
      const { groupId } = getAudienceGroupId(audienceFilters);

      await unshare({
        appId: currentApp.id,
        groupId: groupId,
        viewId: Number(viewId),
      });
    }
    onRemoveFilter(filter);
    setShowRemoveDialog(false);
  };

  const onChangeTraitValue = ({
    value,
    saveFilter = false,
  }: {
    value: string;
    saveFilter?: boolean;
  }) => {
    const newFilter = {
      ...filter,
      body: {
        ...filter.body,
        value,
      },
    };
    setInitialFilter(newFilter);
    setTraitValue(value);
    if (saveFilter) {
      onSaveFilter(newFilter);
    }
  };

  const onSelectTrait = (_trait: ITraitKeyResponse) => {
    const newFilter = {
      ...filter,
      body: {
        ...filter.body,
        trait: _trait.trait,
        value: "",
      },
      type: _trait.filterType || USER_TRAIT,
    };
    setInitialFilter(newFilter);
    setTraitValue("");
    onSaveFilter(newFilter);
    onUpdateReport && onUpdateReport();
  };

  const onChangeComparison = (type: ComparisonType) => {
    const newFilter = {
      ...filter,
      body: {
        ...filter.body,
        comparisonType: Number(type),
      },
    };
    setInitialFilter(newFilter);
    onSaveFilter(newFilter);
    onUpdateReport && onUpdateReport();
  };

  const trackFilterEvent = (filterType: FilterType) => {
    let analyticsEventName = "added_audience_user_trait_filter";
    if (filterType === FilterType.EventFilter) {
      analyticsEventName = "added_audience_event_filter";
    } else if (filterType === FilterType.GroupFilter) {
      analyticsEventName = "added_audience_group_trait_filter";
    } else if (filterType === FilterType.CompanyFilter) {
      analyticsEventName = "added_audience_company_trait_filter";
    }
    window.analytics.track(
      analyticsEventName,
      {},
      { context: { groupId: currentApp.id } },
    );
  };

  const computeDisabled =
    isDisabled ||
    (!!groupId && level === Level.User) ||
    (initialFilter.type === FilterType.GroupFilter &&
      !groupAppObject?.isEnabled) ||
    (initialFilter.type === FilterType.CompanyFilter &&
      !companyAppObject?.isEnabled) ||
    (initialFilter.type === FilterType.GroupFilter &&
      view === AppObjectSlugs.Company) ||
    (initialFilter.type === FilterType.CompanyFilter &&
      view === AppObjectSlugs.Group);

  return (
    <>
      <div
        className={cx(
          "flex items-center whitespace-nowrap bg-gray-50",
          isFirst && "rounded-l-lg",
          hasGroupId && "rounded-r-lg",
        )}
        key={String(initialFilter.id)}
      >
        {[
          FilterType.UserTrait,
          FilterType.GroupFilter,
          FilterType.CompanyFilter,
        ].includes(initialFilter.type) && (
          <TraitFilter
            filter={initialFilter}
            traitValue={traitValue}
            setTraitValue={setTraitValue}
            setFilter={setInitialFilter}
            onSelectTrait={onSelectTrait}
            onChangeComparison={onChangeComparison}
            onChangeTraitValue={onChangeTraitValue}
            isLast={isLast}
            onRemoveFilter={handleRemoveFilter}
            onSaveFilter={onSaveFilter}
            truncate={truncate}
            isDisabled={computeDisabled}
            disabledTooltipText={getDisabledFilterText(
              initialFilter.type,
              view || "user",
              groupId,
              companyAppObject,
              groupAppObject,
            )}
          />
        )}
        {filter.type === FilterType.EventFilter && (
          <EventFilter
            filter={initialFilter}
            filterIndex={filterIndex}
            onRemoveFilter={handleRemoveFilter}
            onSaveFilter={onSaveFilter}
            filterGroupIndex={filterGroupIndex}
            isDisabled={isDisabled}
            disabledTooltipText={getDisabledFilterText(
              initialFilter.type,
              view || "user",
              groupId,
              companyAppObject,
              groupAppObject,
            )}
            onUpdateReport={onUpdateReport}
          />
        )}
        {filter.type === FilterType.ObjectFilter && (
          <ObjectFilter
            filter={initialFilter}
            filterIndex={filterIndex}
            onRemoveFilter={handleRemoveFilter}
            onSaveFilter={onSaveFilter}
          />
        )}
        {filterIndex !== filters.length - 1 && (
          <OperatorMenu
            joinOperator={joinOperator}
            onChangeJoinOperator={onChangeJoinOperator}
            filterGroup={false}
            highlight={highlight}
            setHighlight={setHighlight}
          />
        )}
      </div>

      <Dialog open={showRemoveDialog} onOpenChange={setShowRemoveDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle data-testid="remove-filter-dialog-title">
              Remove filter
            </DialogTitle>
            <DialogDescription>
              This dashboard is currently being shared. Removing this filter
              will disable the sharing for this dashboard. Are you sure you want
              to continue?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowRemoveDialog(false)}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleConfirmRemove}>
              Remove and unshare
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
