import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Container,
  Flex,
  HStack,
  Image,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import Emoji from "a11y-react-emoji";
import { SyntheticEvent, useState } from "react";
import { toast } from "sonner";

import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import GoogleIcon from "core/design-system/components/Icon/Logos/GoogleIcon";
import { useUserAuth } from "core/hooks/useUserAuth";

const Start: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const queryParams = new URLSearchParams(window?.location?.search);
  const showUseWorkEmailAlert = !!queryParams.get(`use_work_email_alert`);
  const { signup, isSignupLoading } = useUserAuth();

  // eslint-disable-next-line no-undef
  const anonymousId =
    window.analytics &&
    window.analytics.user &&
    window.analytics.user().anonymousId();

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    if (!email || !password || !passwordConfirmation) {
      toast.error("Error", {
        description: "Please fill out all fields",
      });

      return;
    }
    // validate email
    if (!email.includes("@")) {
      toast.error("Error", {
        description: "Please enter a valid email",
      });
      return;
    }

    if (passwordConfirmation !== password) {
      toast.error("Error", {
        description: "Your password and password confirmation have to match",
      });

      return;
    }

    signup({ email, password, passwordConfirmation })
      .unwrap()
      .catch((error) => {
        if (error.data.error.includes("personal")) {
          const url = new URL(window.location.href);
          url.searchParams.set("use_work_email_alert", "true");
          window.history.replaceState({}, "", url.toString());
        }
        toast.error("Error", {
          description: error.data.error,
        });
      });
  };

  return (
    <Flex
      height="100vh"
      flexDirection="column"
      justifyContent="space-between"
      backgroundImage="radial-gradient(circle at center, #7b7bff1c 0, #ffffff 60%)"
    >
      <Box id="box" my="auto">
        <Container>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              {" "}
              <Flex justify="center">
                <CompanyJuneLogoIcon h="50px" w="50px" />
              </Flex>
              <Flex pt={4} justify="center">
                <h2 className="mt-4 text-center text-xl font-medium leading-9 tracking-tight text-gray-800">
                  Get started
                </h2>
              </Flex>
              <Flex justify="center">
                <p className="text-gray-500">
                  Start setting up your account{" "}
                  <Emoji symbol="✌️" label="cool" />
                </p>
              </Flex>
              <Flex pt={8} justify="center">
                <Flex w="500px" direction="column" gap={2}>
                  {showUseWorkEmailAlert && (
                    <Flex justify="center">
                      <Alert
                        textAlign="center"
                        variant="subtle"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        colorScheme={"purple"}
                        w="full"
                        borderRadius={"lg"}
                      >
                        <Flex alignItems="center" justifyContent="center">
                          <AlertIcon />
                          <AlertTitle fontWeight="semibold">
                            Use your work email to sign up for June
                          </AlertTitle>
                        </Flex>
                      </Alert>
                    </Flex>
                  )}
                  <Flex justify="center">
                    <a
                      href={`${
                        import.meta.env.VITE_API_HOST
                      }/auth/google_oauth2?anonymous_id=${anonymousId}`}
                      className="flex w-full items-center justify-center gap-3 rounded-md bg-gray-100 px-3 py-1.5 text-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                    >
                      <GoogleIcon h={5} w={5} />

                      <span className="text-sm font-semibold leading-6">
                        Sign up with Google
                      </span>
                    </a>
                  </Flex>
                  <div className="relative mt-6">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-200"></div>
                    </div>
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-white px-6 text-gray-900">
                        Or continue with
                      </span>
                    </div>
                  </div>
                  <form className="mt-6 space-y-6" onSubmit={handleSubmit}>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                      <div className="mt-2">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="new-password"
                          required
                          className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-2">
                        <input
                          id="passwordConfirmation"
                          type="password"
                          autoComplete="new-password"
                          required
                          className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          name="passwordConfirmation"
                          value={passwordConfirmation}
                          onChange={(e) =>
                            setPasswordConfirmation(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className={`flex w-full items-center justify-center rounded-md bg-purple-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-500 ${
                        isSignupLoading && "bg-purple-600"
                      }`}
                    >
                      {isSignupLoading ? (
                        <>
                          <Spinner size="sm" mr={2} />{" "}
                          <span>Creating your account..</span>
                        </>
                      ) : (
                        <>Continue</>
                      )}
                    </button>
                  </form>
                </Flex>
              </Flex>
            </div>
          </div>
        </Container>
        <VStack
          spacing={[5]}
          py={[8]}
          px={[2]}
          display={["none", "none", "flex"]}
        >
          <Text fontSize="sm">These B2B SaaS startups get it</Text>
          <HStack spacing={[8]}>
            <Image src="/rootly.svg" alt="rootly" height={["28px"]} />
            <Image src="/causal.svg" alt="Causal" height={["28px"]} />
            <Image src="/crew.svg" alt="Crew" height={["18px"]} />
            <Image src="/theydo.svg" alt="TheyDo" height={["36px"]} />
          </HStack>
        </VStack>
      </Box>
    </Flex>
  );
};

export default Start;
