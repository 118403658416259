import { useEffect, useState } from "react";

import { getAudienceGroupId } from "core/helpers/audienceHelper";
import {
  IShareQueryResponse,
  useGetQueryTokenQuery,
  useShareQueryMutation,
  useUnshareQueryMutation,
} from "core/models/queries";
import { IViewInsight } from "core/models/viewInsights";

export const useShareViewQuery = ({
  viewInsight,
  groupId,
}: {
  viewInsight: IViewInsight;
  groupId?: string | undefined;
}) => {
  const [token, setToken] = useState("");
  const [shareQuery] = useShareQueryMutation();
  const [unshareQuery] = useUnshareQueryMutation();

  const { data, isLoading } = useGetQueryTokenQuery({
    appId: viewInsight.appId,
    id: viewInsight.queryId as number,
    groupId,
  });

  const getGroupId = () => {
    if (groupId) return groupId;
    if (viewInsight.useViewAudience && viewInsight.view.audience) {
      const { groupId } = getAudienceGroupId(viewInsight.view.audience);
      return groupId;
    }

    return undefined;
  };

  useEffect(() => {
    if (data) setToken(data.token);
  }, [data]);

  const BASE_URL = import.meta.env.VITE_APP_HOST || "http://localhost:3000";
  const publicUrl = groupId
    ? `${BASE_URL}/a/${viewInsight.appId}/dashboard/${viewInsight.viewId}/query/${viewInsight.id}/${groupId}/${token}`
    : `${BASE_URL}/a/${viewInsight.appId}/dashboard/${viewInsight.viewId}/query/${viewInsight.id}/${token}`;

  function onShareQuery() {
    shareQuery({
      appId: viewInsight.appId,
      id: viewInsight.queryId as number,
      groupId: getGroupId(),
    })
      .unwrap()
      .then((response: IShareQueryResponse) => {
        setToken(response?.token);
      })
      .catch((error) => {
        console.error("Failed to share query:", error);
      });
  }

  function onUnshareQuery() {
    unshareQuery({
      appId: viewInsight.appId,
      id: viewInsight.queryId as number,
      groupId: getGroupId(),
    })
      .unwrap()
      .then(() => {
        setToken("");
      })
      .catch((error) => {
        console.error("Failed to unshare query:", error);
      });
  }

  return {
    token,
    isSharing: Boolean(token),
    isLoading,
    publicUrl,
    onShareQuery,
    onUnshareQuery,
  };
};
