import { ComponentDefaultProps } from "@chakra-ui/react";
import React, { useState } from "react";

import { audienceHasGroupId } from "@/core/helpers/audienceHelper";
import { AppObjectType } from "@/core/types/AppObject";
import { FilterType } from "core/constants/report-setup";
import { DataTypeString } from "core/models/traits";
import { AddFilterGroupButton } from "core/modules/audience/Filters/AddFilterGroupButton";
import { ClearFilters } from "core/modules/audience/Filters/ClearFilters";
import Filters, {
  COMPARISON_TYPE_BY_DATA_TYPE,
  DEFAULT_VALUES_FOR_DATA_TYPE,
  EmptyFilters,
} from "core/modules/audience/Filters/Filters";
import { OperatorMenu } from "core/modules/audience/Filters/OperatorMenu";
import { IAudienceFilters } from "core/types/Audience";
import { JoinOperatorValue } from "core/types/Filters.d";

interface IAudienceFiltersProps extends ComponentDefaultProps {
  audienceFilters: IAudienceFilters;
  setAudienceFilters: (newAudienceFilters: IAudienceFilters) => void;
  view?: string;
  showClearFilters?: boolean;
}

export const AudienceFilters: React.FC<IAudienceFiltersProps> = ({
  audienceFilters,
  setAudienceFilters,
  showClearFilters = true,
  view,
  children,
}) => {
  const [highlightOperators, setHighlightOperators] = useState(false);

  const hasGroupId = audienceHasGroupId(audienceFilters);
  const joinOperator = audienceFilters.joinOperator ?? JoinOperatorValue.AND;

  const onChangeJoinOperator = (joinOperatorValue: JoinOperatorValue) => {
    setAudienceFilters({
      ...audienceFilters,
      joinOperator: joinOperatorValue,
    });
  };

  const onAddObjectFilter = (
    filterType: FilterType,
    objectType: AppObjectType,
  ) => {
    const emptyObjectFilter = EmptyFilters[filterType];
    const newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    const newFilterGroup = {
      filters: [
        {
          ...emptyObjectFilter,
          body: { ...emptyObjectFilter.body, objectType },
        },
      ],
      joinOperator: JoinOperatorValue.AND,
    };
    newAudienceFilters.filterGroups.push(newFilterGroup);
    setAudienceFilters(newAudienceFilters);
  };

  const onAddEventFilter = (filterType: FilterType) => {
    const emptyEventFilter = EmptyFilters[filterType];

    const newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    const newFilterGroup = {
      filters: [emptyEventFilter],
      joinOperator: JoinOperatorValue.AND,
    };
    newAudienceFilters.filterGroups.push(newFilterGroup);
    setAudienceFilters(newAudienceFilters);
  };

  function onClearFilters() {
    const newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    newAudienceFilters.filterGroups = [];
    setAudienceFilters(newAudienceFilters);
  }

  const onAddTraitFilter = (
    filterType: FilterType,
    trait: string,
    dataType: DataTypeString,
  ) => {
    const emptyFilter = EmptyFilters[filterType];
    const emptyTraitFilter = {
      ...emptyFilter,
      body: {
        ...emptyFilter.body,
        trait,
        comparisonType: COMPARISON_TYPE_BY_DATA_TYPE[dataType],
        value: DEFAULT_VALUES_FOR_DATA_TYPE[dataType].toString(),
      },
      id: 0,
    };

    const newAudienceFilters = JSON.parse(JSON.stringify(audienceFilters));
    const newFilterGroup = {
      filters: [emptyTraitFilter],
      joinOperator: JoinOperatorValue.AND,
    };
    newAudienceFilters.filterGroups.push(newFilterGroup);
    setAudienceFilters(newAudienceFilters);
  };

  return (
    <div className="flex w-full max-w-full justify-between">
      <div
        className="flex flex-wrap items-center gap-y-2"
        data-testid="audience-filter-groups"
      >
        {audienceFilters.filterGroups.map((_filterGroup, index) => {
          return (
            <div className="flex gap-x-2">
              <Filters
                filterGroupIndex={index}
                audienceFilters={audienceFilters}
                setAudienceFilters={setAudienceFilters}
                view={view}
              >
                {index !== audienceFilters.filterGroups.length - 1 && (
                  <div className="mx-2">
                    <OperatorMenu
                      joinOperator={joinOperator}
                      onChangeJoinOperator={onChangeJoinOperator}
                      bg="white"
                      ml={2}
                      highlight={highlightOperators}
                      setHighlight={setHighlightOperators}
                      disabled={hasGroupId}
                    />
                  </div>
                )}
              </Filters>
            </div>
          );
        })}
        <div className="mx-2 flex flex-row">
          {!hasGroupId && (
            <AddFilterGroupButton
              onAddEventFilter={onAddEventFilter}
              onAddTraitFilter={onAddTraitFilter}
              onAddObjectFilter={onAddObjectFilter}
              isGroupIdFilterDisabled={hasGroupId}
            />
          )}
        </div>
        {children}
      </div>
      <div>
        {showClearFilters && audienceFilters.filterGroups?.length > 0 && (
          <ClearFilters onClick={onClearFilters} />
        )}
      </div>
    </div>
  );
};
