import { BoltIcon } from "@heroicons/react/16/solid";
import { useNavigate, useParams } from "react-router-dom";

import { Tooltip, TooltipContent, TooltipTrigger } from "Components/ui/tooltip";
import { ComputedTraitTooltip } from "core/components/Traits/ComputedTraitTooltip";
import { TraitIcon } from "core/components/Traits/TraitIcon";
import { ITrait } from "core/models/objects/traits";
import { cn } from "lib/utils";
import { Value } from "modules/Object/Sections/Traits/Value";

export const Trait: React.FC<{ trait: ITrait }> = ({ trait }) => {
  const { appId } = useParams();
  const navigate = useNavigate();

  return (
    <div
      key={trait.name}
      className="flex flex-grow justify-between gap-x-2 py-2.5 text-sm"
    >
      <div className="flex items-center gap-1">
        <TraitIcon
          traitName={trait.name}
          computationType={trait.trait?.config?.computationType}
          color="text-gray-600"
        />
        <Tooltip>
          <TooltipTrigger asChild>
            <p
              onClick={() => {
                if (trait?.trait?.isComputed) {
                  navigate(
                    `/a/${appId}/integrations/computed-traits/${trait.trait?.id}`,
                  );
                }
              }}
              className={cn(
                "max-w-[150px] truncate whitespace-nowrap text-gray-600",
                trait?.trait?.isComputed &&
                  "cursor-pointer hover:underline hover:decoration-dashed hover:[text-decoration-thickness:1px] hover:[text-underline-offset:4px]",
              )}
            >
              {trait.name}
            </p>
          </TooltipTrigger>
          <TooltipContent side="bottom">{trait.name}</TooltipContent>
        </Tooltip>
        {trait?.trait?.isComputed && (
          <Tooltip>
            <TooltipTrigger asChild>
              <BoltIcon className="size-3 text-purple-400" />
            </TooltipTrigger>
            <TooltipContent side="bottom">
              <ComputedTraitTooltip
                name={trait.name}
                description={trait.trait?.description}
                lastComputedAt={trait.trait?.lastComputedAt}
                isLive={trait.trait?.isLive}
              />
            </TooltipContent>
          </Tooltip>
        )}
      </div>
      <Value trait={trait} />
    </div>
  );
};
