import { CreateReportRoute } from "@/routes/CreateReportRoute";
import { AddSQLRoute } from "@/routes/Dashboard/AddSql";
import { Dashboards } from "@/routes/Dashboards";
import { PublicViewInsightRoute } from "@/routes/PublicViewInsightRoute";
import { PublicViewInsightScreenshotRoute } from "@/routes/PublicViewInsightScreenshotRoute";
import Audience from "routes/Audience";
import Audiences from "routes/Audiences";
import { Company } from "routes/Company";
import { Contact } from "routes/Contact";
import { Contacts } from "routes/Contacts";
import { CrmSyncSettingRoute } from "routes/CrmSyncSetting";
import { AddInsightRoute } from "routes/Dashboard/AddInsight";
import { EditSQLRoute } from "routes/Dashboard/EditSql";
import { ViewInsightRoute } from "routes/Dashboard/ViewInsight";
import Developers from "routes/Developers";
import Event from "routes/Event";
import { Rename } from "routes/Event/Rename";
import Events from "routes/Events";
import Feed from "routes/Feed";
import { ForgotPasswordPage } from "routes/ForgotPasswordPage";
import GettingStarted from "routes/GettingStarted";
import { Group } from "routes/Group";
import Groups from "routes/Groups";
import Home from "routes/Home";
import { Join } from "routes/Join";
import Login from "routes/Login";
import { MobileWidgetInstall } from "routes/MobileWidgets/Install";
import MobileWidgetsOnboarding from "routes/MobileWidgets/Onboarding";
import { NewGroup } from "routes/NewGroup";
import NewReportSetup from "routes/NewReportSetup";
import { OauthRedirect } from "routes/OauthRedirect";
import { ObjectRoute } from "routes/Object";
import { AddObjectInsightRoute } from "routes/Object/AddObjectInsight";
import { AddObjectSQLRoute } from "routes/Object/AddObjectSql";
import { EditObjectSQLRoute } from "routes/Object/EddObjectSql";
import { EditObjectInsightRoute } from "routes/Object/EditObjectInsight";
import Onboarding from "routes/Onboarding";
import { Schedule } from "routes/Onboarding/Schedule";
import People from "routes/People";
import PublicInsightRoute from "routes/PublicInsightRoute";
import PublicQueryRoute from "routes/PublicQueryRoute";
import PublicReportRoute from "routes/PublicReportRoute";
import PublicViewRoute from "routes/PublicViewRoute";
import { RedirectToMyWorkspace } from "routes/RedirectToMyWorkspace";
import Report from "routes/Report";
import Reports from "routes/Reports";
import { ResetPasswordPage } from "routes/ResetPasswordPage";
import { Integrations } from "routes/Settings/Integrations";
import Amplitude from "routes/Settings/Integrations/Amplitude";
import Api from "routes/Settings/Integrations/Api";
import Attio from "routes/Settings/Integrations/Attio";
import { Email as EmailSettings } from "routes/Settings/Integrations/Email";
import Freshpaint from "routes/Settings/Integrations/FreshPaint";
import { Hubspot } from "routes/Settings/Integrations/Hubspot";
import Mixpanel from "routes/Settings/Integrations/Mixpanel";
import Rudderstack from "routes/Settings/Integrations/Rudderstack";
import { Salesforce } from "routes/Settings/Integrations/Salesforce";
import JuneSdk from "routes/Settings/Integrations/Sdk";
import Segment from "routes/Settings/Integrations/Segment";
import { Slack as SlackSettings } from "routes/Settings/Integrations/Slack";
import { Stripe } from "routes/Settings/Integrations/Stripe";
import { ArchivedTraits } from "routes/Settings/Objects/ArchivedTraits";
import { TraitDebugger } from "routes/Settings/Objects/Traits/Debugger";
import { ViewObject } from "routes/Settings/Objects/ViewObject";
import { ViewObjectTraits } from "routes/Settings/Objects/ViewObjectTraits";
import { Billing } from "routes/Settings/sections/Billing";
import { ComputedTraits } from "routes/Settings/sections/ComputedTraits";
import { Digest } from "routes/Settings/sections/Digest";
import { EditComputedTrait } from "routes/Settings/sections/EditComputedTrait";
import { GeneralSettings } from "routes/Settings/sections/General";
import { Labels } from "routes/Settings/sections/Labels";
import { NewComputedTrait } from "routes/Settings/sections/NewComputedTrait";
import { Objects } from "routes/Settings/sections/Objects";
import Teams from "routes/Settings/sections/Teams";
import Settings from "routes/Settings/Settings";
import Setup from "routes/Setup";
import { Start } from "routes/Start";
import Success from "routes/Success";
import Slack from "routes/Success/Slack";
import { View } from "routes/View";

type SubRouteConfig = {
  isIndex?: boolean;
  path?: string;
  component: React.FC<any>;
};

interface RouteConfig {
  path: string;
  component: React.FC<any>;
  redirectTo?: string;
  subRoutes?: SubRouteConfig[];
}

const routes: {
  unauthenticatedRoutes: RouteConfig[];
  authenticatedRoutes: RouteConfig[];
  indifferentRoutes: RouteConfig[];
} = {
  unauthenticatedRoutes: [
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgotPasswordPage },
    { path: "/reset-password/:token", component: ResetPasswordPage },
    { path: "/start", component: Start },
    { path: "/start/:reportType", component: Start },
    {
      path: "/a/:appId/report/:reportId/:sharingSecretToken",
      component: PublicReportRoute,
    },
    {
      path: "/a/:appId/report/:reportId/:insightSlug/:sharingSecretToken",
      component: PublicInsightRoute,
    },
    {
      path: "/a/:appId/dashboard/:viewId/:sharingSecretToken",
      component: PublicViewRoute,
    },
    {
      path: "/a/:appId/objects/:appObjectId/object/:objectId/dashboard/:viewId/:sharingSecretToken",
      component: PublicViewRoute,
    },
    {
      path: "/a/:appId/dashboard/:viewId/query/:viewInsightId/:sharingSecretToken",
      component: PublicQueryRoute,
    },
    {
      path: "/a/:appId/dashboard/:viewId/query/:viewInsightId/:groupId/:sharingSecretToken",
      component: PublicQueryRoute,
    },
    {
      path: "/a/:appId/dashboard/:viewId/insight/:viewInsightId/:sharingSecretToken",
      component: PublicViewInsightRoute,
    },
    {
      path: "/a/:appId/objects/:appObjectId/object/:objectId/insight/:viewInsightId/:sharingSecretToken",
      component: PublicViewInsightRoute,
    },
    {
      path: "/a/:appId/dashboard/:viewId/insight/:viewInsightId/screenshot/:sharingSecretToken",
      component: PublicViewInsightScreenshotRoute,
    },
    { path: "/", component: Login },
    { path: "*", component: Login },
  ],
  authenticatedRoutes: [
    {
      path: "/redirect-to-my-workspace/:wildcard/*",
      component: RedirectToMyWorkspace,
    },
    { path: "/_/:wildcard/*", component: RedirectToMyWorkspace },
    { path: "/a/:appId/home", component: Home },
    { path: "/a/:appId/home/:viewId", component: View },
    {
      path: "/a/:appId/dashboard/:viewId/graph/:viewInsightId",
      component: ViewInsightRoute,
    },
    {
      path: "/a/:appId/dashboard/:viewId/graph/new",
      component: AddInsightRoute,
    },
    {
      path: "/a/:appId/dashboard/:viewId/sql/new",
      component: AddSQLRoute,
    },
    {
      path: "/a/:appId/dashboard/:viewId/sql/:viewInsightId",
      component: EditSQLRoute,
    },
    {
      path: "/a/:appId/reports",
      component: Reports,
    },
    {
      path: "/a/:appId/events",
      component: Events,
    },
    {
      path: "/a/:appId/groups/*",
      component: Groups,
    },
    {
      path: "/a/:appId/contacts/*",
      component: Contacts,
    },
    {
      path: "/a/:appId/people",
      component: People,
    },
    {
      path: "/a/:appId/people/*",
      component: People,
    },
    {
      path: "/a/:appId/dashboards",
      component: Dashboards,
    },
    {
      path: "/a/:appId/audiences",
      component: Audiences,
    },
    {
      path: "/a/:appId/audience/:audienceId",
      component: Audience,
    },
    {
      path: "/a/:appId/integrations",
      component: Integrations,
      subRoutes: [
        {
          path: "computed-traits",
          component: ComputedTraits,
        },
        {
          path: "computed-traits/new",
          component: NewComputedTrait,
        },
        {
          path: "computed-traits/:traitId",
          component: EditComputedTrait,
        },
        {
          path: "hubspot",
          component: Hubspot,
        },
        {
          path: "salesforce",
          component: Salesforce,
        },
        {
          path: "attio",
          component: Attio,
        },
        {
          path: ":integration/:objectType",
          component: CrmSyncSettingRoute,
        },
        {
          path: "api",
          component: Api,
        },
        {
          path: "email",
          component: EmailSettings,
        },
        {
          path: "slack",
          component: SlackSettings,
        },
        {
          path: "integrations/:integration/:objectType",
          component: CrmSyncSettingRoute,
        },
        {
          path: "stripe",
          component: Stripe,
        },
      ],
    },
    {
      path: "/a/:appId/settings/",
      component: Settings,
      subRoutes: [
        {
          isIndex: true,
          component: GeneralSettings,
        },
        {
          path: "general",
          component: GeneralSettings,
        },
        {
          path: "labels",
          component: Labels,
        },
        {
          path: "team",
          component: Teams,
        },
        {
          path: "billing",
          component: Billing,
        },
        {
          path: "digest",
          component: Digest,
        },
        {
          path: "objects",
          component: Objects,
        },
        {
          path: "objects/:objectId",
          component: ViewObject,
        },
        {
          path: "objects/:objectId/traits",
          component: ViewObjectTraits,
        },
        {
          path: "objects/:objectId/traits/archived",
          component: ArchivedTraits,
        },
        {
          path: "objects/:objectId/traits/:traitId/debugger",
          component: TraitDebugger,
        },
        {
          path: "computed-traits",
          component: ComputedTraits,
        },
        {
          path: "computed-traits/new",
          component: NewComputedTrait,
        },
        {
          path: "computed-traits/:traitId",
          component: EditComputedTrait,
        },
        {
          path: "integrations/hubspot",
          component: Hubspot,
        },
        {
          path: "integrations/salesforce",
          component: Salesforce,
        },
        {
          path: "integrations/attio",
          component: Attio,
        },
        {
          path: "integrations/api",
          component: Api,
        },
        {
          path: "integrations/email",
          component: EmailSettings,
        },
        {
          path: "integrations/slack",
          component: SlackSettings,
        },
        {
          path: "integrations/freshpaint",
          component: Freshpaint,
        },
        {
          path: "integrations/segment",
          component: Segment,
        },
        {
          path: "integrations/rudderstack",
          component: Rudderstack,
        },
        {
          path: "integrations/june-sdk",
          component: JuneSdk,
        },
        {
          path: "june-sdk",
          component: JuneSdk,
        },
        {
          path: "integrations/amplitude",
          component: Amplitude,
        },
        {
          path: "integrations/mixpanel",
          component: Mixpanel,
        },
        {
          path: "developers",
          component: Developers,
        },
        {
          path: "integrations/:integration/:objectType",
          component: CrmSyncSettingRoute,
        },
      ],
    },
    { path: "/a/:appId/setup", component: Setup },
    { path: "/a/:appId/onboarding", component: Onboarding },
    { path: "/a/:appId/onboarding/schedule", component: Schedule },
    { path: "/a/:appId/join", component: Join },
    {
      path: "/a/:appId/getting-started",
      component: GettingStarted,
    },
    { path: "/a/:appId/success", component: Success },
    { path: "/a/:appId/slack/success", component: Slack },
    {
      path: "/a/:appId/template/:reportId",
      component: Report,
    },
    {
      path: "/a/:appId/report/:reportId",
      component: Report,
    },
    {
      path: "/a/:appId/report/:reportId/setup",
      component: NewReportSetup,
    },
    {
      path: "/a/:appId/report/create/:reportType",
      component: CreateReportRoute,
    },
    {
      path: "/a/:appId/report/:reportId/:sharingSecretToken",
      component: PublicReportRoute,
    },
    {
      path: "/a/:appId/report/:reportId/:insightSlug/:sharingSecretToken",
      component: PublicInsightRoute,
    },
    {
      path: "/a/:appId/events/feed/*",
      component: Feed,
    },
    {
      path: "/a/:appId/event/:eventId",
      component: Event,
    },
    {
      path: "/a/:appId/event/:eventId/rename-request",
      component: Rename,
    },
    {
      path: "/a/:appId/group/:groupId",
      component: Group,
    },
    {
      path: "/a/:appId/objects/:appObjectId/object/:objectId",
      component: ObjectRoute,
    },
    {
      path: "/a/:appId/objects/:appObjectId/object/:objectId/graph/:viewInsightId",
      component: EditObjectInsightRoute,
    },
    {
      path: "/a/:appId/objects/:appObjectId/object/:objectId/graph/new",
      component: AddObjectInsightRoute,
    },
    {
      path: "/a/:appId/objects/:appObjectId/object/:objectId/sql/new",
      component: AddObjectSQLRoute,
    },
    {
      path: "/a/:appId/objects/:appObjectId/object/:objectId/sql/:viewInsightId",
      component: EditObjectSQLRoute,
    },
    {
      path: "/a/:appId/group/:groupId/new",
      component: NewGroup,
    },
    {
      path: "/a/:appId/company/:groupId",
      component: Company,
    },
    {
      path: "/a/:appId/group",
      component: Group,
    },
    {
      path: "/a/:appId/contact/:contactId",
      component: Contact,
    },
    // todo: handle redirect
    { path: "/a/:appId/widgets/", component: MobileWidgetsOnboarding },
  ],
  // Routes that allow either authentication states.
  indifferentRoutes: [
    { path: "/widgets/install/", component: MobileWidgetInstall },
    { path: "/oauth/redirect/", component: OauthRedirect },
  ],
};
export default routes;
