import { Avatar } from "@chakra-ui/react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "@/Components/ui/button";
import { IViewInsight } from "@/core/models/viewInsights";

interface IData {
  id: string;
  displayName: string;
  avatar?: string;
  description?: string;
}

export const List: React.FC<{
  data: IData[];
  onClickDataPoint?: () => void;
  viewInsight?: IViewInsight;
}> = ({ data, onClickDataPoint, viewInsight }) => {
  const { sharingSecretToken } = useParams();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="relative flex w-full flex-col gap-3"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {data?.map(
        ({ id, avatar, displayName, description }: IData, index: number) => {
          const name = displayName || id;
          return (
            <div className="flex w-full items-center gap-2" key={index}>
              <div className="flex">
                <Avatar name={displayName} size="xs" src={avatar} />
              </div>
              <div className="flex flex-1 flex-col items-start overflow-hidden">
                <div className="w-[80%] truncate text-ellipsis whitespace-nowrap text-xs">
                  {name}
                </div>
                <div className="text-xs text-gray-600">{description}</div>
              </div>
            </div>
          );
        },
      )}
      {!sharingSecretToken && isHovered && onClickDataPoint && (
        <motion.div
          className="absolute -bottom-5 left-0 h-40 w-full bg-gradient-to-t from-white to-transparent duration-300 ease-in-out"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex h-full w-full flex-col justify-end pb-4 text-center">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Button
                variant="outline"
                size="sm"
                onClick={() => onClickDataPoint?.()}
                className="relative right-2 mx-auto rounded-full"
              >
                <div className="flex items-center gap-1">
                  <span className="text-xs">View</span>
                  <span className="text-xs">
                    {viewInsight?.appObject?.pluralName?.toLowerCase()}
                  </span>
                  <ArrowLongRightIcon className="h-3 w-3 text-gray-600" />
                </div>
              </Button>
            </motion.div>
          </div>
        </motion.div>
      )}
    </div>
  );
};
