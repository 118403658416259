import { IEventRow } from "modules/Events/List/EventsList";
import React from "react";

import { Icon } from "core/design-system/components/Icon";

export const GroupContextStatus = ({ props: event }: { props: IEventRow }) => {
  const { isReceivingGroupContext } = event;

  return (
    <div className="flex items-center justify-end">
      {isReceivingGroupContext ? (
        <Icon
          h={3}
          w={3}
          iconType="icon"
          name="check"
          color="success.400"
          data-testid="check-icon"
        />
      ) : (
        <span className="text-sm">-</span>
      )}
    </div>
  );
};
