import { CopyIcon, Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { Button } from "@/Components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { IView, useDuplicateViewMutation } from "@/core/models/views";

export const DuplicateAction: React.FC<{ view: IView }> = ({ view }) => {
  const [duplicateView, { isLoading }] = useDuplicateViewMutation();
  const navigate = useNavigate();

  const handleDuplicate = () => {
    duplicateView({
      id: view.id,
      appId: view.appId,
    })
      .unwrap()
      .then((data) => {
        toast.success("Dashboard duplicated successfully");
        if (data) {
          navigate(`/a/${view.appId}/home/${data.id}?new=true`);
        }
      })
      .catch(() => {
        toast.error("Failed to duplicate dashboard");
      });
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="ghost" size="icon" onClick={handleDuplicate}>
          {isLoading ? (
            <Loader2 className="h-4 w-4 animate-spin text-gray-600" />
          ) : (
            <CopyIcon className="h-4 w-4 text-gray-600" />
          )}
        </Button>
      </TooltipTrigger>
      <TooltipContent>Duplicate dashboard</TooltipContent>
    </Tooltip>
  );
};
