import { PinIcon, PinOff } from "lucide-react";
import React from "react";
import { useParams } from "react-router-dom";

import { Button } from "@/Components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { usePinAudienceMutation } from "core/models/audiences";
import { IAudience } from "core/types/Audience";

export const AudiencePinCell: React.FC<{ props: IAudience }> = ({
  props: audience,
}) => {
  const { appId } = useParams();
  const [pinAudience] = usePinAudienceMutation();

  function handlePin() {
    if (audience?.id === undefined) return null;

    pinAudience({
      appId: Number(appId),
      audienceId: audience.id,
      pinned: !audience.pinned,
    });
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          onClick={handlePin}
          className="px-1 text-gray-600"
        >
          {audience.pinned ? (
            <PinOff className="h-4 w-4" />
          ) : (
            <PinIcon className="h-4 w-4" />
          )}
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {audience.pinned ? "Unpin audience" : "Pin audience"}
      </TooltipContent>
    </Tooltip>
  );
};
